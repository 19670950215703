import React, {useMemo} from 'react';
import classnames from 'classnames';
import {fromJS, List} from 'immutable';

import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import {setUpStore} from 'client/framework';
import {useQuestionEditorV2Store} from 'client/EditPage/V2/QuestionEditorV2Store';
import {DropdownModel} from 'resources/Question/Question.model';

import FillInTheBlankQuestionStore from './FillInTheBlankQuestion.store';

import './fill-in-the-blank.scss';

interface Props {
  storeName?: string;
  question: any;
  questionId: string;
  isGuessSubmitted: boolean;
}

const FillInTheBlankQuestion = ({
  storeName = 'fillInTheBlankQuestionStore',
  question,
  questionId,
  isGuessSubmitted
}: Props) => {
  const currentTranslatedQuestion = useQuestionEditorV2Store((state) =>
    state.currentTranslatedQuestion()
  );

  const store = useMemo(() => {
    return setUpStore(FillInTheBlankQuestionStore, storeName, questionId);
  }, [storeName, questionId]);

  const dropdowns = currentTranslatedQuestion?.dropdowns
    ? List(
        currentTranslatedQuestion.dropdowns.map((dropdown) => new DropdownModel(fromJS(dropdown)))
      )
    : question.getDropdowns();

  return (
    <div className='fill-in-the-blank'>
      <div className='fill-in-the-blank-question-content'>
        <MarkdownRendererV2 text={currentTranslatedQuestion?.prompt || question.getPrompt()} />
      </div>
      <MarkdownRendererV2
        className={classnames('fitb-input-area', {
          'fitb-input-area-dropdown': !dropdowns.isEmpty()
        })}
        text={currentTranslatedQuestion?.dropdown_text || question.getDropdownText()}
        store={store}
        dropdowns={dropdowns}
        isExplanation={false}
        isGuessSubmitted={isGuessSubmitted}
        question={question}
      />
    </div>
  );
};

export default FillInTheBlankQuestion;
