import React, {useEffect, useMemo} from 'react';
import {callTargetedAction, setUpStore} from 'client/framework';

import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';

import {useQuestionEditorV2Store} from 'client/EditPage/V2/QuestionEditorV2Store';

import EliminationTool from './EliminationTool/EliminationTool.react';
import eliminationToolStore from './EliminationTool/EliminationTool.store';
import AnswerOptionEliminator from './EliminationTool/AnswerOptionEliminator.react';
import multipleChoiceQuestionActions from './MultipleChoiceQuestion.actions';
import MultipleChoiceOption from './MultipleChoiceOption';
import MultipleChoiceQuestionStore from './MultipleChoiceQuestion.store';
import './multiple-choice-question.scss';
import './EliminationTool/elimination-tool.scss';

interface Props {
  isGuessSubmitted: boolean;
  storeName?: string;
  question: any;
  questionId: string;
}

const MultipleChoiceQuestion = ({
  isGuessSubmitted,
  storeName = 'multipleChoiceQuestionStore',
  question,
  questionId
}: Props) => {
  const store = useMemo(() => {
    return setUpStore(MultipleChoiceQuestionStore, storeName, questionId, question);
  }, [storeName, questionId, question]);

  const currentTranslatedQuestion = useQuestionEditorV2Store((state) =>
    state.currentTranslatedQuestion()
  );

  useEffect(() => {
    if (!eliminationToolStore.isEnabled() && store.areOptionsEliminated()) {
      callTargetedAction({
        name: multipleChoiceQuestionActions.SET_ELIMINATED_TO_EMPTY,
        targetStore: storeName
      });
    }
  }, [store, storeName]);

  const options = currentTranslatedQuestion?.options || question.getOptions().toJS();
  const prompt = currentTranslatedQuestion?.prompt || question.getPrompt();

  return (
    <div className='mcq'>
      <MarkdownRendererV2 className='mcq__question-content' text={prompt} />
      <EliminationTool hidden={isGuessSubmitted} storeName={storeName} />
      <div className='mcq__options-wrapper'>
        {options.map((option, index) => {
          const optionId = option.id;
          return (
            <AnswerOptionEliminator
              key={optionId}
              store={store}
              optionId={optionId}
              isGuessSubmitted={isGuessSubmitted}
            >
              <MultipleChoiceOption
                isSelectMultiple={question.isSelectMultiple()}
                index={index}
                option={option}
                isGuessSubmitted={isGuessSubmitted}
                isSelected={store.isSelectedOption(optionId)}
                isCorrectAnswer={store.correctAnswer.has(optionId)}
                isEliminated={store.isOptionEliminated(optionId)}
                targetStore={store.getName()}
              />
            </AnswerOptionEliminator>
          );
        })}
      </div>
    </div>
  );
};

export default MultipleChoiceQuestion;
