import classnames from 'classnames';
import React, {useCallback} from 'react';
import {callTargetedAction} from 'client/framework';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';

import TextInput from 'generic/Forms/TextInput/TextInput';
import CorrectnessIndicatorWrapper from 'client/QuestionTypes/common/CorrectnessIndicatorWrapper/CorrectnessIndicatorWrapper.react';

import freeEntryQuestionActions from './FreeEntryQuestion.actions';

interface Props {
  inputId: string;
  inputPrompt: string;
  isMarkedCorrect: boolean;
  isGuessSubmitted: boolean;
  question: any;
  storeName: string;
  value: string;
}

const FreeEntryQuestionInput = ({
  inputId,
  inputPrompt,
  isMarkedCorrect,
  isGuessSubmitted,
  question,
  storeName,
  value
}: Props) => {
  const handleChange = useCallback(
    (e) => {
      if (isGuessSubmitted) {
        return;
      }
      callTargetedAction({
        name: freeEntryQuestionActions.UPDATE_INPUT,
        payload: {
          inputId,
          value: e.target.value.trim()
        },
        targetStore: storeName
      });
    },
    [inputId, isGuessSubmitted, storeName]
  );

  const wrapperClass = classnames('free-entry-input', {
    'free-entry-input--post-submission': isGuessSubmitted
  });
  const inputClass = classnames(
    'free-entry-input__input',
    isGuessSubmitted
      ? {
          'free-entry-input__input--correct': isMarkedCorrect,
          'free-entry-input__input--incorrect': !isMarkedCorrect
        }
      : null
  );
  const placeholderText =
    question.getIn(['rubric', 'type']) === 'mathContains'
      ? 'Enter answer here. Do not include spaces or units.'
      : 'Type answer here. Be careful with spelling.';

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label className={wrapperClass}>
      <MarkdownRendererV2 text={inputPrompt} className='free-entry-input__prompt' />
      <div className='free-entry-input__input-wrapper'>
        <TextInput
          className={inputClass}
          onChange={handleChange}
          placeholder={placeholderText}
          value={value}
        />
        <CorrectnessIndicatorWrapper
          iconOnly
          isSelected
          size={24}
          className='free-entry-input__correctness-indicator'
          isGuessSubmitted={isGuessSubmitted}
          isCorrectAnswer={isMarkedCorrect}
        />
      </div>
    </label>
  );
};

export default FreeEntryQuestionInput;
