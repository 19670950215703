import React from 'react';

import GenericExplanation from 'client/QuestionTypes/common/V2/GenericExplanation';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import GraphingInput, {modes, flavorTypes} from 'components/GraphingInput/GraphingInput.react';
import {useQuestionEditorV2Store} from 'client/EditPage/V2/QuestionEditorV2Store';

function getGraphingInputProps({question, isExplanation = false}) {
  const {
    allowed_entities: allowedEntities,
    label_bottom: labelBottom,
    label_left: labelLeft,
    provided_entities: providedEntities,
    x_interval: xInterval,
    x_label: xLabel,
    x_max: xMax,
    x_min: xMin,
    y_interval: yInterval,
    y_label: yLabel,
    y_max: yMax,
    y_min: yMin
  } = question.getQuestionTypeAttributes().get('graph_contains').toJS();

  const validResponse = question
    .getRubric()
    .get('valid_response')
    .toJS()
    .map((entity) => ({
      ...entity,
      flavor: isExplanation ? flavorTypes.solution : flavorTypes.correctAnswer
    }));

  const initialEntities = providedEntities
    .map((entity) => ({
      ...entity,
      flavor: flavorTypes.authorProvided
    }))
    .concat(validResponse);

  return {
    mode: modes.reviewing,
    currentFlavor: flavorTypes.studentGuess,
    boundingBox: [xMin, yMax, xMax, yMin],
    xTickInterval: xInterval,
    yTickInterval: yInterval,
    xAxisLabel: xLabel,
    yAxisLabel: yLabel,
    allowedEntities,
    initialEntities,
    labels: {
      bottom: labelBottom,
      left: labelLeft
    },
    className: 'graphing-question-input u-mar-b_2',
    id: `graphing-question-${isExplanation ? 'explanation' : 'prompt'}-preview`,
    renderToolbar: () => null,
    onChange: () => null
  };
}

interface Props {
  question: any;
}

export function GraphingEditorQuestionPreview({question}: Props) {
  const currentTranslatedQuestion = useQuestionEditorV2Store((state) =>
    state.currentTranslatedQuestion()
  );

  return (
    <div>
      <MarkdownRendererV2 text={currentTranslatedQuestion?.prompt || question.getPrompt()} />
      <GraphingInput {...getGraphingInputProps({question})} />
    </div>
  );
}

export function GraphingEditorExplanationPreview(props: Props) {
  return (
    <GenericExplanation {...(props as any)}>
      <GraphingInput {...getGraphingInputProps({question: props.question, isExplanation: true})} />
    </GenericExplanation>
  );
}
