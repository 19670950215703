import React, {useMemo} from 'react';
import classnames from 'classnames';

import {MULTIPLE_CHOICE_LETTERS} from './MultipleChoice.constants';

interface Props {
  correctAnswer: any;
  previewMode: boolean;
  question: any;
  selectedAnswer: any;
}

const MultipleChoiceQuestionResponseStats = ({
  correctAnswer,
  previewMode,
  question,
  selectedAnswer
}: Props) => {
  const correctOptions = correctAnswer;
  const selectedOptions = selectedAnswer;
  const isIncomplete = !correctOptions || !selectedOptions;

  if ((isIncomplete && !previewMode) || question.getTotalAnswerCount() < 50) {
    return null;
  }

  const optionsContent = useMemo(() => {
    return question.getOptions().map((option, index) => {
      const optionLetter = MULTIPLE_CHOICE_LETTERS[index];
      const optionPercentage = question.getOptionResponsePercentage(option.get('id'));
      const isCorrectOption = correctOptions.has(option.get('id'));
      const isSelectedOption = selectedOptions ? selectedOptions.has(option.get('id')) : null;
      const optionClasses = classnames('question-response-stats__item', {
        correct: isCorrectOption,
        incorrect: isSelectedOption && !isCorrectOption
      });

      return (
        <div className={optionClasses} key={`question-response_${option.get('id')}`}>
          <span className='question-response-stats__item-label'>{optionLetter}</span>
          <span
            className='question-response-stats__item-fillbar'
            data-fill-percentage={optionPercentage}
          />
          <span className='question-response-stats__item-percentage'>{`${optionPercentage}%`}</span>
        </div>
      );
    });
  }, [question, correctOptions, selectedOptions]);

  return (
    <div className='question-response-stats'>
      <div className='question-response-stats__header'>How Others Answered</div>
      <div className='question-response-stats__body'>{optionsContent}</div>
    </div>
  );
};

export default MultipleChoiceQuestionResponseStats;
