import React from 'react';

interface Props {
  index: number;
  className: string;
  answerPercentage: number;
}

const FreeEntryQuestionResponseStatsPercentageIndicator = ({
  index,
  className,
  answerPercentage
}: Props) => {
  return (
    <div className={className}>
      <span className='question-response-stats__item-label'>Input {index + 1}</span>
      <span
        className='question-response-stats__item-fillbar'
        data-fill-percentage={answerPercentage}
      />
      <span className='question-response-stats__item-percentage'>{`${answerPercentage}%`}</span>
    </div>
  );
};

export default FreeEntryQuestionResponseStatsPercentageIndicator;
