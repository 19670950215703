import React, {useCallback, useMemo} from 'react';
import classnames from 'classnames';
import {Text} from '@albert-io/atomic';
import {useQuestionEditorV2Store} from 'client/EditPage/V2/QuestionEditorV2Store';
import {callTargetedAction, setUpStore} from 'client/framework';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';

import snippetSelectionQuestionActions from './SnippetSelectionQuestion.actions';
import SnippetSelectionQuestionStore from './SnippetSelectionQuestion.store';

import './snippet-selection-question.scss';

interface Props {
  questionId: string;
  question: any;
  storeName: string;
  isGuessSubmitted: boolean;
}

export const SnippetSelectionQuestion = ({
  questionId,
  question,
  storeName,
  isGuessSubmitted
}: Props) => {
  const currentTranslatedQuestion = useQuestionEditorV2Store((state) =>
    state.currentTranslatedQuestion()
  );

  const store = useMemo(() => {
    return setUpStore(SnippetSelectionQuestionStore, storeName, questionId);
  }, [storeName, questionId]);

  const onOptionClick = useCallback(
    (optionId) => {
      if (isGuessSubmitted) {
        return;
      }

      callTargetedAction({
        name: snippetSelectionQuestionActions.TOGGLE_OPTION,
        targetStore: store.getName(),
        payload: optionId
      });
    },
    [isGuessSubmitted, store]
  );

  const inputAreaClassNames = classnames('snippet-selection-question__input-area', {
    'snippet-selection-question__input-area--interactive': !(isGuessSubmitted || store.hasResponse)
  });

  return (
    <div className='snippet-selection-question'>
      <MarkdownRendererV2 text={currentTranslatedQuestion?.prompt || question.getPrompt()} />
      <h2 className='question__prompt-heading'>Select answer(s) below</h2>
      {currentTranslatedQuestion?.snippet_prompt ? (
        <div className='u-mar-b_1'>
          <Text color='warning' size='l'>
            Snippet selection translations are only shown for reference. Snippet selection questions
            must be answered in their original language.
          </Text>
          <MarkdownRendererV2
            text={currentTranslatedQuestion.snippet_prompt}
            className='u-mar-t_1'
          />
        </div>
      ) : (
        <MarkdownRendererV2
          className={inputAreaClassNames}
          text={question.getSnippetPrompt()}
          snippetSelectionData={{
            isExplanation: isGuessSubmitted,
            options: question.getOptions(),
            storeName: store.getName(),
            onOptionClick
          }}
        />
      )}
    </div>
  );
};
