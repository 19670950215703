import React, {useState} from 'react';
import {TransformWrapper} from 'react-zoom-pan-pinch';
import {Resizable} from 're-resizable';
import {SupplementModelV1} from '@albert-io/models';
import {Text, WindowSizeConsumer} from '@albert-io/atomic';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import useClientRect from 'lib/hooks/useClientRect';

import {HiddenImage} from './HiddenImage.react';
import {ImageControls} from './ImageControls.react';
import {ImageFullScreenModal} from './ImageFullScreenModal.react';
import {ImageWrapper} from './ImageWrapper.react';

import {MIN_ZOOM, MAX_ZOOM, DEFAULT_HEIGHT, MAX_HEIGHT, MIN_HEIGHT} from './constants';

import './image-v2.scss';

interface ImageV2Props {
  content?: any;
  supplement: SupplementModelV1;
  showHideImage?: boolean;
  showFullScreen?: boolean;
  showZoom?: boolean;
}

export const ImageV2 = ({
  supplement,
  content,
  showHideImage = true,
  showFullScreen = true,
  showZoom = true
}: ImageV2Props) => {
  const [captionRect, captionRef] = useClientRect();

  const [isHidden, setIsHidden] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  // used to disable pan/zoom while resizing
  const [isResizing, setIsResizing] = useState(false);
  // disable panning on mobile if we are not zoomed in
  const [shouldDisablePanning, setShouldDisablePanning] = useState(false);

  // Content is also passed down to Image V2 in the supplement renderer
  // Translated supplements pass translated caption to content
  // use old method of getting caption as fallback in case there are instances
  // where content isn't passed down
  const caption = content?.toJS?.()?.caption || supplement.getCaption();

  return (
    <div className='u-overflow-x_hidden'>
      <WindowSizeConsumer>
        {({breakpoints, viewportWidth}) => {
          const isMobile = viewportWidth < breakpoints.s;

          if (isHidden) {
            return <HiddenImage setIsHidden={setIsHidden} isMobile={isMobile} />;
          }

          return (
            <>
              <Resizable
                onResizeStart={() => setIsResizing(true)}
                onResizeStop={() => {
                  setIsResizing(false);
                }}
                defaultSize={{
                  width: '100%',
                  height: DEFAULT_HEIGHT
                }}
                minWidth='100%'
                minHeight={MIN_HEIGHT + (captionRect?.height || 0)} // account for height of dynamic caption so that it always fits
                maxHeight={MAX_HEIGHT}
                className='image-supplement__resizable'
                bounds='parent'
                enable={isMobile ? false : undefined}
              >
                <figure
                  className='image-supplement__figure'
                  data-testid='image-supplement-default-display'
                >
                  <div className='image-supplement__image-container'>
                    <TransformWrapper
                      centerOnInit
                      limitToBounds
                      minScale={MIN_ZOOM}
                      maxScale={MAX_ZOOM}
                      disabled={isResizing}
                      pinch={{
                        step: 10
                      }}
                      wheel={{
                        wheelDisabled: true
                      }}
                      panning={{
                        disabled: shouldDisablePanning
                      }}
                    >
                      {/**
                       * controls are visible via hover on desktop and always visible on mobile
                       * */}
                      <ImageControls
                        isMobile={isMobile}
                        showHideImage={showHideImage}
                        setIsHidden={setIsHidden}
                        showFullScreen={showFullScreen}
                        setIsFullScreen={setIsFullScreen}
                        showZoom={showZoom}
                        setShouldDisablePanning={setShouldDisablePanning}
                      />
                      <ImageWrapper
                        className='image-supplement__image'
                        supplement={supplement}
                        shouldCenter={!isResizing}
                      />
                    </TransformWrapper>
                  </div>
                  {caption && (
                    <div ref={captionRef}>
                      <Text
                        className='u-display_flex u-justify-content_center u-pad_1'
                        as='figcaption'
                        size='xs'
                      >
                        <MarkdownRendererV2 text={caption} />
                      </Text>
                    </div>
                  )}
                  {!isMobile && (
                    <div className='image-supplement__resize-handle'>
                      <div /> <div /> <div />
                    </div>
                  )}
                </figure>
              </Resizable>
              {isFullScreen && (
                <ImageFullScreenModal
                  isMobile={isMobile}
                  caption={caption}
                  setIsFullScreen={setIsFullScreen}
                  supplement={supplement}
                />
              )}
            </>
          );
        }}
      </WindowSizeConsumer>
    </div>
  );
};
