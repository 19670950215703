import React, {useMemo} from 'react';
import {Heading} from '@albert-io/atomic';
import {QuestionModelV3} from '@albert-io/models';

import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import classnames from 'classnames';
import {useShallow} from 'zustand/react/shallow';

import {useQuestionTranslationStore} from 'components/QuestionTypes/shared/TranslationSection';
import {formatDropdowns} from './FillInTheBlankInputArea.react';

interface FillInTheBlankExplanationProps {
  question: QuestionModelV3;
  isGuessSubmitted: boolean;
}

/*
old propTypes info
FillInTheBlankExplanation.propTypes = {
  question: PropTypes.object,
  isGuessSubmitted: PropTypes.bool
};
*/

const FillInTheBlankExplanation = ({
  question,
  isGuessSubmitted
}: FillInTheBlankExplanationProps) => {
  // Translation info
  const {translatedQuestionInfo, showAnswerTranslation} = useQuestionTranslationStore(
    useShallow((state) => ({
      translatedQuestionInfo: state.translatedQuestionInfo,
      showAnswerTranslation: state.toggleStatus.answer
    }))
  );

  const [dropdowns, dropdownText] = useMemo(() => {
    if (showAnswerTranslation && translatedQuestionInfo) {
      return [
        formatDropdowns(translatedQuestionInfo.dropdowns),
        translatedQuestionInfo.dropdown_text
      ];
    }
    return [question.getDropdowns(), question.getDropdownText()];
  }, [question, translatedQuestionInfo, showAnswerTranslation]);

  return (
    <div className='fitb-v2-explanation'>
      <Heading as='h2' size='s'>
        Correct Answer(s)
      </Heading>
      <MarkdownRendererV2
        className={classnames({
          'fitb-input-area-dropdown': !dropdowns.isEmpty()
        })}
        isExplanation
        text={dropdownText}
        dropdowns={dropdowns}
        isGuessSubmitted={isGuessSubmitted}
        store={question.getStore()}
        question={question}
      />
    </div>
  );
};

export default FillInTheBlankExplanation;
