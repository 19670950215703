import React, {useMemo} from 'react';
import classnames from 'classnames';
import {fromJS, List} from 'immutable';

import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import {setUpStore} from 'client/framework';
import {DropdownModel} from 'resources/Question/Question.model';

import {useQuestionEditorV2Store} from 'client/EditPage/V2/QuestionEditorV2Store';

import GenericExplanation from '../../common/V2/GenericExplanation';

import FillInTheBlankQuestionStore from './FillInTheBlankQuestion.store';

interface Props {
  question: any;
  questionStoreName: string;
  questionId: string;
}

const FillInTheBlankExplanation = ({
  question,
  questionStoreName,
  questionId,
  ...otherProps
}: Props) => {
  const currentTranslatedQuestion = useQuestionEditorV2Store((state) =>
    state.currentTranslatedQuestion()
  );

  const store = useMemo(() => {
    return setUpStore(FillInTheBlankQuestionStore, questionStoreName, questionId);
  }, [questionStoreName, questionId]);

  const dropdowns = currentTranslatedQuestion?.dropdowns
    ? List(
        currentTranslatedQuestion.dropdowns.map((dropdown) => new DropdownModel(fromJS(dropdown)))
      )
    : question.getDropdowns();

  return (
    <GenericExplanation questionId={questionId} {...(otherProps as any)}>
      <div className='fitb-v2-explanation'>
        <h2>Correct Answer(s)</h2>
        <MarkdownRendererV2
          className={classnames({
            'fitb-input-area-dropdown': !dropdowns.isEmpty()
          })}
          isExplanation
          text={currentTranslatedQuestion?.dropdown_text || question.getDropdownText()}
          dropdowns={dropdowns}
          store={store}
          question={question}
        />
      </div>
    </GenericExplanation>
  );
};

export default FillInTheBlankExplanation;
