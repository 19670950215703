import React from 'react';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import {useQuestionEditorV2Store} from 'client/EditPage/V2/QuestionEditorV2Store';

interface Props {
  question: any;
}

export const FreeResponseQuestion = ({question}: Props) => {
  const currentTranslatedQuestion = useQuestionEditorV2Store((state) =>
    state.currentTranslatedQuestion()
  );

  const prompt = currentTranslatedQuestion?.prompt || question.getPrompt();

  return (
    <div className='free-response-question'>
      <div className='free-response-question-content'>
        <MarkdownRendererV2 text={prompt} />
      </div>
    </div>
  );
};
