import React, {useCallback} from 'react';
import TextArea from 'generic/Forms/TextArea/TextArea.react';
import {AuthoringQuestionModelV1} from 'resources/GeneratedModels/AuthoringQuestion/AuthoringQuestionModel.v1';
import {useQuestionEditorV2Store} from 'client/EditPage/V2/QuestionEditorV2Store';
import {useShallow} from 'zustand/react/shallow';
import {PRIMARY_CONTENT_LANGUAGE} from 'client/EditPage/V2/QuestionEditorV2Store.types';

interface Props {
  question: AuthoringQuestionModelV1;
  onModelChange: (question: AuthoringQuestionModelV1, ...fields: string[]) => void;
}

export const UncorrectedTextEditor = ({onModelChange, question}: Props) => {
  const {currentLanguage, translatedUncorrectedText, updateTranslatedQuestionField} =
    useQuestionEditorV2Store(
      useShallow((state) => ({
        currentLanguage: state.currentLanguage,
        updateTranslatedQuestionField: state.updateTranslatedQuestionField,
        translatedUncorrectedText:
          state
            .currentTranslatedQuestion()
            ?.translated_fields?.find((field) => field.field === 'uncorrected_text')?.text || ''
      }))
    );

  const handleChange = useCallback(
    (e) => {
      if (currentLanguage === PRIMARY_CONTENT_LANGUAGE) {
        const updatedQuestion = question.setUncorrectedText(e.target.value);
        onModelChange(updatedQuestion, 'prompt');
      } else {
        updateTranslatedQuestionField(currentLanguage, 'uncorrected_text', 'text', e.target.value);
      }
    },
    [question, onModelChange, currentLanguage, updateTranslatedQuestionField]
  );

  // Checks to see if any region indices end at an index higher than the current word count.
  const hasOutOfScopeCorrection = useCallback(() => {
    const zeroIndexedWordCount = question.getUncorrectedText().trim().split(' ').length - 1;
    const hasError = question
      .getValidResponse()
      .some((resp) => resp.get('end_index') > zeroIndexedWordCount);
    return hasError;
  }, [question]);

  const value =
    currentLanguage === PRIMARY_CONTENT_LANGUAGE
      ? question.getUncorrectedText()
      : translatedUncorrectedText;

  return (
    <div className='passage-correction-editor-uncorrected-text'>
      <TextArea
        label='Uncorrected text'
        className='passage-correction-editor-uncorrected-text__input'
        value={value}
        onChange={handleChange}
        error={hasOutOfScopeCorrection()}
        errorMessage='Changing the length of the passage caused an index mismatch. To fix this, you will need to extend the length of the passage and delete the original corrections before shortening the passage back to its intended length.'
        data-testid='question-editor-uncorrected-text-input'
      />
    </div>
  );
};
