import React, {useCallback, useMemo} from 'react';
import {fromJS, List} from 'immutable';
import {setUpStore} from 'client/framework';
import {useQuestionEditorV2Store} from 'client/EditPage/V2/QuestionEditorV2Store';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import {FreeEntryInputModel} from 'resources/Question/Question.model';

import FreeEntryQuestionStore from './FreeEntryQuestion.store';
import FreeEntryQuestionInput from './FreeEntryQuestionInput.react';

import './free-entry-question.scss';

interface Props {
  isGuessSubmitted: boolean;
  storeName: string;
  question: any;
  questionId: string;
}

const FreeEntryQuestion = ({
  isGuessSubmitted,
  storeName = 'freeEntryQuestionStore',
  question,
  questionId
}: Props) => {
  const currentTranslatedQuestion = useQuestionEditorV2Store((state) =>
    state.currentTranslatedQuestion()
  );

  const store = useMemo(() => {
    return setUpStore(FreeEntryQuestionStore, storeName, questionId);
  }, [storeName, questionId]);

  const isInputCorrect = useCallback(({validResponse, type, threshold, inputId, value = ''}) => {
    if (validResponse.isEmpty()) {
      return false;
    }

    const correctOptions = validResponse.get(inputId);

    if (type === 'contains') {
      return correctOptions
        .map((option) => option.toLowerCase())
        .includes(value.toLowerCase().trim());
    }

    if (threshold) {
      const parsedVal = parseFloat(value);
      const parsedThreshold = parseFloat(threshold);

      return correctOptions.some((option) => {
        const parsedOption = parseFloat(option);
        if (parsedOption === parsedVal) {
          return true;
        }
        const leeway = (parsedOption / 100) * parsedThreshold;
        return Math.abs(parsedOption - parsedVal) < Math.abs(leeway);
      });
    }

    return correctOptions.includes(value.trim());
  }, []);

  const makeInputs = useCallback(
    (input) => {
      const inputId = input.getId();
      const value = store.getValueForId(inputId);
      const validResponse = question.getValidResponse();
      const type = question.getRubric()?.get('type') || null;
      const threshold = question.getIn(['meta', 'rubric', 'threshold'], false);
      const isMarkedCorrect = isInputCorrect({validResponse, type, threshold, inputId, value});

      return (
        <FreeEntryQuestionInput
          key={inputId}
          inputId={inputId}
          inputPrompt={input.getText()}
          isGuessSubmitted={isGuessSubmitted}
          isMarkedCorrect={isMarkedCorrect}
          question={question}
          storeName={storeName}
          value={value}
        />
      );
    },
    [isGuessSubmitted, question, storeName, store, isInputCorrect]
  );

  const inputs = currentTranslatedQuestion?.inputs
    ? List(currentTranslatedQuestion.inputs.map((input) => new FreeEntryInputModel(fromJS(input))))
    : question.getInputs();

  return (
    <div className='free-entry-question free-entry-question--legacy'>
      <MarkdownRendererV2
        text={currentTranslatedQuestion?.prompt || question.getPrompt()}
        className='free-entry-question__question-content'
      />
      <div className='free-entry-question__inputs-wrapper'>{inputs.map(makeInputs)}</div>
    </div>
  );
};

export default FreeEntryQuestion;
