import React, {useState} from 'react';
import {debounce} from 'lodash';
import {callAction, setUpStore} from 'client/framework';
import SimpleDropdown from 'generic/SimpleDropdown/SimpleDropdown.react';
import SimpleDropdownStore from 'generic/SimpleDropdown/SimpleDropdown.store';
import SubjectSearchDropdown from 'client/Dennis/Content/Queue/QueueSearchPanel/SubjectSearchDropdown/SubjectSearchDropdown.react';
import TextInput from 'generic/Forms/TextInput/TextInput';
import Button from 'sg/Button/Button.react';
import {MandarkTable, Column} from 'sg/Tables/MandarkTable/MandarkTable.react';
import MandarkSearchDropdown from 'client/styleguide/Dropdowns/MandarkSearchDropdown/MandarkSearchDropdown.react';
import {supplementModes} from 'client/Supplements/SupplementManager/SupplementManager';
import supplementManagerStore from 'client/Supplements/SupplementManager/SupplementManager.store';
import supplementManagerActions from 'client/Supplements/SupplementManager/SupplementManager.actions';
import {pushQueryParams} from 'client/history';
import supplementActions from 'client/Supplement/SupplementActions';

import {
  getSupplementsQuery,
  getAuthorsByEmailQuery,
  getAuthorByIdQuery
} from './SupplementSearch.queries';

import './supplement-search.scss';

const typeDropdownStore = setUpStore(SimpleDropdownStore, 'SupplementSearchTypeDropdownStore');

interface SupplementSearchProps {
  activeSubjectId: string;
  owner: string;
  searchString: string;
  showInsertButton: boolean;
  type: string;
  typeOptions: Map<string, string>[];
}

export const SupplementSearch = ({
  activeSubjectId,
  owner,
  searchString,
  showInsertButton,
  type,
  typeOptions
}: SupplementSearchProps) => {
  const [selectedSubjectId, setSelectedSubjectId] = useState(activeSubjectId);

  const setSearchString = (string) => {
    pushQueryParams({
      supplementSearchStr: string
    });
  };
  const debouncedSetSearchString = debounce(setSearchString, 500);

  const handleSearchInput = (e) => {
    debouncedSetSearchString(e.target.value);
  };

  const handleSubjectSearchOptionChange = (option) => {
    pushQueryParams({supplementSelectedSubject: option ? option.get('id') : ''});
    setSelectedSubjectId(option ? option.get('id') : '');
  };

  const handleOwnerOptionChange = (option) => {
    pushQueryParams({supplementOwner: option ? option.getId() : null});
  };

  const generateActionButtons = (supplement) => {
    const buttonGroup = [
      <Button
        className='supplement-search__action-btn supplement-search__action-btn--edit'
        color='gray'
        key='edit'
        onClick={() => {
          pushQueryParams({
            supplement: supplement.getId(),
            supplementMode: supplementModes.EDIT
          });
        }}
        small
        text='Edit'
        data-testid={`edit-supplement-${supplement.getId()}`}
      />
    ];

    if (showInsertButton) {
      buttonGroup.push(
        <Button
          className='supplement-search__action-btn supplement-search__action-btn--add'
          color='green'
          key='add'
          onClick={() => {
            callAction(supplementManagerActions.INSERT_SUPPLEMENT_AT_CURSOR_POSITION, {
              fieldPath: supplementManagerStore.getCurrentEditorField(),
              supplement
            });
            callAction(supplementManagerActions.SHOW_MODAL, false);
            callAction(supplementActions.ADD_SUPPLEMENT, supplement);
          }}
          small
          text='Add'
          data-testid={`add-supplement-${supplement.getId()}`}
        />
      );
    }

    return buttonGroup;
  };

  const getFilters = () => (
    <div className='supplement-search__filters'>
      <SubjectSearchDropdown
        className='supplement-search__filters-input'
        handleOptionChange={handleSubjectSearchOptionChange}
        storeName='SupplementSubjectsSearchStore'
        queryParamName='supplementSelectedSubject'
        defaultSelectedId={selectedSubjectId}
      />
      <SimpleDropdown
        activeItemId={type}
        className='supplement-search__filters-input supplement-search__filters-input--type'
        fullWidth
        label='Type'
        options={typeOptions}
        onChange={(item) => pushQueryParams({supplementType: item.get('id')})}
        placeholder='Choose one'
        storeName={typeDropdownStore.getName()}
      />
      <MandarkSearchDropdown
        className='supplement-search__filters-input supplement-search__filters-input--owner'
        defaultSelectedId={owner}
        displayName='email'
        onClick={handleOwnerOptionChange}
        byIdQuery={getAuthorByIdQuery}
        indexQuery={(str) => getAuthorsByEmailQuery(str)}
        label='Author Email'
        placeholder='Search by author email'
        renderFunc={(option) => (
          <div className='supplement-search__search-dropdown-option'>
            <div className='supplement-search__search-dropdown-option-email'>
              {option.getEmail()}
            </div>
            <div className='supplement-search__search-dropdown-option-name'>
              {option.getFullName()}
            </div>
          </div>
        )}
        storeName='SupplementSearchAuthorEmailSearch'
      />
      <TextInput
        className='supplement-search__filters-input supplement-search__filters-input--search'
        label='Supplement Name'
        maxLength={128}
        onChange={handleSearchInput}
        placeholder='Search by name'
        type='search'
        value={searchString}
      />
      <Button
        className='supplement-search__filters-button supplement-search__filters-button--new-supplement'
        text='New supplement'
        onClick={() => {
          pushQueryParams({
            supplement: null,
            supplementMode: supplementModes.EDIT
          });
        }}
      />
    </div>
  );

  return (
    <div className='supplement-search'>
      <div className='supplement-search__filters-wrapper filters-wrapper--supplements'>
        <h1 className='supplement-search__heading'>Filters</h1>
        {getFilters()}
      </div>
      <div className='supplement-search__results-wrapper'>
        <h1 className='supplement-search__heading'>Supplements</h1>
        <div className='supplement-search__results'>
          <MandarkTable
            className='supplement-search__results-table'
            query={getSupplementsQuery({
              owner,
              subject: selectedSubjectId,
              searchString,
              type
            })}
            storeName='supplementMandarkTableStore'
          >
            <Column
              heading='Type'
              content={(supplement) =>
                supplement.getType().charAt(0).toUpperCase() +
                supplement.getType().slice(1).replace(/-/g, ' ')
              }
            />
            <Column heading='Name' content={(supplement) => supplement.getName()} sortBy='name' />
            <Column
              heading='Owner'
              content={(supplement) => supplement.getMeta().getOwnerEmail() || 'N/A'}
            />
            <Column heading='' content={(supplement) => generateActionButtons(supplement)} />
          </MandarkTable>
        </div>
      </div>
    </div>
  );
};
