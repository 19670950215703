import React, {useEffect, useCallback, useMemo} from 'react';
import {Text} from '@albert-io/atomic';
import {useQuestionEditorV2Store} from 'client/EditPage/V2/QuestionEditorV2Store';
import {callTargetedAction, setUpStore} from 'client/framework';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';

import TextHighlightPrompt from './TextHighlightPrompt.react';
import textHighlightQuestionActions from './TextHighlightQuestion.actions';
import TextHighlightQuestionStore from './TextHighlightQuestion.store';

import './text-highlight-question.scss';

interface Props {
  isCorrect: boolean;
  isGuessSubmitted: boolean;
  question: any;
  questionId: string;
  storeName?: string;
}

const TextHighlightQuestion = ({
  isCorrect,
  isGuessSubmitted,
  question,
  questionId,
  storeName = 'textHighlightQuestionStore'
}: Props) => {
  const currentTranslatedQuestion = useQuestionEditorV2Store((state) =>
    state.currentTranslatedQuestion()
  );

  const store = useMemo(
    (storeNameParam = storeName, questionIdParam = questionId) => {
      return setUpStore(TextHighlightQuestionStore, storeNameParam, questionIdParam);
    },
    [storeName, questionId]
  );

  const setInitialStoreData = useCallback(
    (questionData) => {
      callTargetedAction({
        name: textHighlightQuestionActions.SET_UP_QUESTION,
        payload: {
          highlightPrompt: questionData.getHighlightPrompt(),
          preferredPermutation: questionData.getPreferredPermutation()
        },
        targetStore: store.getName()
      });
    },
    [store]
  );

  const clearSelections = useCallback(() => {
    callTargetedAction({
      name: textHighlightQuestionActions.CLEAR_SELECTIONS,
      targetStore: storeName
    });
  }, [storeName]);

  const setGuessSubmitted = useCallback(() => {
    callTargetedAction({
      name: textHighlightQuestionActions.SET_GUESS_SUBMITTED,
      payload: isGuessSubmitted,
      targetStore: storeName
    });
  }, [isGuessSubmitted, storeName]);

  // Initialize store when component mounts or when questionId changes
  useEffect(() => {
    setInitialStoreData(question);
  }, [questionId, question, setInitialStoreData]);

  // Handle clear selection and guess submission
  useEffect(() => {
    // Clear selections if needed
    if (store.isGuessSubmitted === true && isGuessSubmitted === false) {
      clearSelections();
    }

    // Set guess submitted if different
    if (isGuessSubmitted !== store.isGuessSubmitted) {
      setGuessSubmitted();
    }
  }, [isGuessSubmitted, store, clearSelections, setGuessSubmitted]);

  // Cleanup when component unmounts
  useEffect(() => {
    return () => {
      setGuessSubmitted();
    };
  }, [setGuessSubmitted]);

  return (
    <div className='text-highlight-question'>
      <MarkdownRendererV2
        className='question-prompt'
        text={currentTranslatedQuestion?.prompt || question.getPrompt()}
      />
      <h2>Highlight Answer(s) Below</h2>

      {currentTranslatedQuestion?.highlight_prompt ? (
        <div className='u-mar-b_1'>
          <Text color='warning' size='l'>
            Text highlight translations are only shown for reference. Text highlight questions must
            be answered in their original language.
          </Text>
          <MarkdownRendererV2
            text={currentTranslatedQuestion.highlight_prompt}
            className='u-mar-t_1'
          />
        </div>
      ) : (
        <TextHighlightPrompt storeName={storeName} isCorrect={isCorrect} />
      )}
    </div>
  );
};

export default TextHighlightQuestion;
