import React from 'react';
import PropTypes from 'prop-types';
import {pushQueryParams} from 'client/history';
import classnames from 'classnames';
import {callAction} from 'client/framework';
import Button from 'sg/Button/Button.react';
import {Button as AtomicButton} from '@albert-io/atomic';
import supplementManagerActions from 'client/Supplements/SupplementManager/SupplementManager.actions';
import './supplement-manager.scss';

export default class SupplementModalButton extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    hideButtonCopy: PropTypes.bool,
    linkButton: PropTypes.bool,
    pathToProperty: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    disabled: PropTypes.bool
  };

  handleClick = () => {
    pushQueryParams({
      supplementMode: 'search'
    });
    callAction(supplementManagerActions.SET_CURRENT_EDITOR_FIELD, this.props.pathToProperty);
    callAction(supplementManagerActions.SHOW_MODAL, true);
  };

  render() {
    const buttonClass = classnames('supplement-modal-button', this.props.className, {
      'supplement-modal-button--hide-copy fa fa-plus': this.props.hideButtonCopy
    });
    const text = this.props.hideButtonCopy ? null : 'Add supplement';
    return this.props.linkButton ? (
      <Button
        disabled={this.props.disabled}
        linkButton
        className={buttonClass}
        text={text}
        title='Add supplement'
        onClick={this.handleClick}
      />
    ) : (
      <AtomicButton
        className={buttonClass}
        onClick={this.handleClick}
        disabled={this.props.disabled}
        data-testid='supplement-manager-modal-btn'
      >
        Add supplement
      </AtomicButton>
    );
  }
}
