import React, {useEffect, useCallback} from 'react';
import {Map, List} from 'immutable';
import {v4 as uuid} from 'uuid';
import {callTargetedAction, setUpStore} from 'client/framework';
import TextArea from 'generic/Forms/TextArea/TextArea.react';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import IconTooltip from 'sg/IconTooltip/IconTooltip.react';
import Button from 'sg/Button/Button.react';

import {AuthoringQuestionModelV1} from 'resources/augmented/AuthoringQuestion/AuthoringQuestionModel.v1';

import {useQuestionEditorV2Store} from 'client/EditPage/V2/QuestionEditorV2Store';

import {useShallow} from 'zustand/react/shallow';

import {PRIMARY_CONTENT_LANGUAGE} from 'client/EditPage/V2/QuestionEditorV2Store.types';

import SnippetSelectionQuestionStore from './SnippetSelectionQuestion.store';
import snippetSelectionQuestionActions from './SnippetSelectionQuestion.actions';

function getSnippetsInPrompt(prompt: string) {
  return prompt.match(/{{(.*?)}}/g);
}

function formatSnippets(snippets: RegExpMatchArray) {
  return snippets.map((option) => option.slice(2, -2));
}

function createOptions(optionsArray: string[]) {
  return List(
    optionsArray.map((option) => {
      return Map({
        label: '',
        value: option,
        id: uuid()
      });
    })
  );
}

interface Props {
  question: AuthoringQuestionModelV1;
  onModelChange: (question: AuthoringQuestionModelV1, ...fields: string[]) => void;
  validationErrors: Map<string, string>;
}

const SnippetSelectionQuestionEditor = ({question, onModelChange, validationErrors}: Props) => {
  return (
    <div className='multiplechoice-editor'>
      <SnippetSelectionPrompt
        question={question}
        onModelChange={onModelChange}
        validationErrors={validationErrors}
      />
    </div>
  );
};

export default SnippetSelectionQuestionEditor;

const SnippetSelectionPrompt = ({question, onModelChange, validationErrors}: Props) => {
  const questionId = question.getId();
  const getStore = useCallback(
    () => setUpStore(SnippetSelectionQuestionStore, 'SnippetSelectionEditorStore', questionId),
    [questionId]
  );

  getStore();

  const {currentLanguage, translatedSnippetPrompt, updateTranslatedQuestionField} =
    useQuestionEditorV2Store(
      useShallow((state) => ({
        currentLanguage: state.currentLanguage,
        updateTranslatedQuestionField: state.updateTranslatedQuestionField,
        translatedSnippetPrompt:
          state
            .currentTranslatedQuestion()
            ?.translated_fields?.find((field) => field.field === 'snippet_prompt')?.text || ''
      }))
    );

  const setSelectedOption = useCallback(
    (questionModel) => {
      const targetStore = getStore().getName();
      callTargetedAction({
        name: snippetSelectionQuestionActions.SET_SELECTED_OPTIONS,
        targetStore,
        payload: questionModel.getValidResponse().keySeq().toSet()
      });
    },
    [getStore]
  );

  const updateOptions = useCallback(
    (startIndex: number, snippets: string[]) => {
      const snippetOptions = createOptions(snippets);
      const updatedOptions = question.getOptions().take(startIndex).concat(snippetOptions);
      const updatedValidResponses = question
        .getValidResponse()
        .filter((val, key) => updatedOptions.some((option) => option.get('id') === key));
      return question.setOptions(updatedOptions).setValidResponse(updatedValidResponses);
    },
    [question]
  );

  const handleOptionsMismatch = useCallback(
    (prompt: string) => {
      const snippetsInPrompt = getSnippetsInPrompt(prompt);
      if (!snippetsInPrompt) {
        return question;
      }
      const currentOptions = question.getOptions();
      if (!currentOptions.isEmpty() && !snippetsInPrompt) {
        // If our current options are not empty and we have no snippets, clear the options in our question
        return updateOptions(0, []);
      }
      const formattedSnippets = formatSnippets(snippetsInPrompt);

      let firstIndexWithMismatch;
      if (currentOptions.isEmpty()) {
        firstIndexWithMismatch = 0;
      } else if (snippetsInPrompt.length >= currentOptions.size) {
        firstIndexWithMismatch = formattedSnippets.findIndex((option, i) => {
          return !currentOptions.get(i) || option !== currentOptions.get(i).getValue();
        });
      } else {
        firstIndexWithMismatch = currentOptions.findIndex(
          (option, i) => option.getValue() !== formattedSnippets[i]
        );
      }

      if (firstIndexWithMismatch !== -1) {
        return updateOptions(
          firstIndexWithMismatch,
          formattedSnippets.slice(firstIndexWithMismatch)
        );
      }

      return question;
    },
    [question, updateOptions]
  );

  const updatePrompt = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement> | null, prompt: string) => {
      const value: string = prompt || e?.target.value || '';

      if (currentLanguage === PRIMARY_CONTENT_LANGUAGE) {
        const questionWithUpdatedOptions = handleOptionsMismatch(value);
        const updatedQuestion = questionWithUpdatedOptions.setSnippetPrompt(value);
        onModelChange(updatedQuestion, 'snippetPrompt', 'options');
      } else {
        updateTranslatedQuestionField(currentLanguage, 'snippet_prompt', 'text', value);
      }
    },
    [handleOptionsMismatch, onModelChange, currentLanguage, updateTranslatedQuestionField]
  );

  const handleOptionClick = useCallback(
    (optionId) => {
      const currentValidResponses = question.getValidResponse();
      let updatedValidResponses;
      if (currentValidResponses.has(optionId)) {
        updatedValidResponses = currentValidResponses.delete(optionId);
      } else {
        updatedValidResponses = currentValidResponses.set(optionId, true);
      }

      const updatedQuestion = question.setValidResponse(updatedValidResponses);

      onModelChange(updatedQuestion, 'rubric');

      callTargetedAction({
        name: snippetSelectionQuestionActions.TOGGLE_OPTION,
        targetStore: getStore().getName(),
        payload: optionId
      });
    },
    [question, onModelChange, getStore]
  );

  const snippetEverything = useCallback(() => {
    // Kill all {{ and }}
    const promptSansSnippets = question.getSnippetPrompt().replace(/[(?:{{)(?:}})]/g, '');
    // Wraps everything in the prompt that is not one of the
    // following (, . ; : ? ! ( ) — [ ] ") in curly braces.
    // This is a pretty loose temporary solution. Will revisit.
    const snippetedPrompt = promptSansSnippets.replace(/([^\s,.;:?!()—[\]"]+)/g, '{{$&}}');

    updatePrompt(null, snippetedPrompt);
  }, [question, updatePrompt]);

  useEffect(() => {
    setSelectedOption(question);
  }, [setSelectedOption, question]);

  // Equivalent to UNSAFE_componentWillUpdate
  useEffect(() => {
    if (question.getId() && getStore().questionId !== question.getId()) {
      setSelectedOption(question);
    }
  }, [question, getStore, setSelectedOption]);

  const snippetLabel = (
    <div>
      Snippet Text
      <IconTooltip
        content={`Enter the text for the interactive snippet
          selection area here. Wrap available snippet options
          in double curly braces {{like this}}.`}
      />
    </div>
  );

  const snippetPromptError = validationErrors.get('snippetPrompt');
  const snippetOptionsError = validationErrors.get('options');
  const rubricError = validationErrors.get('rubric');

  const value =
    currentLanguage === PRIMARY_CONTENT_LANGUAGE
      ? question.getSnippetPrompt()
      : translatedSnippetPrompt;

  return (
    <div className='snippet-selection-editor'>
      <TextArea
        className='question-editor__question-detail--fullwidth'
        label={snippetLabel}
        onChange={updatePrompt}
        value={value}
        data-testid='question-editor-snippet-text-input'
      />
      {currentLanguage === PRIMARY_CONTENT_LANGUAGE && (
        <>
          {snippetPromptError && <div className='a-form-input__error'>{snippetPromptError}</div>}
          {snippetOptionsError && <div className='a-form-input__error'>{snippetOptionsError}</div>}
          <div className='snippet-selection-editor__snippet-everything-wrapper'>
            <Button onClick={snippetEverything} text='Convert all words to snippets' />
          </div>
          <div className='snippet-selection-question'>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className='a-form-input__label'>
              Correct Options
              <IconTooltip
                content={`Select the correct snippet options.
              Your question must have at least one
              correct option.`}
              />
            </label>
            <MarkdownRendererV2
              text={question.getSnippetPrompt()}
              snippetSelectionData={{
                options: question.getOptions(),
                storeName: 'SnippetSelectionEditorStore',
                onOptionClick: handleOptionClick
              }}
            />
            {rubricError && <div className='a-form-input__error'>{rubricError}</div>}
          </div>
        </>
      )}
    </div>
  );
};
