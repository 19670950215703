import {createContext, Dispatch, SetStateAction} from 'react';
import {List, Map, Set} from 'immutable';

import {SubjectModelV2, GuideLevelModelV2} from '@albert-io/models';
import {GuideLevelMapType} from 'client/components/ContentDiscovery/SubjectAndGuideSelector/TopicsModal/GuideLevel.helpers';
import {
  Difficulties,
  QuestionTypes
} from 'client/components/ContentDiscovery/ContentDiscovery.utils';

export interface ContextProps {
  questionsQuery: any;
  setQuestionsQuery: (any) => void;
  questionDifficulties: List<Difficulties>;
  setQuestionDifficulties: (questionDifficulties: List<Difficulties>) => void;
  questionTypes: List<QuestionTypes>;
  setQuestionTypes: (questionTypes: List<QuestionTypes>) => void;
  hidePreviouslyAssigned: boolean;
  setHidePreviouslyAssigned: (boolean) => void;
  hideAssessmentQuestions: boolean;
  setHideAssessmentQuestions: (boolean) => void;
  searchSort: string | null;
  setSearchSort: (string) => void;
  hasFilters: boolean;
  clearFilters: () => void;
  clearTopicsModal: () => void;

  addXQuestionsVal: number;
  setAddXQuestionsVal: (val: number) => void;

  searchString: string;
  setSearchString: (string) => void;
  selectedTopicsModalSubjects: Set<SubjectModelV2>;
  setSelectedTopicsModalSubjects: (selectedTopicsModalSubjects) => void;
  guideCollection: Map<string, GuideLevelMapType>;
  setGuideCollection: (guideCollection) => void;

  selectedGuideLevels: List<GuideLevelModelV2>;
  setSelectedGuideLevels: (selectedGuideLevels) => void;
  selectedSubjects: List<SubjectModelV2>;
  setSelectedSubjects: (selectedSubjects) => void;

  showSummaryInformation: boolean;
  setShowSummaryInformation: Dispatch<SetStateAction<boolean>>;
  sidebarCollapsed: boolean;
  setSidebarCollapsed: Dispatch<SetStateAction<boolean>>;
  resetAssignmentBuilder: () => void;
}

export const ContentDiscoveryContext = createContext({} as ContextProps);
