import React from 'react';
import {upperFirst} from 'lib/stringUtils';
import withTracking from 'lib/hocs/withTracking';
import {Button, Modal, Dialogue, Text, ButtonGroup, WithTooltip, Badge} from '@albert-io/atomic';
import {QUESTIONSET_STATUSES} from 'client/constants';
import sessionStore from 'client/Session/SessionStore';
import {callAction} from 'client/framework';

import questionEditorActions from '../QuestionEditor/QuestionEditor.actions';
import questionEditorStore from '../QuestionEditor/QuestionEditor.store';

import SubmitForReviewModal from './SubmitForReviewModal/SubmitForReviewModal.react';

import './question-actions.scss';

const TrackedButton = withTracking(Button, {
  onClick: {
    feature: 'dennis-queue',
    event: 'click',
    type: 'button',
    component: 'approve_button',
    content: 'Approved'
  }
});
export default class QuestionActions extends React.Component {
  constructor() {
    super();
    this.state = {showApproveModal: false, showReviewModal: false};
  }

  toggleShowApproveModal = () => {
    this.setState((state) => ({showApproveModal: !state.showApproveModal}));
  };

  toggleShowReviewModal = () => {
    this.setState((state) => ({showReviewModal: !state.showReviewModal}));
  };

  generateStatusLabel() {
    const status = questionEditorStore.getQuestionSet().getStatus();
    return (
      <div className='question-editor__status-label-container'>
        <Badge emphasis='bold'>Status: {upperFirst(status)}</Badge>
      </div>
    );
  }

  canBePublished() {
    const questionSet = questionEditorStore.getQuestionSet();
    if (!questionSet) {
      return false;
    }
    return !(
      questionSet.getAuthoringGuideLevels().isEmpty() && questionSet.getAuthoringExams().isEmpty()
    );
  }

  handleSaveQuestion = () => {
    callAction(questionEditorActions.SAVE_QUESTION);
  };

  approveQuestionSet = () => {
    if (!this.canBePublished()) {
      return;
    }
    this.toggleShowApproveModal();
    callAction(questionEditorActions.APPROVE_QUESTION_SET);
  };

  rejectQuestionSet = () => {
    callAction(questionEditorActions.REJECT_QUESTION_SET);
  };

  revertToDraft = () => {
    callAction(questionEditorActions.REVERT_TO_DRAFT);
  };

  submitForReview = () => {
    if (!this.canBePublished()) {
      return;
    }
    callAction(questionEditorActions.SUBMIT_FOR_REVIEW);
  };

  getQuestionActions = () => {
    const isSuperUser = sessionStore.isSuper();
    const questionSetStatus = questionEditorStore.getQuestionSet().getStatus();
    const isSavePending = questionEditorStore.isSavePending();
    const canBeReviewed = [QUESTIONSET_STATUSES.REJECTED, QUESTIONSET_STATUSES.DRAFT_MODE].includes(
      questionSetStatus
    );
    if (isSuperUser) {
      return (
        <>
          <WithTooltip
            enabled={!this.canBePublished()}
            content='Please align the question to a guide before submitting.'
          >
            <Button
              size='s'
              disabled={!this.canBePublished() || isSavePending}
              onClick={this.toggleShowApproveModal}
            >
              Approve
            </Button>
          </WithTooltip>

          <Button
            size='s'
            className='u-mar-l_1'
            color='negative'
            onClick={this.rejectQuestionSet}
            disabled={isSavePending}
          >
            Reject
          </Button>
        </>
      );
    }

    if (canBeReviewed) {
      return (
        <WithTooltip
          enabled={!this.canBePublished()}
          content='Please align the question to a guide before submitting.'
        >
          <Button
            size='s'
            disabled={!this.canBePublished() || isSavePending}
            onClick={this.toggleShowReviewModal}
          >
            Submit for review
          </Button>
        </WithTooltip>
      );
    }
    if (QUESTIONSET_STATUSES.IN_REVIEW === questionSetStatus) {
      return (
        <Button size='s' onClick={this.revertToDraft} disabled={isSavePending}>
          Revert to draft
        </Button>
      );
    }
    return null;
  };

  render() {
    if (!questionEditorStore.isReady()) {
      return null;
    }

    const {showApproveModal, showReviewModal} = this.state;
    const isSavePending = questionEditorStore.isSavePending();
    return (
      <div className='question-actions card'>
        <ButtonGroup>
          {this.getQuestionActions()}
          <Button
            vriant='outlined'
            size='s'
            className='u-mar-l_1'
            disabled={isSavePending}
            onClick={this.handleSaveQuestion}
            data-testid='question-editor-save-btn'
          >
            Save
          </Button>
        </ButtonGroup>

        {this.generateStatusLabel()}
        {showApproveModal && (
          <Modal ariaLabel='Approve question modal' handleClose={this.toggleShowApproveModal}>
            {({CloseButtonWrapper, modalContentStyle}) => {
              return (
                <Dialogue
                  handleClose={this.toggleShowApproveModal}
                  inModal
                  className={modalContentStyle}
                  title='Approve question'
                >
                  <Dialogue.Body>
                    <Text>Are you sure you want to approve this question?</Text>
                  </Dialogue.Body>
                  <Dialogue.BtnGroup>
                    <CloseButtonWrapper>
                      <Button variant='text'>Cancel</Button>
                    </CloseButtonWrapper>
                    <TrackedButton onClick={this.approveQuestionSet}>Approve</TrackedButton>
                  </Dialogue.BtnGroup>
                </Dialogue>
              );
            }}
          </Modal>
        )}
        {showReviewModal && (
          <SubmitForReviewModal
            handleClose={this.toggleShowReviewModal}
            handleSubmit={this.submitForReview}
          />
        )}
      </div>
    );
  }
}
