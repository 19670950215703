import React, {lazy, Suspense, useCallback, useState} from 'react';
import {fromJS} from 'immutable';

import {Button, Modal, Dialogue, Icon, LoadingSpinner} from '@albert-io/atomic';

import {callAction} from 'client/framework';
import abandonQueueChangesStore from 'client/Dennis/Content/Queue/AbandonQueueChanges.store';
import ConfirmLeaveModal from 'generic/ConfirmLeaveModal/ConfirmLeaveModal.react';
import SupplementManagerModalButton from 'client/Supplements/SupplementManager/SupplementManagerModalButton.react';
import StandardSearch from 'client/EditPage/V2/StandardSearch/StandardSearch';
import SimpleDropdown from 'generic/SimpleDropdown/SimpleDropdown.react';
import TextInput from 'generic/Forms/TextInput/TextInput';

import QuestionSetAlignment from '../QuestionSetAlignment/QuestionSetAlignment';
import QuestionTypeManager from '../QuestionTypeManager';
import AuthoringTextInput from '../AuthoringTextInput/AuthoringTextInput.react';
import {QuestionEditorLinks} from '../QuestionEditorLinks/QuestionEditorLinks';
import {StatusDropdown} from '../StatusDropdown/StatusDropdown';

import DifficultyEditor from './DifficultyEditor';
import questionEditorActions from './QuestionEditor.actions';
import QuestionTagEditor from './QuestionTags/QuestionTagEditor';
import questionEditorStore from './QuestionEditor.store';

import './question-editor.scss';

const MarkdownCheatSheet = lazy(
  () => import('generic/MarkdownCheatSheet/MarkdownCheatSheet.react')
);

const subTypeOptions = fromJS([
  {
    id: 'free_response_question',
    name: 'FRQ'
  },
  {
    id: 'document_based_question',
    name: 'DBQ'
  },
  {
    id: 'classroom_sample_task',
    name: 'CST'
  },
  {
    id: 'short_answer',
    name: 'Short Answer'
  },
  {
    id: 'long_answer',
    name: 'Long Answer'
  }
]);

const QuestionEditor = () => {
  const [showMarkdownModal, setShowMarkdownModal] = useState(false);
  const toggleMarkdownModal = useCallback(() => {
    setShowMarkdownModal(!showMarkdownModal);
  }, [showMarkdownModal, setShowMarkdownModal]);

  const updateQuestionDetails = ({setter, validate, value}) => {
    callAction(questionEditorActions.UPDATE_QUESTION_DETAILS, {setter, value});
    callAction(questionEditorActions.VALIDATE_QUESTION_FIELD, validate);
  };

  const handleChange = (e) => {
    const element = e.target;
    updateQuestionDetails({
      setter: element.dataset.setterMethod,
      validate: element.dataset.validate,
      value: element.value
    });
  };

  const handleAuthoringTextInputChange = (e) => {
    updateQuestionDetails({
      setter: e.target.dataset.setterMethod,
      validate: e.target.dataset.validate,
      value: e.target.value
    });
  };

  const handleTagChange = (tag, remove = false) => {
    callAction(questionEditorActions.UPDATE_QUESTION_SET_TAGS, {remove, tag});
  };

  const handleQuestionTypeChange = (model, ...methodsToValidate) => {
    callAction(questionEditorActions.UPDATE_QUESTION, model);
    if (methodsToValidate.length > 0) {
      methodsToValidate.forEach((method) => {
        callAction(questionEditorActions.VALIDATE_QUESTION_FIELD, method);
      });
    }
  };

  const handleUpdateQuestion = useCallback((updateFunc) => {
    const question = questionEditorStore.getQuestion();
    const updatedQuestion = updateFunc(question);
    callAction(questionEditorActions.UPDATE_QUESTION, updatedQuestion);
  }, []);

  const shouldPromptLeave = () => {
    const currentQuestionSet = questionEditorStore.getQuestionSet();
    const currentQuestion = questionEditorStore.getQuestion();
    if (!currentQuestion) {
      return false;
    }

    const hasChanges =
      !currentQuestion.getChangeMap().isEmpty() || !currentQuestionSet.getChangeMap().isEmpty();
    if (hasChanges && abandonQueueChangesStore.hasAbandonedChanges()) {
      return false;
    }

    return hasChanges;
  };

  const handleBlur = (e) => {
    const {dataset, selectionStart, selectionEnd} = e.target;
    const name = dataset.fieldPath || e.target.name;
    callAction(questionEditorActions.SAVE_CURSOR_POSITION, {
      end: selectionEnd,
      name,
      start: selectionStart
    });
  };

  if (!questionEditorStore.isReady()) {
    return null;
  }
  const question = questionEditorStore.getQuestion();
  const questionSet = questionEditorStore.getQuestionSet();
  const errors = questionEditorStore.getValidationErrors();
  return (
    <div className='question-editor'>
      <div className='question-editor__question-edit-wrapper card'>
        <div className='question-editor__question-details'>
          <StatusDropdown />
          <SubTypeDropdown />
          <DifficultyEditor
            className='question-editor__question-detail--fullwidth'
            difficulty={question.getDifficulty()}
            data-validate='difficulty'
            error={Boolean(errors.get('difficulty'))}
            errorMessage={errors.get('difficulty')}
          />
          <hr />
          <TextInput
            className='question-editor__question-detail--fullwidth'
            label='Title'
            data-setter-method='setTitle'
            data-validate='title'
            name='title'
            value={question.getTitle()}
            onChange={handleChange}
            error={Boolean(errors.get('title'))}
            errorMessage={errors.get('title')}
            data-testid='question-editor-title-input'
          />
          <AuthoringTextInput
            label='Prompt'
            data-setter-method='setPrompt'
            data-validate='prompt'
            data-field-path='prompt'
            value={question.getPrompt()}
            error={errors.get('prompt')}
            onChange={handleAuthoringTextInputChange}
            onBlur={handleBlur}
            className='question-editor__question-detail--fullwidth question-editor__prompt'
          />
          <div className='question-editor__cheat-sheet-wrapper'>
            <Button variant='text' onClick={toggleMarkdownModal}>
              Markdown cheat sheet
            </Button>
            {showMarkdownModal && (
              <Modal longModal ariaLabel='Markdown cheat sheet' handleClose={toggleMarkdownModal}>
                {({modalContentStyle}) => {
                  return (
                    <Dialogue
                      handleClose={toggleMarkdownModal}
                      className={modalContentStyle}
                      size='xl'
                    >
                      <Dialogue.Body>
                        <Suspense fallback={<LoadingSpinner />}>
                          <MarkdownCheatSheet />
                        </Suspense>
                      </Dialogue.Body>
                    </Dialogue>
                  );
                }}
              </Modal>
            )}
            <Button
              className='u-mar-l_1'
              as='a'
              href='https://docs.google.com/document/d/1EQEOtnk4laR_dMrAIjGcRRAC3XiUPTW4sKc6_kJk20E/edit#heading=h.n9hcmim2zhbz'
              target='_blank'
              variant='text'
            >
              LaTeX cheat sheet
              <Icon className='u-mar-l_1' icon='external-link-alt' />
            </Button>
            <Button
              className='u-mar-l_1'
              as='a'
              href='https://sites.google.com/albert.io/authorwiki/'
              target='_blank'
              variant='text'
            >
              Author Wiki <Icon className='u-mar-l_1' icon='external-link-alt' />
            </Button>
          </div>
          <SupplementManagerModalButton
            className='question-editor__add-supplement-btn'
            pathToProperty='prompt'
          />
        </div>
        <div className='question-editor__question-type'>
          <QuestionTypeManager
            question={question}
            onBlur={handleBlur}
            onModelChange={handleQuestionTypeChange}
            validationErrors={errors}
            updateQuestion={handleUpdateQuestion}
          />
        </div>
        <hr />
        <div className='question-editor__question-otherstuff'>
          <AuthoringTextInput
            label='Explanation of Solution'
            data-setter-method='setSolutionText'
            data-validate='solution_text'
            height='auto'
            data-field-path='solution_text'
            value={question.getSolutionText()}
            error={errors.get('solution_text')}
            onChange={handleAuthoringTextInputChange}
            onBlur={handleBlur}
            className='question-editor__question-detail--fullwidth question-editor__solution'
          />
          <SupplementManagerModalButton
            className='question-editor__add-supplement-btn'
            pathToProperty='solution_text'
          />
        </div>
        <div className='question-editor__label-tag-inputs'>
          <QuestionEditorLinks />
          <StandardSearch
            standards={question.getStandards()}
            subjectId={questionSet.getSubjectId()}
            disabled={!questionSet.getId()}
          />
          <QuestionTagEditor tags={questionSet.getTags()} onTagClick={handleTagChange} />
        </div>
        <QuestionSetAlignment questionSetId={questionSet.getId()} />
        <ConfirmLeaveModal
          shouldPromptFunc={shouldPromptLeave}
          modalTitle='You have unsaved changes'
          modalPrompt={
            <p>
              There are unsaved changes on this question.
              <br />
              Are you sure you want to leave?
            </p>
          }
        />
      </div>
    </div>
  );
};

export default QuestionEditor;

const SubTypeDropdown = () => {
  if (questionEditorStore.getQuestion().getQuestionType() !== 'free-response') {
    return null;
  }

  const handleSubTypeChange = (option) => {
    callAction(questionEditorActions.UPDATE_QUESTION_DETAILS, {
      setter: 'setQuestionSubType',
      value: option.get('id')
    });
  };

  return (
    <SimpleDropdown
      className='question-editor__question-detail--fullwidth'
      label='Question Sub-Type'
      activeItemId={questionEditorStore.getQuestion().getQuestionSubType()}
      options={subTypeOptions}
      storeName='QuestionSubTypeStore'
      onChange={handleSubTypeChange}
      destroyStoreOnUnmount
    />
  );
};
