import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import classnames from 'classnames';
import {defer, isUndefined} from 'lodash';
import {callTargetedAction} from 'client/framework';
import interactionEngineStore from 'client/InteractionEngineV2/InteractionEngineStore';
import studentAssignmentQuestionsListStore from 'client/InteractionEngineV2/IESessionTypes/Assignments/StudentAssignmentIE/StudentAssignmentQuestionsList/StudentAssignmentQuestionsList.store';
import {QuestionModelV3} from 'resources/augmented/Question/QuestionModel.v3';
import {useShallow} from 'zustand/react/shallow';
import {useQuestionTranslationStore} from 'components/QuestionTypes/shared/TranslationSection';

import textHighlightQuestionActions from '../TextHighlightQuestion.actions';

interface TextHighlightPromptProps {
  explanation?: boolean;
  isGuessCorrect?: boolean;
  isGuessSubmitted?: boolean;
  question?: QuestionModelV3;
  storeName?: string;
  className?: string;
}

/*
legacy class propTypes ref
  static propTypes = {
    explanation: PropTypes.bool,
    isGuessCorrect: PropTypes.bool,
    isGuessSubmitted: PropTypes.bool,
    question: PropTypes.instanceOf(QuestionModelV3),
    storeName: PropTypes.string,
    className: PropTypes.string
  };
*/

const TextHighlightPrompt = ({
  explanation,
  isGuessCorrect,
  isGuessSubmitted,
  question,
  storeName,
  className
}: TextHighlightPromptProps) => {
  // TRANSLATION LOGIC ---

  const {translatedQuestionInfo, showAnswerTranslation} = useQuestionTranslationStore(
    useShallow((state) => ({
      translatedQuestionInfo: state.translatedQuestionInfo,
      showAnswerTranslation: state.toggleStatus.answer
    }))
  );

  const highlightPrompt = useMemo(() => {
    if (showAnswerTranslation && translatedQuestionInfo?.highlight_prompt) {
      return translatedQuestionInfo.highlight_prompt;
    }
    return question.getStore().highlightPrompt;
  }, [question, translatedQuestionInfo, showAnswerTranslation]);

  // --- END TRANSLATION LOGIC

  // wrapper ref ---------
  const highlightAreaWrapperNode = useRef<HTMLDivElement>(null);
  const mouseupListenerFunc = useRef<((e: any) => void) | null>(null);
  const touchListenerFunc = useRef<((e: any) => void) | null>(null);
  const mouseMoveTicking = useRef<boolean>(false);
  // const [mouseMoveTicking, setMouseMoveTicking] = useState(false);

  useEffect(() => {
    return () => {
      if (mouseupListenerFunc.current) {
        // handleEndSelection
        global.document.removeEventListener('mouseup', mouseupListenerFunc.current);
      }
      if (touchListenerFunc.current) {
        // handleTouchEnd
        global.document.removeEventListener('touchend', touchListenerFunc.current);
      }
    };
  }, []);

  // prompt logic and rendering

  const getClassNames = useCallback(
    (index) => {
      const baseClass = 'text-highlight-area__char';
      const highlightClass = 'text-highlight-area__char--highlight';

      if (explanation) {
        return classnames(baseClass, {
          [highlightClass]: question.getStore().isIndexInPreferredPermutation(index)
        });
      }

      return classnames(baseClass, {
        [highlightClass]: question.getStore().isIndexSelected(index),
        'text-highlight-area__char--current': question.getStore().isIndexInCurrentSelection(index),
        'text-highlight-area__char--limit':
          index === question.getStore().startIndex || index === question.getStore().potentialIndex
      });
    },
    [question, explanation]
  );

  const getTargetElementFromTouchEventData = useCallback((eventProperty) => {
    const {pageX, pageY} = eventProperty[eventProperty.length - 1];
    const {scrollX, scrollY} = global.window;
    const xPos = pageX - scrollX;
    const yPos = pageY - scrollY;
    return global.document.elementFromPoint(xPos, yPos);
  }, []);

  const pendingSelectionEnd = useCallback(
    (index) => {
      if (question.getStore().startIndex === null) {
        return;
      }
      callTargetedAction({
        name: textHighlightQuestionActions.POTENTIAL_SELECTION,
        payload: index,
        targetStore: storeName
      });
    },
    [question, storeName]
  );

  // main selection functions
  const startSelection = useCallback(
    (index) => {
      if (question?.getStore?.().isGuessSubmitted) {
        return;
      }
      callTargetedAction({
        name: textHighlightQuestionActions.START_SELECTION,
        payload: index,
        targetStore: storeName
      });
    },
    [storeName, question]
  );

  const endSelection = useCallback(
    (index, targetEl) => {
      if (isUndefined(index) || !highlightAreaWrapperNode.current?.contains(targetEl)) {
        callTargetedAction({
          name: textHighlightQuestionActions.CLEAR_PENDING_SELECTION,
          targetStore: storeName
        });
        return;
      }
      callTargetedAction({
        name: textHighlightQuestionActions.END_SELECTION,
        payload: index,
        targetStore: storeName
      });
    },
    [storeName]
  );

  // handlers

  const handleEndSelection = useCallback(
    (e) => {
      global.document.removeEventListener('mouseup', mouseupListenerFunc.current!);
      endSelection(e.target.dataset.index, e.target);
      mouseupListenerFunc.current = null;
    },
    [endSelection]
  );

  const handleMouseDown = useCallback(
    (e) => {
      global.document.addEventListener('mouseup', handleEndSelection);
      mouseupListenerFunc.current = handleEndSelection;
      startSelection(e.target.dataset.index);
    },
    [startSelection, handleEndSelection]
  );

  const handleTouchEnd = useCallback(
    (e) => {
      global.document.removeEventListener('touchend', touchListenerFunc.current!);
      touchListenerFunc.current = null;
      const target = getTargetElementFromTouchEventData(e.changedTouches) as any;
      const {index} = target.dataset;
      endSelection(index, target);
    },
    [endSelection, getTargetElementFromTouchEventData]
  );

  const handleTouchStart = useCallback(
    (e) => {
      e.preventDefault();
      global.document.addEventListener('touchend', handleTouchEnd);
      touchListenerFunc.current = handleTouchEnd;
      startSelection(e.target.dataset.index);
    },
    [startSelection, handleTouchEnd]
  );

  const handleMouseEnter = useCallback(
    (e) => {
      pendingSelectionEnd(e.target.dataset.index);
    },
    [pendingSelectionEnd]
  );

  const handleTouchMove = useCallback(
    (e) => {
      e.preventDefault();
      if (mouseMoveTicking.current) {
        return;
      }
      mouseMoveTicking.current = true;
      const target = getTargetElementFromTouchEventData(e.targetTouches) as any;
      pendingSelectionEnd(target.dataset.index);
      defer(() => {
        mouseMoveTicking.current = false;
      });
    },
    [mouseMoveTicking, pendingSelectionEnd, getTargetElementFromTouchEventData]
  );

  // Children react components

  // logic booleans -------
  const isExplanation = useMemo(() => !!explanation, [explanation]);
  const shouldPreventInteraction = useMemo(
    () => isGuessSubmitted || isExplanation,
    [isGuessSubmitted, isExplanation]
  );
  // props.isGuessCorrect is false if you haven't answered, which is
  // why we're being so explicit about this
  const isCorrect = useMemo(
    () => isGuessSubmitted && isGuessCorrect,
    [isGuessCorrect, isGuessSubmitted]
  );
  const isIncorrect = useMemo(
    () => isGuessSubmitted && !isGuessCorrect,
    [isGuessSubmitted, isGuessCorrect]
  );

  // from legacy component,
  // studentAssignmentQuestionsListStore changes in an unobsevable way, so putting in a hook does not
  // update as needed
  let showGuessFeedback = true;
  if (
    interactionEngineStore.isStudentAssignmentSession &&
    !studentAssignmentQuestionsListStore.getAssignment().shouldShowGuessFeedback()
  ) {
    showGuessFeedback = false;
  }

  // styling ------------
  const classNames = useMemo(
    () =>
      classnames('text-highlight-area', className, {
        'text-highlight-area--prevent-interaction': shouldPreventInteraction,
        'text-highlight-area--correct': isCorrect && showGuessFeedback,
        'text-highlight-area--incorrect': isIncorrect && showGuessFeedback && !isExplanation
      }),
    [isCorrect, isIncorrect, showGuessFeedback, shouldPreventInteraction, isExplanation]
  );

  return (
    <div className={classNames} ref={highlightAreaWrapperNode}>
      {highlightPrompt.split('').map((char, i) => {
        return (
          <span
            key={i}
            className={getClassNames(i)}
            data-index={`${i}`}
            onMouseDown={isGuessSubmitted ? undefined : handleMouseDown}
            onMouseEnter={isGuessSubmitted ? undefined : handleMouseEnter}
            onTouchStart={isGuessSubmitted ? undefined : handleTouchStart}
            onTouchMove={isGuessSubmitted ? undefined : handleTouchMove}
          >
            {char}
          </span>
        );
      })}
    </div>
  );
};

export default TextHighlightPrompt;
