import React, {useCallback} from 'react';
import appStore from 'client/AppStore';
import classnames from 'classnames';
import {callTargetedAction} from 'client/framework';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';

import {TwoWayQuestionModel, TwoWayRow} from 'resources/Question/Question.model';
import {AuthoringQuestionModelV1} from 'resources/augmented/AuthoringQuestion/AuthoringQuestionModel.v1';
import {Badge, Text, Icon} from '@albert-io/atomic';

import {TranslatedQuestion} from 'client/EditPage/V2/QuestionEditorV2Store.types';

import {fromJS, List} from 'immutable';

import twoWayQuestionActions from './TwoWayQuestion.actions';
import TwoWayQuestionStore from './TwoWayQuestion.store';

import './two-way-v2.scss';

interface TwoWayQuestionTableProps {
  isGuessSubmitted: boolean;
  question: TwoWayQuestionModel | AuthoringQuestionModelV1;
  currentTranslatedQuestion: TranslatedQuestion | null;
  store: TwoWayQuestionStore;
}

const TwoWayQuestionTable = ({
  isGuessSubmitted = false,
  question,
  currentTranslatedQuestion,
  store
}: TwoWayQuestionTableProps) => {
  const isInDennis = appStore.routerProps.location.pathname.includes('/dennis/');
  const stickyClass = isInDennis
    ? 'twq-table__heading--sticky-dennis'
    : 'twq-table__heading--sticky';
  const rows = currentTranslatedQuestion?.rows
    ? List(currentTranslatedQuestion.rows.map((row) => new TwoWayRow(fromJS(row))))
    : question.getRows();

  return (
    <table
      className={classnames('twq-table', {
        'twq-table--full-width': question.containsLatex()
      })}
    >
      <thead>
        <tr>
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <th className={classnames(stickyClass, 'twq-table__heading--empty')} />
          <th className={stickyClass}>
            <MarkdownRendererV2
              text={currentTranslatedQuestion?.header_left || question.getLeftHeader()}
              className='twq-table__heading'
            />
          </th>
          <th className={stickyClass}>
            <MarkdownRendererV2
              text={currentTranslatedQuestion?.header_right || question.getRightHeader()}
              className='twq-table__heading'
            />
          </th>
        </tr>
      </thead>
      <tbody>
        {rows.map((row) => (
          <Row
            key={row.getId()}
            row={row}
            store={store}
            question={question}
            isGuessSubmitted={isGuessSubmitted}
          />
        ))}
      </tbody>
    </table>
  );
};

interface RowProps {
  row: any;
  store: TwoWayQuestionStore;
  question: TwoWayQuestionModel | AuthoringQuestionModelV1;
  isGuessSubmitted: boolean;
}

const Row = ({row, store, question, isGuessSubmitted}: RowProps) => {
  const handleCheck = useCallback(
    (e) => {
      if (e.type === 'keypress' && e.key !== 'Enter') {
        return;
      }
      callTargetedAction({
        name: twoWayQuestionActions.SELECT_COLUMN,
        payload: e.currentTarget.dataset.id,
        targetStore: store.getName()
      });
      if (e.type === 'click') {
        e.currentTarget.blur();
      }
    },
    [store]
  );

  const generateIndicator = useCallback(
    (isSelected, isCorrectAnswer, isMissedAnswer) => {
      if (!isGuessSubmitted || (!isSelected && !isCorrectAnswer && !isMissedAnswer)) {
        return null;
      }
      return (
        <div className='twq-table-row__correctness-indicator'>
          {isCorrectAnswer && (
            <Badge
              emphasis='bold'
              size='small'
              className='twq-table-row__correctness-indicator-correct'
            >
              <Text color='primary-inverse' size='xs'>
                Correct
                <Icon
                  className='u-mar-l_1 twq-table-row__correctness-indicator_icon'
                  icon={['fas', 'thumbs-up']}
                />
              </Text>
            </Badge>
          )}
          {!isCorrectAnswer && !isMissedAnswer && (
            <Badge emphasis='bold' size='small' status='negative'>
              Incorrect
              <Icon
                className='u-mar-l_1 twq-table-row__correctness-indicator_icon'
                icon={['far', 'xmark']}
              />
            </Badge>
          )}
          {isMissedAnswer && (
            <Badge emphasis='bold' size='small' status='negative'>
              Missed
              <Icon
                className='u-mar-l_1 twq-table-row__correctness-indicator_icon'
                icon={['far', 'xmark']}
              />
            </Badge>
          )}
        </div>
      );
    },
    [isGuessSubmitted]
  );

  return (
    <tr className='twq-table-row'>
      <td className='twq-table-row__cell'>
        <MarkdownRendererV2 text={row.getStatement()} />
      </td>
      {['Left', 'Right'].map((side) => {
        const option = row[`get${side}Column`]();
        const optionId = option.getId();
        const isChecked = store.isSelectedColumn(optionId);
        let postSubmitClassNames;
        let isCorrectSelection;
        const isCorrectOption = question.getValidResponse().has(optionId) && isChecked;
        const isMissedOption = question.getValidResponse().has(optionId) && !isChecked;
        if (isGuessSubmitted) {
          isCorrectSelection = isCorrectOption ? isChecked : !isChecked;
          postSubmitClassNames = {
            'twq-table-row__cell--submitted': true,
            'twq-table-row__cell--correct-selection': isCorrectOption,
            'twq-table-row__cell--missed-selection': isMissedOption,
            'twq-table-row__cell--incorrect-selection': !isCorrectSelection
          };
        }
        // If option's contents is empty, or consists only of whitespace, render a pseudo-checkbox
        const cellContents = /^\s*$/.test(option.getText()) ? (
          <div
            className={classnames('twq-table-row__checkmark', {
              'twq-table-row__checkmark--checked': isChecked
            })}
          />
        ) : (
          <MarkdownRendererV2 text={option.getText()} className='twq-table-row__input-text' />
        );
        return (
          <td
            key={side}
            className={classnames(
              'twq-table-row__cell',
              'twq-table-row__cell-option',
              {
                'twq-table-row__cell-option--checked': isChecked,
                'twq-table-row__cell-option--unchecked': !isChecked
              },
              postSubmitClassNames
            )}
            onClick={isGuessSubmitted ? undefined : handleCheck}
            onKeyPress={isGuessSubmitted ? undefined : handleCheck}
            data-id={optionId}
            role={isGuessSubmitted ? '' : 'checkbox'}
            aria-checked={isGuessSubmitted ? '' : isChecked}
            tabIndex={isGuessSubmitted ? undefined : 0}
          >
            {cellContents}
            {generateIndicator(isChecked, isCorrectOption, isMissedOption)}
          </td>
        );
      })}
    </tr>
  );
};

export default TwoWayQuestionTable;
