import {Map} from 'immutable';

import PassageCorrectionQuestionEditor from './PassageCorrectionEditor/PassageCorrectionEditor';
import PassageCorrectionQuestion from './PassageCorrectionQuestion/PassageCorrectionQuestion';
import PassageCorrectionExplanation from './PassageCorrectionQuestion/PassageCorrectionExplanation';

/**
 * @todo: Make a generic response stats component that returns
 * percentage correct / percentage incorrect and use that here
 */
const PassageCorrectionResponseStats = () => null;

const PassageCorrectionQuestionType = Map({
  type: 'passage-correction',
  label: 'Passage Correction',
  questionRenderer: PassageCorrectionQuestion, // New IE renderer
  explanationRenderer: PassageCorrectionExplanation,
  responseStatsRenderer: PassageCorrectionResponseStats,
  editor: PassageCorrectionQuestionEditor,
  isHidden: false
});

export default PassageCorrectionQuestionType;
