import React, {useCallback, useMemo} from 'react';
import {callTargetedAction, setUpStore} from 'client/framework';
import classnames from 'classnames';

import freeEntryQuestionActions from './FreeEntryQuestion.actions';
import FreeEntryQuestionStore from './FreeEntryQuestion.store';

interface Props {
  inputId: string;
  topAnswers: any;
  totalAttempts: number;
  usersGuess: string | null;
  storeName: string;
}

const FreeEntryQuestionResponseStatsTopFiveAttempts = ({
  inputId,
  topAnswers,
  totalAttempts,
  usersGuess,
  storeName
}: Props) => {
  const store = useMemo(() => setUpStore(FreeEntryQuestionStore, storeName), [storeName]);

  const toggleTopFive = useCallback(() => {
    callTargetedAction({
      name: freeEntryQuestionActions.TOGGLE_TOP_FIVE,
      payload: inputId,
      targetStore: storeName
    });
  }, [inputId, storeName]);

  const isTopFiveOpen = store.isTopFiveOpen(inputId);
  const isShowingToggleText = isTopFiveOpen ? 'Hide top answers' : 'Show top answers';
  const isShowingClasses = classnames('question-response-top-attempts__toggle', {
    'is-showing': isTopFiveOpen
  });
  const listIsShowingClasses = classnames('question-response-top-attempts__list', {
    'is-showing': isTopFiveOpen
  });

  return (
    <div className='question-response-top-attempts'>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div className={isShowingClasses} onClick={toggleTopFive}>
        {isShowingToggleText}
      </div>
      <ul className={listIsShowingClasses}>
        {topAnswers
          .map((stat, i) => {
            const isCorrect = stat.isCorrect();
            const isUsersGuess = stat.getInputValue() === usersGuess;
            const percentageCorrect = stat.getPercentageResponseRate(totalAttempts);
            const attemptPercentageClasses = classnames(
              'question-response-top-attempts__list-item',
              {
                correct: isCorrect,
                selected: isUsersGuess,
                incorrect: !isCorrect
              }
            );
            const isCorrectIndicator = classnames(null, {
              'fa fa-check-circle': isCorrect,
              'fa fa-times-circle': !isCorrect
            });
            return (
              // eslint-disable-next-line react/no-array-index-key
              <li key={i} className={attemptPercentageClasses}>
                <span className={isCorrectIndicator} />
                <span className='question-response-top-attempts__attempt-value'>
                  {stat.getInputValue()}
                </span>
                <span className='question-response-top-attempts__attempt-percentage'>
                  {`${percentageCorrect}%`}
                </span>
              </li>
            );
          })
          .toList()}
      </ul>
    </div>
  );
};

export default FreeEntryQuestionResponseStatsTopFiveAttempts;
