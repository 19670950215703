import {Map} from 'immutable';

import TextHighlightQuestionV2 from './V2/TextHighlightQuestion';
import TextHighlightExplanation from './V2/TextHighlightExplanation';
import TextHighlightEditor from './TextHighlightEditor';

export const TextHighlightQuestionType = Map({
  type: 'text-highlight',
  label: 'Text Highlight',
  questionRenderer: TextHighlightQuestionV2,
  explanationRenderer: TextHighlightExplanation,
  responseStatsRenderer: () => null,
  editor: TextHighlightEditor,
  isHidden: false
});
