/* eslint-disable */


// Programatically generated on Wed Apr 02 2025 19:07:43 GMT+0000
import {fromJS, List, Map, Record, Set} from 'immutable';
import {GenericModel} from 'resources/Generic.model';
import {validate, Empty} from '@albert-io/json-api-framework/models/helpers';
import moment from 'moment';

const ModelConfig = fromJS(
  {
    'endpoint': 'authoring_feedback_v1',
    'attributes': {
      'sent_email_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'message': {
        'type': 'string',
        'isRequired': true,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'email_message': {
        'type': 'string',
        'isRequired': true,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'updated_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'inserted_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'id': {
        'type': 'binary_id',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      }
    },
    'relationships': {
      'authoring_question_v1': {
        'type': 'one-to-one'
      },
      'user_v1': {
        'type': 'one-to-one'
      },
      'user_v2': {
        'type': 'one-to-one'
      },
      'question_v1': {
        'type': 'one-to-one'
      },
      'question_v2': {
        'type': 'one-to-one'
      },
      'question_v3': {
        'type': 'one-to-one'
      },
      'search_question_v1': {
        'type': 'one-to-one'
      },
      'public_question_v1': {
        'type': 'one-to-one'
      },
      'search_question_v2': {
        'type': 'one-to-one'
      }
    }
  }
);

const ModelRecord = Record(
  {
    'email_message': Empty,
    'question': Empty,
    'message': Empty,
    'user': Empty,
    'authoring_question': Empty,
    'updated_at': Empty,
    'inserted_at': Empty,
    'id': Empty,
    'public_question': Empty,
    'relationshipMeta': Empty,
    'sent_email_at': Empty,
    'search_question': Empty,
    'meta': new Map({
    'populated_metadata_fields': Empty,
    'user_email': Empty,
    'user_first_name': Empty,
    'user_last_name': Empty
  })
  }
);

const DefaultModel = fromJS(
  {
    'email_message': '',
    'message': '',
    'id': '',
    'updated_at': new Date(),
    'inserted_at': new Date(),
    'sent_email_at': new Date(),
    'meta': new Map(),
    'question': new Map(),
    'user': new Map(),
    'authoring_question': new Map(),
    'public_question': new Map(),
    'relationshipMeta': new Map(),
    'search_question': new Map()
  }
);

export class AuthoringFeedbackModelV1 extends GenericModel(ModelRecord, ModelConfig, 'AuthoringFeedbackModelV1') {
  // -- Query Rules --
  /**
   * @returns {boolean}
   */
  static allowsInclude(include) {
    // AllowedIncludes is currently disabled.
    return true; // AllowedIncludes.has(include);
  }

  /**
   * @returns {boolean}
   */
  static allowsFilter(filterField, filterType) {
    return filterType ?
      FilterRules.get(filterField).has(filterType) :
      FilterRules.has(filterField);
  }

  /**
   * @returns {boolean}
   */
  static allowsSort(sort) {
    return sort.split(',').filter((s) => AllowedSorts.has(s)).length > 0;
  }

  /**
   * @returns {Array}
   */
  static getAllowedSorts() {
    return AllowedSorts.keySeq().toJS();
  }

  static getMetaContextQuery(key) {
    if (!MetadataContextRules.hasIn(['optional', key]) && !MetadataContextRules.hasIn(['required', key])) {
      console.error('Rule for meta context key ' + key + ' does not exist');
    }

    return MetadataContextRules.getIn(['optional', key]) || MetadataContextRules.getIn(['required', key]);
  }

  /**
   * @returns {Immutable.Set}
   */
  static getRequiredMetaContextRules() {
    return new Set([...MetadataContextRules.get('required').values()]);
  }

  /**
   * @returns {Immutable.Set}
   */
  static getAllMetaContextRules() {
    return new Set([...MetadataContextRules.get('required').values(), ...MetadataContextRules.get('optional').values()]);
  }

  static getDefaultModel() {
    _emptyAuthoringFeedbackModelV1Model = _emptyAuthoringFeedbackModelV1Model ? _emptyAuthoringFeedbackModelV1Model : new AuthoringFeedbackModelV1(DefaultModel);
    return _emptyAuthoringFeedbackModelV1Model;
  }

  static supportsMetaContext() {
    return MetadataContextRules !== null;
  }

  /**
   * @returns {string}
   */
  static getModelName() {
    return 'AuthoringFeedbackModelV1';
  }

  /**
   * @returns {boolean}
   */
  static _isFullSpec() {
    return true;
  }

  constructor() {
    super(...arguments);
    this.__meta = null;
  }

  /**
   * @returns {string}
   */
  getJsonApiType() {
    return 'authoring_feedback_v1';
  }

  /**
   * @params {string} - An attribute name
   * @params {*} [def] - Optional default value override for the attribute
   */
  getValueForAttribute(attribute, def) {
    let value = this.get(attribute);
    validate(value, attribute);
    if (value === Empty) {
      value = def !== undefined ? def : DefaultModel.get(attribute);
    }
    return value;
  }

  // -- Primary Attributes --
  
  /**
   * @type {string}
   */
  getEmailMessage() {
    const value = this.getValueForAttribute('email_message');
    return value;
  }

  setEmailMessage(value) {
    return this.setField('email_message', value);
  }

  /**
   * @type {binary_id}
   */
  getId() {
    const value = this.getValueForAttribute('id');
    return value;
  }

  setId(value) {
    return this.setField('id', value);
  }

  /**
   * @type {datetime}
   */
  getInsertedAt() {
    const value = this.getValueForAttribute('inserted_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  /**
   * @type {string}
   */
  getMessage() {
    const value = this.getValueForAttribute('message');
    return value;
  }

  setMessage(value) {
    return this.setField('message', value);
  }

  /**
   * @type {datetime}
   */
  getSentEmailAt() {
    const value = this.getValueForAttribute('sent_email_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  /**
   * @type {datetime}
   */
  getUpdatedAt() {
    const value = this.getValueForAttribute('updated_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  // -- Meta Attributes --
  
    /**
     * LEGACY META ACCESSORS
     * @todo These should no longer be used, instead use this.getMeta()[...]
     */
    
      getPopulatedMetadataFields() {
        return this.getMeta().getPopulatedMetadataFields();
      }
    
      getUserEmail() {
        return this.getMeta().getUserEmail();
      }
    
      getUserFirstName() {
        return this.getMeta().getUserFirstName();
      }
    
      getUserLastName() {
        return this.getMeta().getUserLastName();
      }
    
    getMeta() {
      if (this.__meta) {
        return this.__meta;
      }
      const meta = this.get('meta', Map());
      this.__meta = {
        
          /**
           * @type {string[]}
           */
          getPopulatedMetadataFields() {
            const value = meta.get('populated_metadata_fields');
            validate(value, 'populated_metadata_fields');
            return value;
          },
  
          /**
           * @type {string}
           */
          getUserEmail() {
            const value = meta.get('user_email');
            validate(value, 'user_email');
            return value;
          },
  
          /**
           * @type {string}
           */
          getUserFirstName() {
            const value = meta.get('user_first_name');
            validate(value, 'user_first_name');
            return value;
          },
  
          /**
           * @type {string}
           */
          getUserLastName() {
            const value = meta.get('user_last_name');
            validate(value, 'user_last_name');
            return value;
          },
  
      };
      return this.__meta;
    }
  
  // -- Relationships --
  
  /**
   * @type {authoring_question_v1 (one)}
   */
  getAuthoringQuestion() {
    const value = this.getValueForAttribute('authoring_question');
    return value;
  }

  // -- Relationship Fields: authoring_question --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {public_question_v1 (one)}
   */
  getPublicQuestion() {
    const value = this.getValueForAttribute('public_question');
    return value;
  }

  // -- Relationship Fields: public_question --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {question_v3 (one)}
   */
  getQuestion() {
    const value = this.getValueForAttribute('question');
    return value;
  }

  // -- Relationship Fields: question --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {search_question_v2 (one)}
   */
  getSearchQuestion() {
    const value = this.getValueForAttribute('search_question');
    return value;
  }

  // -- Relationship Fields: search_question --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {user_v2 (one)}
   */
  getUser() {
    const value = this.getValueForAttribute('user');
    return value;
  }

  // -- Relationship Fields: user --
  // no 'fields' found, skipped generating accessor(s)
  
}

// -- Empty Model Instance --
let _emptyAuthoringFeedbackModelV1Model = null;

// -- Sort Rules --

const AllowedSorts = fromJS({
  'email_message': null,
  'id': null,
  'inserted_at': null,
  'message': null,
  'meta.user_email': null,
  'meta.user_first_name': null,
  'meta.user_last_name': null,
  'sent_email_at': null,
  'updated_at': null,
});

// -- Include Rules --
// List []

// -- Filter Rules --
const FilterRules = new Map({
  'authoring_question_v1': new Set(['associated']),
  'email_message': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'id': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'inserted_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'message': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'meta.populated_metadata_fields': new Set(['null']),
  'meta.user_email': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'meta.user_first_name': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'meta.user_last_name': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'public_question_v1': new Set(['associated']),
  'question_v1': new Set(['associated']),
  'question_v2': new Set(['associated']),
  'question_v3': new Set(['associated']),
  'search_question_v1': new Set(['associated']),
  'search_question_v2': new Set(['associated']),
  'sent_email_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'updated_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'user_v1': new Set(['associated']),
  'user_v2': new Set(['associated']),
});
    

// -- Metadata Context Rules --
const MetadataContextRules = fromJS({
  optional: {
  },
  required: {
  }
});

// -- Legacy Name Export --
export const AuthoringFeedback = AuthoringFeedbackModelV1;
