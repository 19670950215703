import {Map} from 'immutable';

import GenericExplanation from '../common/V2/GenericExplanation';

import TwoWayQuestionV2 from './V2/TwoWayQuestion';
import TwoWayEditor from './TwoWayEditor';

export const TwoWayQuestionType = Map({
  type: 'two-way',
  label: 'Two Way',
  questionRenderer: TwoWayQuestionV2,
  explanationRenderer: GenericExplanation,
  responseStatsRenderer: () => null,
  editor: TwoWayEditor,
  isHidden: false
});
