import classnames from 'classnames';
import React, {useCallback} from 'react';
import {callTargetedAction} from 'client/framework';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import CorrectnessIndicatorWrapper from 'client/QuestionTypes/common/CorrectnessIndicatorWrapper/CorrectnessIndicatorWrapper.react';

import multipleChoiceQuestionActions from './MultipleChoiceQuestion.actions';
import {MULTIPLE_CHOICE_LETTERS} from './MultipleChoice.constants';

interface Props {
  index: number;
  option: any;
  isCorrectAnswer: boolean;
  isSelected: boolean;
  targetStore: string;
  isGuessSubmitted: boolean;
  isEliminated: boolean;
  isSelectMultiple: boolean;
}

const MultipleChoiceOption = ({
  index,
  option,
  isCorrectAnswer,
  isSelected,
  targetStore,
  isGuessSubmitted,
  isEliminated,
  isSelectMultiple
}: Props) => {
  const handleClick = useCallback(() => {
    if (isGuessSubmitted || isEliminated) {
      return;
    }
    callTargetedAction({
      name: multipleChoiceQuestionActions.SELECT_OPTION,
      payload: option.getId(),
      targetStore
    });
  }, [isGuessSubmitted, isEliminated, option, targetStore]);

  const {value} = option;
  const wrapperClass = classnames('mcq-option', {
    'mcq-option--selected': isSelected,
    'mcq-option--unselected': !isSelected,
    'mcq-option--pre-submission': !isGuessSubmitted,
    'mcq-option--post-submission': isGuessSubmitted,
    'mcq-option--eliminated': isEliminated,
    'mcq-option--checkbox': isSelectMultiple
  });

  return (
    <CorrectnessIndicatorWrapper
      className={wrapperClass}
      onClick={handleClick}
      isGuessSubmitted={isGuessSubmitted}
      isCorrectAnswer={isCorrectAnswer}
      isSelected={isSelected}
    >
      <div
        className={classnames('mcq-option__letter-wrapper', {
          'mcq-option__letter-wrapper--checkbox': isSelectMultiple
        })}
      >
        <span className='mcq-option__letter'>{MULTIPLE_CHOICE_LETTERS.charAt(index)}</span>
      </div>
      <MarkdownRendererV2 className='mcq-option__content' text={value} />
    </CorrectnessIndicatorWrapper>
  );
};

export default MultipleChoiceOption;
