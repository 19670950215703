import React, {useCallback, useMemo} from 'react';
import classnames from 'classnames';
import {callTargetedAction, setUpStore} from 'client/framework';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import {Fieldset} from '@albert-io/atomic';
import {QuestionModelV3} from '@albert-io/models';
import {useShallow} from 'zustand/react/shallow';
import {useQuestionTranslationStore} from 'components/QuestionTypes/shared/TranslationSection';

import snippetSelectionQuestionActions from './SnippetSelectionQuestion.actions';
import SnippetSelectionQuestionStore from './SnippetSelectionQuestion.store';

import './snippet-selection-question.scss';

interface SnippetSelectionInputAreaProps {
  questionId: string;
  question: QuestionModelV3;
  storeName: string;
  isGuessSubmitted: boolean;
}

/*
old propTypes
  static propTypes = {
    questionId: PropTypes.string,
    question: PropTypes.instanceOf(getModelForResourceType('question_v3')),
    storeName: PropTypes.string.isRequired,
    isGuessSubmitted: PropTypes.bool
  };
*/

const SnippetSelectionInputArea = ({
  questionId,
  question,
  storeName,
  isGuessSubmitted
}: SnippetSelectionInputAreaProps) => {
  // Translation info
  const {translatedQuestionInfo, showAnswerTranslation} = useQuestionTranslationStore(
    useShallow((state) => ({
      translatedQuestionInfo: state.translatedQuestionInfo,
      showAnswerTranslation: state.toggleStatus.answer
    }))
  );

  const {prompt, options} = useMemo(() => {
    if (showAnswerTranslation && translatedQuestionInfo) {
      return {
        prompt: translatedQuestionInfo.snippet_prompt,
        options: translatedQuestionInfo.options
      };
    }
    return {
      prompt: question.getSnippetPrompt(),
      options: question.getOptions()
    };
  }, [translatedQuestionInfo, showAnswerTranslation, question]);

  // end translation info

  const store = useMemo(() => {
    return setUpStore(SnippetSelectionQuestionStore, storeName, questionId);
  }, [storeName, questionId]);

  const onOptionClick = useCallback(
    (optionId: string) => {
      if (isGuessSubmitted) return;
      callTargetedAction({
        name: snippetSelectionQuestionActions.TOGGLE_OPTION,
        targetStore: store?.getName(),
        payload: optionId
      });
    },
    [isGuessSubmitted, store]
  );

  const inputAreaClassNames = useMemo(() => {
    return classnames('snippet-selection-question__input-area', {
      'snippet-selection-question__input-area--interactive': !(
        isGuessSubmitted || store?.hasResponse
      )
    });
  }, [isGuessSubmitted, store]);

  return (
    <Fieldset legend='Select answer(s) below'>
      <div className='snippet-selection-question u-mar-b_2'>
        <MarkdownRendererV2
          className={inputAreaClassNames}
          isGuessSubmitted={isGuessSubmitted}
          text={prompt}
          snippetSelectionData={{
            isExplanation: isGuessSubmitted,
            options,
            storeName: store?.getName(),
            onOptionClick
          }}
          question={question}
        />
      </div>
    </Fieldset>
  );
};

export {SnippetSelectionInputArea};
