import {Map} from 'immutable';

import {FreeResponseEditor} from './FreeResponseEditor';
import {FreeResponseQuestion} from './FreeResponseQuestion';
import FreeResponseExplanation from './FreeResponseExplanation';
import {FreeResponseResponseStats} from './FreeResponseResponseStats';

export const FreeResponseQuestionType = Map({
  type: 'free-response',
  label: 'Free Response',
  questionRenderer: FreeResponseQuestion,
  explanationRenderer: FreeResponseExplanation,
  responseStatsRenderer: FreeResponseResponseStats,
  editor: FreeResponseEditor,
  isHidden: false
});
