import React, {useCallback, useMemo} from 'react';
import {fromJS, List} from 'immutable';

import {setUpStore} from 'client/framework';
import {useQuestionEditorV2Store} from 'client/EditPage/V2/QuestionEditorV2Store';
import {DropdownModel} from 'resources/Question/Question.model';

import FillInTheBlankQuestionStore from './FillInTheBlankQuestion.store';

interface Props {
  question: any;
  storeName: string;
}

const FillInTheBlankResponseStats = ({question, storeName}: Props) => {
  const currentTranslatedQuestion = useQuestionEditorV2Store((state) =>
    state.currentTranslatedQuestion()
  );

  const store = useMemo(() => {
    return setUpStore(FillInTheBlankQuestionStore, storeName, question.getId());
  }, [storeName, question]);

  const getChoice = useCallback(
    (choice, dropdownId) => {
      const choiceId = choice.getId();
      const isChosenOption = store.isChosenOption(choiceId, dropdownId);
      const isCorrectOption = question.getValidResponse().includes(choiceId);
      return (
        <div key={choiceId} className='fitb-question-response-stats__choice'>
          {isCorrectOption ? (
            <span className='fitb-question-response-stats__correct-answer fa fa-check-circle' />
          ) : null}
          {!isCorrectOption && isChosenOption ? (
            <span className='fitb-question-response-stats__incorrect-answer fa fa-times-circle' />
          ) : null}
          <span className='fitb-question-response-stats__choice-percentage'>
            {question.getPercentageSelectedInputValue(dropdownId, choiceId)}%
          </span>
          <span className='fitb-question-response-stats__choice-content'>{choice.getValue()}</span>
        </div>
      );
    },
    [question, store]
  );

  if (question.getTotalAnswerCount() < 50) {
    return null;
  }

  const dropdowns = currentTranslatedQuestion?.dropdowns
    ? List(
        currentTranslatedQuestion.dropdowns.map((dropdown) => new DropdownModel(fromJS(dropdown)))
      )
    : question.getDropdowns();

  const dropdownsJsx = dropdowns.map((dropdown, index) => {
    const dropdownId = dropdown.getId();
    const choices = dropdown.getChoices().map((choice) => getChoice(choice, dropdownId));

    return (
      <div key={dropdownId} className='fitb-question-response-stats'>
        <div className='fitb-question-response-stats__item'>
          <div className='fitb-question-response-stats__header'>Dropdown {index + 1}</div>
          {choices}
        </div>
      </div>
    );
  });

  return (
    <div className='question-response-stats'>
      <div className='question-response-stats__header'>How Others Answered</div>
      {dropdownsJsx}
    </div>
  );
};

export default FillInTheBlankResponseStats;
