import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {defer, isUndefined} from 'lodash';
import {callTargetedAction} from 'client/framework';
import interactionEngineStore from 'client/InteractionEngineV2/InteractionEngineStore';
import studentAssignmentQuestionsListStore from 'client/InteractionEngineV2/IESessionTypes/Assignments/StudentAssignmentIE/StudentAssignmentQuestionsList/StudentAssignmentQuestionsList.store';
import {QuestionModelV3} from 'resources/augmented/Question/QuestionModel.v3';

import textHighlightQuestionActions from '../TextHighlightQuestion.actions';

export default class TextHighlightPrompt extends React.Component {
  static propTypes = {
    explanation: PropTypes.bool,
    isGuessCorrect: PropTypes.bool,
    isGuessSubmitted: PropTypes.bool,
    question: PropTypes.instanceOf(QuestionModelV3),
    storeName: PropTypes.string,
    className: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.highlightAreaWrapperNode = null;
    this.mouseMoveTicking = false;
  }

  componentWillUnmount() {
    global.document.removeEventListener('mouseup', this.handleEndSelection);
    global.document.removeEventListener('touchend', this.handleTouchEnd);
  }

  getClassNames(index) {
    const baseClass = 'text-highlight-area__char';
    const highlightClass = 'text-highlight-area__char--highlight';
    return this.props.explanation
      ? classnames(baseClass, {
          [highlightClass]: this.props.question.getStore().isIndexInPreferredPermutation(index)
        })
      : classnames(baseClass, {
          [highlightClass]: this.props.question.getStore().isIndexSelected(index),
          'text-highlight-area__char--current': this.props.question
            .getStore()
            .isIndexInCurrentSelection(index),
          'text-highlight-area__char--limit':
            index === this.props.question.getStore().startIndex ||
            index === this.props.question.getStore().potentialIndex
        });
  }

  getTargetElementFromTouchEventData(eventProperty) {
    const {pageX, pageY} = eventProperty[eventProperty.length - 1];
    const {scrollX, scrollY} = global.window;
    const xPos = pageX - scrollX;
    const yPos = pageY - scrollY;
    return global.document.elementFromPoint(xPos, yPos);
  }

  handleMouseDown = (e) => {
    global.document.addEventListener('mouseup', this.handleEndSelection);
    this.startSelection(e.target.dataset.index);
  };

  handleTouchStart = (e) => {
    e.preventDefault();
    global.document.addEventListener('touchend', this.handleTouchEnd);
    this.startSelection(e.target.dataset.index);
  };

  startSelection(index) {
    if (this.props.question.getStore().isGuessSubmitted) {
      return;
    }
    callTargetedAction({
      name: textHighlightQuestionActions.START_SELECTION,
      payload: index,
      targetStore: this.props.storeName
    });
  }

  handleMouseEnter = (e) => {
    this.pendingSelectionEnd(e.target.dataset.index);
  };

  handleTouchMove = (e) => {
    e.preventDefault();
    if (this.mouseMoveTicking) {
      return;
    }
    this.mouseMoveTicking = true;
    const target = this.getTargetElementFromTouchEventData(e.targetTouches);
    this.pendingSelectionEnd(target.dataset.index);
    defer(() => {
      this.mouseMoveTicking = false;
    });
  };

  pendingSelectionEnd(index) {
    if (this.props.question.getStore().startIndex === null) {
      return;
    }
    callTargetedAction({
      name: textHighlightQuestionActions.POTENTIAL_SELECTION,
      payload: index,
      targetStore: this.props.storeName
    });
  }

  handleEndSelection = (e) => {
    global.document.removeEventListener('mouseup', this.handleEndSelection);
    this.endSelection(e.target.dataset.index, e.target);
  };

  handleTouchEnd = (e) => {
    global.document.removeEventListener('touchend', this.handleTouchEnd);
    const target = this.getTargetElementFromTouchEventData(e.changedTouches);
    const index = target.dataset.index;
    this.endSelection(index, target);
  };

  endSelection(index, targetEl) {
    if (isUndefined(index) || !this.highlightAreaWrapperNode.contains(targetEl)) {
      callTargetedAction({
        name: textHighlightQuestionActions.CLEAR_PENDING_SELECTION,
        targetStore: this.props.storeName
      });
      return;
    }
    callTargetedAction({
      name: textHighlightQuestionActions.END_SELECTION,
      payload: index,
      targetStore: this.props.storeName
    });
  }

  render() {
    const {isGuessSubmitted, question, explanation, isGuessCorrect, className} = this.props;
    const formattedPrompt = question
      .getStore()
      .highlightPrompt.split('')
      .map((char, i) => {
        return (
          <span
            key={i}
            className={this.getClassNames(i)}
            data-index={`${i}`}
            onMouseDown={isGuessSubmitted ? undefined : this.handleMouseDown}
            onMouseEnter={isGuessSubmitted ? undefined : this.handleMouseEnter}
            onTouchStart={isGuessSubmitted ? undefined : this.handleTouchStart}
            onTouchMove={isGuessSubmitted ? undefined : this.handleTouchMove}
          >
            {char}
          </span>
        );
      });
    const isExplanation = explanation;
    const shouldPreventInteraction = isGuessSubmitted || isExplanation;
    // this.props.isGuessCorrect is false if you haven't answered, which is weird
    // but that's why I'm being so explicit about this
    const isCorrect = isGuessSubmitted && isGuessCorrect;
    const isIncorrect = isGuessSubmitted && !isGuessCorrect;

    let showGuessFeedback = true;
    if (
      interactionEngineStore.isStudentAssignmentSession &&
      !studentAssignmentQuestionsListStore.getAssignment().shouldShowGuessFeedback()
    ) {
      showGuessFeedback = false;
    }

    const classNames = classnames('text-highlight-area', className, {
      'text-highlight-area--prevent-interaction': shouldPreventInteraction,
      'text-highlight-area--correct': isCorrect && showGuessFeedback,
      'text-highlight-area--incorrect': isIncorrect && showGuessFeedback && !isExplanation
    });
    return (
      <div
        className={classNames}
        ref={(node) => {
          this.highlightAreaWrapperNode = node;
        }}
      >
        {formattedPrompt}
      </div>
    );
  }
}
