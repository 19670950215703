import React, {useState} from 'react';
import {setUpStore, callTargetedAction} from 'client/framework';
import TextInput from 'generic/Forms/TextInput/TextInput';
import StaticField from 'generic/Forms/StaticField/StaticField.react';
import Button from 'sg/Button/Button.react';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';

import PassageCorrectionPopover from '../shared/PassageCorrectionPopover.react';

import PassageCorrectionQuestionStore from './PassageCorrectionQuestion.store';
import passageCorrectionQuestionActions from './PassageCorrectionQuestion.actions';
import './passage-correction-question.scss';

interface PassageCorrectionPreSubmissionRendererProps {
  storeName: string;
  questionId: string;
  question: any;
  onRenderWordFunc: any;
}

const PassageCorrectionPreSubmissionRenderer = (
  props: PassageCorrectionPreSubmissionRendererProps
) => {
  const {storeName, questionId, question, onRenderWordFunc} = props;

  const getStore = () => {
    return setUpStore(PassageCorrectionQuestionStore, storeName, questionId);
  };

  const callAction = (name: string, payload?: any) => {
    const actionToCall: any = {
      name,
      targetStore: getStore().getName()
    };
    if (payload !== undefined) {
      actionToCall.payload = payload;
    }
    callTargetedAction(actionToCall);
  };

  const handleClick = (e) => {
    const {regionIndex} = e.target.dataset;
    if (regionIndex === undefined) {
      return;
    }
    callAction(
      passageCorrectionQuestionActions.SET_ACTIVE_OPTION,
      parseInt(e.target.dataset.idx, 10)
    );
  };

  const commitCorrectionChange = (updatedCorrection) => {
    callAction(passageCorrectionQuestionActions.COMMIT_CORRECTION_CHANGE, updatedCorrection);
  };

  const handleCorrectionReset = () => {
    callAction(passageCorrectionQuestionActions.REMOVE_ACTIVE_OPTION_CORRECTION);
  };

  const wordPropsFunc = (text, optionIndex) => {
    const store = getStore();
    const matchedRegionIndex = question.getRegionKeyForOptionIndex(optionIndex);
    const activeOption = store.getActiveOption();
    const isActive = activeOption === optionIndex;
    const correctedText = store.getCorrections().hasIn([matchedRegionIndex, optionIndex])
      ? store.getCorrections().getIn([matchedRegionIndex, optionIndex])
      : text;
    return {
      isActive,
      isRangeEnd: isActive,
      isRangeStart: isActive,
      isCorrected: store.isOptionCorrected(optionIndex),
      matchedRegionIndex,
      isSelectable: question.isOptionIndexInteractive(optionIndex),
      onRenderWordFunc,
      correctedText
    };
  };

  const store = getStore();
  const activeIndex = store.hasActiveOption() ? store.getActiveOption() : null;

  return (
    <MarkdownRendererV2
      passageCorrection
      className='passage-correction-question__input-area'
      text={question.getUncorrectedText()}
      onClick={handleClick}
      wordPropsFunc={wordPropsFunc}
      popover={
        <Popover
          key={activeIndex}
          commitChange={commitCorrectionChange}
          correctedValue={store.getActiveCorrectedValue()}
          onReset={handleCorrectionReset}
          originalText={store.getActiveOriginalValue()}
        />
      }
    />
  );
};

interface PopoverProps {
  commitChange: any;
  correctedValue: string;
  onReset: any;
  originalText: string;
}

const Popover = (props: PopoverProps) => {
  const {commitChange, correctedValue, onReset, originalText} = props;
  const [pendingCorrectionValue, setPendingCorrectionValue] = useState(correctedValue);

  const updateCorrection = (e) => {
    setPendingCorrectionValue(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleCommitChange();
    }
  };

  const handleCommitChange = () => {
    if (pendingCorrectionValue.length === 0) {
      return;
    }
    commitChange(pendingCorrectionValue);
  };

  return (
    <PassageCorrectionPopover {...props}>
      <div className='passage-correction-popover__correction-wrapper'>
        <TextInput
          className='passage-correction-popover__input'
          label='Enter correction'
          value={pendingCorrectionValue}
          onChange={updateCorrection}
          onKeyDown={handleKeyDown}
        />
        <div className='passage-correction-popover__button-wrapper'>
          <Button
            small
            text='Change'
            onClick={handleCommitChange}
            disabled={pendingCorrectionValue.length === 0}
            disabledTooltipContent='The correction may not be empty'
            tooltipPosition='right'
          />
        </div>
      </div>
      <div className='passage-correction-popover__original-text'>
        <StaticField
          label='Original text'
          value={originalText}
          labelRightContent={<Button noPadding linkButton text='Reset' onClick={onReset} />}
        />
      </div>
    </PassageCorrectionPopover>
  );
};

export default PassageCorrectionPreSubmissionRenderer;
