import React, {useMemo} from 'react';
import classnames from 'classnames';
import {setUpStore} from 'client/framework';

import FreeEntryQuestionStore from './FreeEntryQuestion.store';
import FreeEntryQuestionResponseStatsPercentageIndicator from './FreeEntryQuestionResponseStatsPercentageIndicator';
import FreeEntryQuestionResponseStatsTopFiveAttempts from './FreeEntryQuestionResponseStatsTopFiveAttempts';

interface Props {
  previewMode: boolean;
  question: any;
  selectedAnswer: any;
  storeName: string;
}

const FreeEntryQuestionResponseStats = ({
  previewMode,
  question,
  selectedAnswer,
  storeName
}: Props) => {
  const store = useMemo(() => {
    return setUpStore(FreeEntryQuestionStore, storeName, question.getId(), question);
  }, [storeName, question]);

  const getStatsforInput = (inputId, index) => {
    if (question.getTotalAnswerCount() < 50) {
      return null;
    }
    let inputValue: string | null = null;
    let isCorrectAnswer = null;
    let topFiveAttemptsComponent: React.JSX.Element | null = null;
    const topAnswers = question.getTopAnswersForInputSorted(inputId);
    const totalAttempts = question.getTotalAnswerCountForInput(inputId);
    const answerPercentage = question.getPercentageCorrectForInput(inputId);

    if (selectedAnswer && !previewMode) {
      inputValue = selectedAnswer.get(inputId);
      isCorrectAnswer = store.isGuessCorrect();
    }

    const itemClasses = classnames('question-response-stats__item', {
      correct: previewMode || isCorrectAnswer,
      incorrect: !previewMode && !isCorrectAnswer
    });

    if (totalAttempts >= 5) {
      topFiveAttemptsComponent = (
        <FreeEntryQuestionResponseStatsTopFiveAttempts
          key={`stats-top-five-${inputId}`}
          inputId={inputId}
          topAnswers={topAnswers}
          totalAttempts={totalAttempts}
          storeName={storeName}
          usersGuess={inputValue}
        />
      );
    }

    return (
      <div key={index}>
        <FreeEntryQuestionResponseStatsPercentageIndicator
          className={itemClasses}
          index={index}
          answerPercentage={answerPercentage}
        />
        {topFiveAttemptsComponent}
      </div>
    );
  };

  const inputs = question.getInputs();
  const responseStatsBody = inputs.map((input, index) => {
    return getStatsforInput(input.getId(), index);
  });

  return (
    <div className='question-response-stats'>
      <div className='question-response-stats__header'>How Others Answered</div>
      <div className='question-response-stats__body'>{responseStatsBody}</div>
    </div>
  );
};

export default FreeEntryQuestionResponseStats;
