import React from 'react';
import {snakeCase} from 'lodash';
import PropTypes from 'prop-types';
import {Link} from 'react-router';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classnames from 'classnames';
import makeRef from 'lib/makeRef';
import constants from 'client/constants';
import {callTargetedAction, setUpStore, destroyStore} from 'client/framework';
import simpleDropdownActions from './SimpleDropdown.actions';
import SimpleDropdownStore from './SimpleDropdown.store';
import './simple-dropdown.scss';

export default class SimpleDropdown extends React.Component {
  static propTypes = {
    activeItemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    defaultItemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     * By default, `SimpleDropdown` will show the active item using `item.get('name', '')`
     * You can provide either a custom function (which will provide the active item as the first
     * argument) or a `string`. In the case of `string`, it is assumed that it is the name of
     * a method that can be called on the active item directly (ie. `item[string]()`).
     */
    activeItemDisplayGetter: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    className: PropTypes.string,
    destroyStoreOnUnmount: PropTypes.bool,
    fullWidth: PropTypes.bool,
    hasOptionTemplate: PropTypes.bool,
    label: PropTypes.string,
    noInputStyles: PropTypes.bool,
    onChange: PropTypes.func,
    options: ImmutablePropTypes.list,
    // The below is no longer supported as of react-16
    // but is still a very useful description of the structure
    // therefore I leave it here for informative purposes
    // options: ImmutablePropTypes.listOf(
    //   ImmutablePropTypes.contains({
    //     name: PropTypes.string.isRequired,
    //     to: PropTypes.string,
    //     id: PropTypes.oneOfType([
    //       PropTypes.string,
    //       PropTypes.number
    //     ]).isRequired,
    //     displayName: PropTypes.string,
    //     disabled: PropTypes.bool // If disabled, selecting this option will no-op and close the drawer
    //   })
    // ).isRequired,
    optionTemplate: PropTypes.func,
    parentRect: (props, propName, componentName) => {
      if (
        props.preventOptionsOverflow &&
        (!props[propName] || typeof props[propName] !== 'object')
      ) {
        return new Error(
          `Invalid prop ${propName} passed to ${componentName}. ${propName} is expected to be an object returned by element.getBoundingClientRect()`
        );
      }
      return null;
    },
    placeholder: PropTypes.string,
    preventOptionsOverflow: PropTypes.bool,
    storeName: PropTypes.string.isRequired,
    disabled: PropTypes.bool
  };

  static defaultProps = {
    hasOptionTemplate: false,
    optionTemplate: () => {},
    onChange: () => {}
  };

  constructor(props) {
    super(props);
    this.initStore();
    this.dropdownNode = null;
  }

  componentDidMount() {
    const store = this.getStore();

    global.document.addEventListener('click', this.handleClickOutsideComponent);
    if (this.props.preventOptionsOverflow) {
      callTargetedAction({
        name: simpleDropdownActions.SET_OPTIONS_RECT,
        targetStore: store.getName(),
        payload: this.dropdownOptionsWrapper.getBoundingClientRect()
      });
    }
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (this.props.activeItemId !== nextProps.activeItemId) {
      callTargetedAction({
        name: simpleDropdownActions.SET_SELECTED_ITEM,
        targetStore: this.getStore().getName(),
        payload: nextProps.options.find((item) => {
          return item.get('id', '') === nextProps.activeItemId;
        })
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.preventOptionsOverflow !== this.props.preventOptionsOverflow) {
      callTargetedAction({
        name: simpleDropdownActions.SET_OPTIONS_RECT,
        targetStore: this.getStore().getName(),
        payload: this.dropdownOptionsWrapper.getBoundingClientRect()
      });
    }

    if (this.props.options.size !== prevProps.options.size) {
      this.initStore();
    }
  }

  componentWillUnmount() {
    global.document.removeEventListener('click', this.handleClickOutsideComponent);
    const store = this.getStore();
    callTargetedAction({
      name: simpleDropdownActions.SET_OPTIONS_RECT,
      targetStore: store.getName(),
      payload: null
    });
    if (this.props.destroyStoreOnUnmount) {
      destroyStore(this.props.storeName);
    }
  }

  makeRef = makeRef.bind(this);

  getStore() {
    return setUpStore(SimpleDropdownStore, this.props.storeName);
  }

  initStore() {
    const store = this.getStore();
    // @todo: Prevent the rerender after mount that happens due to writing initial store data
    const {options, activeItemId, defaultItemId, placeholder} = this.props;
    if (!options || options.isEmpty()) {
      return;
    }
    const noSelectedItem = store.getSelectedItem().isEmpty();
    let defaultItem;
    if (defaultItemId && noSelectedItem) {
      defaultItem = options.find((item) => item.get('id', '') === defaultItemId);
    } else if (activeItemId && noSelectedItem) {
      defaultItem = options.find((item) => {
        return item.get('id', '') === activeItemId;
      });
    } else if (!placeholder && !activeItemId && noSelectedItem) {
      defaultItem = options.first();
    }
    if (defaultItem) {
      callTargetedAction({
        name: simpleDropdownActions.SET_SELECTED_ITEM,
        targetStore: store.getName(),
        payload: defaultItem
      });
    }
  }

  focusOnInput = () => {
    this.inputNode.focus();
  };

  focusOnDropdownOption = () => {
    const activeOption = this.dropdownOptionsWrapper.querySelector(
      '.simple-dropdown__option--active'
    );

    if (activeOption !== null) {
      activeOption.focus();
    } else {
      this.dropdownOptionsWrapper.firstChild.focus();
    }
  };

  handleInputKeyDown = (e) => {
    const store = this.getStore();
    if (store && !store.isOpen()) {
      if (
        e.which === constants.KEYMAP.DOWN_ARROW ||
        e.which === constants.KEYMAP.ENTER ||
        e.which === constants.KEYMAP.UP_ARROW
      ) {
        e.preventDefault();
        callTargetedAction({
          name: simpleDropdownActions.SET_IS_OPEN,
          targetStore: store.getName(),
          payload: true
        });
      }
      return;
    }

    if (store && store.isOpen()) {
      if (e.which === constants.KEYMAP.ENTER || e.which === constants.KEYMAP.ESCAPE) {
        e.preventDefault();
        this.focusOnInput();
        callTargetedAction({
          name: simpleDropdownActions.SET_IS_OPEN,
          targetStore: store.getName(),
          payload: false
        });
      } else if (e.which === constants.KEYMAP.DOWN_ARROW || e.which === constants.KEYMAP.UP_ARROW) {
        e.preventDefault();
        this.focusOnDropdownOption();
      }
    }
  };

  handleDropdownOptionKeyDown = (e) => {
    const store = this.getStore();
    if (e.which === constants.KEYMAP.ESCAPE) {
      e.preventDefault();
      callTargetedAction({
        name: simpleDropdownActions.SET_IS_OPEN,
        targetStore: store.getName(),
        payload: false
      });
    } else if (e.which === constants.KEYMAP.DOWN_ARROW) {
      e.preventDefault();
      const nextOptionNode = e.target.nextElementSibling;

      if (nextOptionNode === null) {
        e.currentTarget.firstChild.focus();
      } else {
        nextOptionNode.focus();
      }
    } else if (e.which === constants.KEYMAP.UP_ARROW) {
      e.preventDefault();

      const prevOptionNode = e.target.previousElementSibling;

      if (prevOptionNode === null) {
        e.currentTarget.lastChild.focus();
      } else {
        prevOptionNode.focus();
      }
    }
  };

  handleClickOutsideComponent = (e) => {
    const store = this.getStore();
    if (store && store.isOpen() && !this.dropdownNode.contains(e.target)) {
      callTargetedAction({
        name: simpleDropdownActions.SET_IS_OPEN,
        targetStore: store.getName(),
        payload: false
      });
    }
  };

  generateDropdownOptions() {
    const store = this.getStore();
    return this.props.options.map((item, i) => {
      return (
        <DropdownOption
          key={i}
          item={item}
          onChange={this.props.onChange}
          store={store || null}
          isActive={item.get('id', '') === store.getSelectedItem().get('id', '')}
          hasOptionTemplate={this.props.hasOptionTemplate}
          optionTemplate={this.props.optionTemplate}
        />
      );
    });
  }

  generateDropdownOptionsStyles() {
    const {preventOptionsOverflow, parentRect} = this.props;
    const store = this.getStore();
    const childRect = store.getOptionsRect();
    const shouldOffsetChildFromParent = childRect && preventOptionsOverflow && parentRect;
    if (!shouldOffsetChildFromParent) {
      return {};
    }

    if (parentRect.bottom < childRect.bottom) {
      const minOffset = -2;
      const offset = childRect.bottom - parentRect.bottom - minOffset;
      /**
       * We should not offset the options by a value greater than their own total height, or else the div
       * may appear completely detached from the dropdown. But since we can't use the node's height itself,
       * since we set it to 0 when closed to preven overflow issues in the parent, we reduce over the child
       * items and tally up their total height instead.
       */
      const max = Object.values(this.dropdownOptionsWrapper.childNodes).reduce(
        (totalHeight, item) => item.getBoundingClientRect().height + totalHeight,
        0
      );
      const styles = {
        top: `calc(100% - ${Math.min(offset, max)}px)`
      };
      return !store.isOpen()
        ? {
            ...styles,
            height: '0'
          }
        : styles;
    }
    return {};
  }

  toggleIsOpen = () => {
    if (this.props.disabled) {
      return;
    }

    callTargetedAction({
      name: simpleDropdownActions.TOGGLE_IS_OPEN,
      targetStore: this.getStore().getName()
    });
  };

  makeRef = makeRef.bind(this);

  render() {
    const store = this.getStore();
    const {options} = this.props;
    const showSortIcon =
      options.size === 1 ? null : <span className='simple-dropdown__icon fa fa-caret-down' />;

    const hasSelectedItem = !store.getSelectedItem().isEmpty();
    const shouldShowPlaceholder = this.props.placeholder && !hasSelectedItem;

    let activeItemName;
    if (shouldShowPlaceholder) {
      activeItemName = this.props.placeholder;
    } else if (!hasSelectedItem) {
      logger.debug('Simple Dropdown: there is no selected item, and no available placeholder.');
      activeItemName = '';
    } else if (this.props.activeItemDisplayGetter) {
      activeItemName =
        typeof this.props.activeItemDisplayGetter === 'function'
          ? this.props.activeItemDisplayGetter(store.getSelectedItem())
          : store.getSelectedItem()[this.props.activeItemDisplayGetter]();
    } else {
      activeItemName = store.getSelectedItem().get('name', '');
    }

    const simpleDropdownClass = classnames('simple-dropdown', this.props.className, {
      'simple-dropdown--disabled': this.props.disabled
    });

    const inputWrapperClass = classnames('simple-dropdown__input-wrapper', {
      'simple-dropdown__input-wrapper--opened': store.isOpen(),
      'simple-dropdown__input-wrapper--full-width': this.props.fullWidth
    });

    const inputClass = classnames('simple-dropdown__input', {
      'simple-dropdown__input--bare': this.props.noInputStyles
    });

    const activeItemClass = classnames('simple-dropdown__input-active-item', {
      'simple-dropdown__input-active-item--placeholder': shouldShowPlaceholder
    });

    const label = this.props.label ? (
      <div className='simple-dropdown__label'>{this.props.label}</div>
    ) : null;

    const dropdownOptionsStyles = this.generateDropdownOptionsStyles();

    return (
      <div
        className={simpleDropdownClass}
        onClick={this.toggleIsOpen}
        ref={this.makeRef}
        data-ref-name='dropdownNode'
      >
        {label}
        <div className={inputWrapperClass}>
          <div
            ref={(ref) => {
              this.inputNode = ref;
            }}
            className={inputClass}
            onKeyDown={this.handleInputKeyDown}
            tabIndex='0'
            data-testid={`simple-dropdown-input-${snakeCase(this.props.label)}`}
          >
            <span className={activeItemClass}>{activeItemName}&nbsp;</span>
            {showSortIcon}
          </div>
          <div
            className='simple-dropdown__options'
            data-ref-name='dropdownOptionsWrapper'
            onKeyDown={this.handleDropdownOptionKeyDown}
            ref={this.makeRef}
            style={dropdownOptionsStyles}
          >
            {this.generateDropdownOptions()}
          </div>
        </div>
      </div>
    );
  }
}

class DropdownOption extends React.Component {
  static propTypes = {
    item: ImmutablePropTypes.iterable,
    onChange: PropTypes.func,
    store: PropTypes.instanceOf(SimpleDropdownStore),
    isActive: PropTypes.bool,
    hasOptionTemplate: PropTypes.bool,
    optionTemplate: PropTypes.func
  };

  handleClick = () => {
    const {item} = this.props;
    if (item.get('disabled')) {
      return;
    }
    callTargetedAction({
      name: simpleDropdownActions.SET_SELECTED_ITEM,
      targetStore: this.props.store.getName(),
      payload: item
    });
    this.props.onChange(item);
  };

  handleOptionKeyDown = (e) => {
    if (e.which === constants.KEYMAP.ENTER) {
      callTargetedAction({
        name: simpleDropdownActions.SET_IS_OPEN,
        targetStore: this.props.store.getName(),
        payload: false
      });
      this.handleClick();
    }
  };

  render() {
    const itemClassName = classnames({
      'simple-dropdown__option': true,
      'simple-dropdown__option--active': this.props.isActive
    });
    const {item} = this.props;
    const optionName = item.has('displayName') ? item.get('displayName') : item.get('name');

    const OptionTemplate = this.props.optionTemplate;
    const optionContent = !this.props.hasOptionTemplate ? (
      optionName
    ) : (
      <OptionTemplate item={item} />
    );

    return item.has('to') ? (
      <Link
        className={itemClassName}
        to={item.get('to', '#')}
        onClick={this.handleClick}
        onKeyDown={this.handleOptionKeyDown}
        tabIndex='0'
      >
        {optionContent}
      </Link>
    ) : (
      <div
        className={itemClassName}
        onClick={this.handleClick}
        onKeyDown={this.handleOptionKeyDown}
        tabIndex='0'
      >
        {optionContent}
      </div>
    );
  }
}
