import React, {useMemo} from 'react';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import {setUpStore} from 'client/framework';
import {Fieldset, Text} from '@albert-io/atomic';
import {QuestionModelV3} from '@albert-io/models';
import classnames from 'classnames';
import {useShallow} from 'zustand/react/shallow';
import {List} from 'immutable';

import {useQuestionTranslationStore} from 'components/QuestionTypes/shared/TranslationSection';

import FillInTheBlankQuestionStore from './FillInTheBlankQuestion.store';

import './fill-in-the-blank.scss';

interface FillInTheBlankInputAreaProps {
  storeName?: string;
  question?: QuestionModelV3;
  questionId?: string;
  isGuessSubmitted?: boolean;
}

/*
old propTypes info
  static propTypes = {
    storeName: PropTypes.string,
    question: ImmutablePropTypes.record,
    questionId: PropTypes.bool,
    isGuessSubmitted: PropTypes.bool
  };

  static defaultProps = {
    storeName: 'fillInTheBlankQuestionStore'
  };
*/

const FillInTheBlankInputArea = ({
  question,
  questionId,
  isGuessSubmitted,
  storeName = 'fillInTheBlankQuestionStore'
}: FillInTheBlankInputAreaProps) => {
  const store = useMemo(() => {
    return setUpStore(FillInTheBlankQuestionStore, storeName, questionId);
  }, [storeName, questionId]);

  // Translation info
  const {translatedQuestionInfo, showAnswerTranslation} = useQuestionTranslationStore(
    useShallow((state) => ({
      translatedQuestionInfo: state.translatedQuestionInfo,
      showAnswerTranslation: state.toggleStatus.answer
    }))
  );

  const [dropdowns, dropdownText] = useMemo(() => {
    if (showAnswerTranslation && translatedQuestionInfo) {
      return [
        formatDropdowns(translatedQuestionInfo.dropdowns),
        translatedQuestionInfo.dropdown_text
      ];
    }
    return [question.getDropdowns(), question.getDropdownText()];
  }, [question, translatedQuestionInfo, showAnswerTranslation]);

  return (
    <Fieldset>
      <Text as='legend' size='m' className='u-mar-b_1' bold>
        Select options below
      </Text>
      <MarkdownRendererV2
        className={classnames('fitb-input-area', {
          'fitb-input-area-dropdown': !dropdowns.isEmpty()
        })}
        text={dropdownText}
        store={store}
        dropdowns={dropdowns}
        isExplanation={false}
        isGuessSubmitted={isGuessSubmitted}
        question={question}
      />
    </Fieldset>
  );
};

export default FillInTheBlankInputArea;

// MarkdownRendererV2 compatible dropdown
export const formatDropdowns = (dropdowns: any | null) => {
  const formattedDropdowns = !dropdowns
    ? []
    : dropdowns.map((info) => {
        // mardown is expecting the following methods
        // getId
        // getChoices
        const choices = info.choices.map((choice) => {
          return {...choice, getValue: () => choice.value, getId: () => choice.id};
        });
        return {
          ...info,
          choices,
          getId: () => info.id,
          getChoices: () => List(choices),
          getPosition: () => info.position
        };
      });

  return List(formattedDropdowns);
};
