import React, {useCallback, useEffect} from 'react';
import {resource} from '@albert-io/json-api-framework/request/builder';
import {AuthoringSubjectModelV1} from 'resources/augmented/AuthoringSubject/AuthoringSubjectModel.v1';
import {callAction, callTargetedAction, setUpStore} from 'client/framework';
import getDifficultyValueFromInt from 'lib/getDifficultyValueFromInt';
import {getQuestionTypeDefinition} from 'client/QuestionTypes/QuestionTypeDefinitions';

import awaitMandarkQueries from 'lib/hocs/awaitMandarkQueries';
import ConfirmLeaveModal from 'generic/ConfirmLeaveModal/ConfirmLeaveModal.react';
import QuestionDifficultyLabel from 'generic/QuestionDifficultyLabel/QuestionDifficultyLabel.react';
import supplementActions from 'client/Supplement/SupplementActions';
import textHighlightQuestionActions from 'client/QuestionTypes/TextHighlight/V2/TextHighlightQuestion.actions';
import TextHighlightQuestionStore from 'client/QuestionTypes/TextHighlight/V2/TextHighlightQuestion.store';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import {useShallow} from 'zustand/react/shallow';

import questionEditorStore from '../QuestionEditor/QuestionEditor.store';
import {useQuestionEditorV2Store} from '../QuestionEditorV2Store';
import {PRIMARY_CONTENT_LANGUAGE} from '../QuestionEditorV2Store.types';
import './question-preview.scss';

function getAuthoringSubjectQuery() {
  return resource('authoring_subject_v1').mandarkEndpoint([
    'authoring_subjects_v1',
    questionEditorStore.getQuestionSet().getSubjectId()
  ]);
}

interface Props {
  questionId: string;
  questionSetId: string;
  subject: AuthoringSubjectModelV1;
}

const QuestionPreviewComponent = ({questionId, questionSetId, subject}: Props) => {
  const {isPrimaryLanguage, currentTranslatedQuestion} = useQuestionEditorV2Store(
    useShallow((state) => ({
      isPrimaryLanguage: state.currentLanguage === PRIMARY_CONTENT_LANGUAGE,
      currentTranslatedQuestion: state.currentTranslatedQuestion()
    }))
  );

  const getStoreName = useCallback(() => {
    return `QuestionPreview--${questionId}`;
  }, [questionId]);

  const setUpTextHighlightQuestion = useCallback(() => {
    const storeName = getStoreName();
    setUpStore(TextHighlightQuestionStore, storeName, questionId);

    const question = questionEditorStore.getQuestion();
    callTargetedAction({
      name: textHighlightQuestionActions.SET_UP_QUESTION,
      payload: {
        highlightPrompt: question.getHighlightPrompt(),
        preferredPermutation: question.getPreferredPermutation(),
        rubric: question.getRubric()
      },
      targetStore: storeName
    });
  }, [questionId, getStoreName]);

  const setUpSupplements = useCallback(() => {
    const supplements = questionEditorStore.getQuestion().getAuthoringSupplements();
    callAction(supplementActions.QUESTIONSET_SUPPLEMENTS_RECEIVED, supplements);
  }, []);

  const shouldPromptLeave = useCallback(() => {
    const currentQuestion = questionEditorStore.getQuestion();
    if (!currentQuestion) {
      return false;
    }
    return !currentQuestion.getChangeMap().isEmpty();
  }, []);

  useEffect(() => {
    if (questionEditorStore.getQuestion().getQuestionType() === 'text-highlight') {
      setUpTextHighlightQuestion();
    }
  }, [setUpTextHighlightQuestion]);

  useEffect(() => {
    setUpSupplements();
  }, [setUpSupplements]);

  if (!questionEditorStore.isReady()) {
    return null;
  }

  if (!isPrimaryLanguage && !currentTranslatedQuestion) {
    return null;
  }

  const question = questionEditorStore.getQuestion();
  const questionSet = questionEditorStore.getQuestionSet();
  const currentSubject = !questionSet.getAuthoringSubject().isEmpty()
    ? questionSet.getAuthoringSubject()
    : subject;
  const type = getQuestionTypeDefinition(question.getType());
  const QuestionRenderer = type.get('questionRenderer');
  const ExplanationRenderer = type.get('explanationRenderer');
  const ResponseStatsRenderer = type.get('responseStatsRenderer');
  const correctAnswer = question.getValidResponse();
  const statsContent = (
    <ResponseStatsRenderer
      question={question}
      correctAnswer={correctAnswer}
      previewMode
      selectedAnswer={correctAnswer}
      storeName={`QuestionPreviewResponseStatsStore${question.getId()}`}
    />
  );
  const subjectSlug = questionSet.getSlugId().split('@')[0];
  const storeName = getStoreName();

  if (question.getId() !== questionId) {
    return null;
  }

  return (
    <div className='question-preview card'>
      <div className='question-preview__header'>
        <h1>
          <MarkdownRendererV2
            text={isPrimaryLanguage ? question.getTitle() : currentTranslatedQuestion!.title}
          />
        </h1>
        <QuestionDifficultyLabel
          difficulty={getDifficultyValueFromInt(question.getDifficulty(), true)}
        />
      </div>
      <QuestionRenderer
        isGuessSubmitted={false}
        question={question}
        questionId={questionId}
        storeName={storeName}
      />
      <ExplanationRenderer
        question={question}
        questionId={questionId}
        questionSetId={questionSetId}
        isCorrect
        responseStats={statsContent}
        showLabelTable={currentSubject.isShouldShowLabelTable()}
        solutionText={currentTranslatedQuestion?.solution_text || question.getSolutionText()}
        sample={currentTranslatedQuestion?.sample || question.getSample()}
        subjectId={questionSet.getSubjectId()}
        subjectSlug={subjectSlug}
        videoExplanationId={question.getVideoExplanationId()}
        questionStoreName={storeName}
        isInUnpublishedSubject={!currentSubject.isPublished()}
      />
      <ConfirmLeaveModal
        shouldPromptFunc={shouldPromptLeave}
        storeName='questionPreviewIERouteChangeHandler'
        modalTitle='You have unsaved changes'
        modalPrompt={
          <p>
            There are unsaved changes on this question.
            <br />
            Are you sure you want to leave?
          </p>
        }
      />
    </div>
  );
};

export default awaitMandarkQueries(() => {
  const queries: any = {};
  if (!questionEditorStore.getQuestionSet().existsOnServer()) {
    queries.subject = getAuthoringSubjectQuery();
  }
  return {queries};
}, QuestionPreviewComponent);
