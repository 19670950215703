import {useContext, useEffect} from 'react';
import {debounce} from 'lodash';

import {useContentDiscoveryStore} from '../ContentDiscoveryStore.react';
import {ContentDiscoveryContext} from '../../ContentDiscoveryContext';

export const useTopicsModalSubjectsSubscription = () => {
  const store = useContentDiscoveryStore;

  if (!store) {
    throw new Error('Missing ContentDiscoveryStoreProvider in component tree');
  }

  const {standardCollection, getSelectedStandardsAndSets} = store.getState().standards;

  const {questionDifficulties, questionTypes, hideAssessmentQuestions, hidePreviouslyAssigned} =
    useContext(ContentDiscoveryContext);

  const {selectedStandardIds, selectedStandardSetIds} =
    getSelectedStandardsAndSets(standardCollection);

  const selectedContentDiscoveryFilters = {
    standardIds: selectedStandardIds,
    standardSetIds: selectedStandardSetIds,
    questionDifficulties,
    questionTypes,
    hideAssessmentQuestions,
    hidePreviouslyAssigned
  };

  useEffect(() => {
    const debouncedGetFilteredSubjects = debounce((contentDiscoveryFilters) => {
      store.getState().topicsModal.getFilteredSubjects(contentDiscoveryFilters, Date.now());
    }, 300);

    const unsubscribeSubjects = store.subscribe(
      (state) => ({
        areSubjectsPersonalized: state.topicsModal.areSubjectsPersonalized,
        subjectSearchString: state.topicsModal.subjectSearchString,
        subjectGuideSearchString: state.topicsModal.subjectGuideSearchString
      }),
      ({areSubjectsPersonalized, subjectSearchString, subjectGuideSearchString}, prevState) => {
        if (
          areSubjectsPersonalized !== prevState.areSubjectsPersonalized ||
          subjectSearchString !== prevState.subjectSearchString ||
          subjectGuideSearchString !== prevState.subjectGuideSearchString
        ) {
          debouncedGetFilteredSubjects.cancel();
          debouncedGetFilteredSubjects(selectedContentDiscoveryFilters);
        }
      }
    );

    const debouncedGetSubjectGuides = debounce((contentDiscoveryFilters) => {
      store.getState().topicsModal.getSubjectGuides(contentDiscoveryFilters, Date.now());
    }, 300);

    const unsubscribeGuides = store.subscribe(
      (state) => ({
        selectedSubject: state.topicsModal.selectedSubject,
        subjectGuideSearchString: state.topicsModal.subjectGuideSearchString
      }),
      ({selectedSubject, subjectGuideSearchString}, prevState) => {
        if (
          selectedSubject !== prevState.selectedSubject ||
          subjectGuideSearchString !== prevState.subjectGuideSearchString
        ) {
          debouncedGetSubjectGuides.cancel();
          debouncedGetSubjectGuides(selectedContentDiscoveryFilters);
        }
      }
    );

    const unsubscribeSelectedSubjectQuestionCounts = store.subscribe(
      (state) => ({selectedGuideLevels: state.topicsModal.selectedGuideLevels}),
      ({selectedGuideLevels}, prevState) => {
        if (!selectedGuideLevels.equals(prevState.selectedGuideLevels)) {
          store.getState().topicsModal.updateSelectedSubjects();
        }
      }
    );

    const unsubscribeUpdateSelectedSubjectGuides = store.subscribe(
      (state) => ({flattenedSubjectGuideLevels: state.topicsModal.flattenedSubjectGuideLevels}),
      ({flattenedSubjectGuideLevels}, prevState) => {
        if (!flattenedSubjectGuideLevels.equals(prevState.flattenedSubjectGuideLevels)) {
          store.getState().topicsModal.updateSelectedSubjectGuideLevels();
        }
      }
    );

    /* init ops */
    debouncedGetFilteredSubjects.cancel();
    debouncedGetFilteredSubjects(selectedContentDiscoveryFilters);

    debouncedGetSubjectGuides.cancel();
    if (store.getState().topicsModal.selectedSubject) {
      store.getState().topicsModal.getSubjectGuides(selectedContentDiscoveryFilters, Date.now());
    }

    if (
      !store
        .getState()
        .topicsModal.appliedGuideLevels.toSet()
        .equals(store.getState().topicsModal.selectedGuideLevels)
    ) {
      store.getState().topicsModal.resetSelectedGuideLevels();
    }

    if (store.getState().topicsModal.selectedGuideLevels.size > 0) {
      const selectedSubjectIds = store
        .getState()
        .topicsModal.selectedSubjects.reduce((acc, countObj) => {
          acc.push(countObj.id);
          return acc;
        }, []);
      store
        .getState()
        .topicsModal.getAndReplaceSelectedGuideLevels(
          selectedSubjectIds.join(','),
          selectedContentDiscoveryFilters
        );
    }

    store.getState().topicsModal.updateSelectedSubjects();
    /* end init ops */

    return () => {
      unsubscribeSubjects();
      unsubscribeGuides();
      unsubscribeSelectedSubjectQuestionCounts();
      unsubscribeUpdateSelectedSubjectGuides();
      debouncedGetFilteredSubjects.cancel();
      debouncedGetSubjectGuides.cancel();
    };
  }, [store]);
};
