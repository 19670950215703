import React, {useCallback, useMemo} from 'react';
import {QuestionModelV3} from '@albert-io/models';
import {Fieldset} from '@albert-io/atomic';
import classnames from 'classnames';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import Row from './TwoWayQuestionTableRow.react';
import {useShallow} from 'zustand/react/shallow';

import {useQuestionTranslationStore} from 'components/QuestionTypes/shared/TranslationSection';

type TwoWayQuestionStore = any;

interface TwoWayQuestionTableProps {
  isGuessSubmitted: boolean;
  showResponseStats: boolean;
  store: TwoWayQuestionStore;
  question: QuestionModelV3;
}

/*
 old class component propTypes
  static propTypes = {
    isGuessSubmitted: PropTypes.bool,
    showResponseStats: PropTypes.bool,
    store: PropTypes.instanceOf(TwoWayQuestionStore),
    question: PropTypes.instanceOf(getModelForResourceType('question_v3'))
  };

  static defaultProps = {
    isGuessSubmitted: false,
    showResponseStats: false
  };
*/

const TwoWayQuestionTable = ({
  question,
  store,
  isGuessSubmitted = false,
  showResponseStats = false
}: TwoWayQuestionTableProps) => {
  // Translation info
  const {translatedQuestionInfo, showAnswerTranslation} = useQuestionTranslationStore(
    useShallow((state) => ({
      translatedQuestionInfo: state.translatedQuestionInfo,
      showAnswerTranslation: state.toggleStatus.answer
    }))
  );

  const containsLatex = useCallback((question: QuestionModelV3) => {
    const func = () => {
      const regex = /\$.+\$/;
      if (regex.test(question.get('header_left')) || regex.test(question.get('header_right'))) {
        return true;
      }
      return question
        .getRows()
        .some(
          (row) =>
            regex.test(row.getLeftColumn().getText()) ||
            regex.test(row.getRightColumn().getText()) ||
            regex.test(row.getStatement())
        );
    };
    return question.cache('containsLatex', func);
  }, []);

  const hasHeader = useMemo(() => {
    const headerLeft = question.get('header_left');
    const headerRight = question.get('header_right');
    const hasHeaderBool = !!(headerLeft || headerRight);
    return hasHeaderBool;
  }, []);

  const headerText = useMemo(() => {
    if (showAnswerTranslation && translatedQuestionInfo) {
      return {
        left: translatedQuestionInfo.header_left,
        right: translatedQuestionInfo.header_right
      };
    }
    return {
      left: question.get('header_left'),
      right: question.get('header_right')
    };
  }, [question, translatedQuestionInfo, showAnswerTranslation]);

  return (
    <Fieldset>
      <table
        className={classnames('twq-table', {
          'twq-table--full-width': containsLatex(question)
        })}
      >
        {hasHeader && (
          <thead>
            <tr>
              <th className='twq-table__heading' />
              <th>
                <MarkdownRendererV2 text={headerText.left} className='twq-table__heading' />
              </th>
              <th>
                <MarkdownRendererV2 text={headerText.right} className='twq-table__heading' />
              </th>
            </tr>
          </thead>
        )}
        <tbody>
          {question.getRows().map((row, i) => (
            <Row
              key={i}
              row={row}
              store={store}
              question={question}
              isGuessSubmitted={isGuessSubmitted}
              showResponseStats={showResponseStats}
            />
          ))}
        </tbody>
      </table>
    </Fieldset>
  );
};

export default TwoWayQuestionTable;
