import React, {useMemo} from 'react';
import {setUpStore} from 'client/framework';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';

import {TwoWayQuestionModel} from 'resources/Question/Question.model';

import {AuthoringQuestionModelV1} from 'resources/augmented/AuthoringQuestion/AuthoringQuestionModel.v1';

import {useQuestionEditorV2Store} from 'client/EditPage/V2/QuestionEditorV2Store';

import TwoWayQuestionTable from './TwoWayQuestionTable';
import TwoWayQuestionStore from './TwoWayQuestion.store';

interface Props {
  isGuessSubmitted: boolean;
  storeName: string;
  question: TwoWayQuestionModel | AuthoringQuestionModelV1;
  questionId: string;
}

const TwoWayQuestion = ({isGuessSubmitted, storeName, question, questionId}: Props) => {
  const currentTranslatedQuestion = useQuestionEditorV2Store((state) =>
    state.currentTranslatedQuestion()
  );

  const store = useMemo(() => {
    return setUpStore(TwoWayQuestionStore, storeName, questionId);
  }, [storeName, questionId]);

  const prompt = question.getPrompt();

  return (
    <div className='two-way-question'>
      <div className='two-way-question-content-prompt'>
        <MarkdownRendererV2 text={currentTranslatedQuestion?.prompt || prompt} />
      </div>
      <TwoWayQuestionTable
        currentTranslatedQuestion={currentTranslatedQuestion}
        question={question}
        store={store}
        isGuessSubmitted={isGuessSubmitted}
      />
    </div>
  );
};

export default TwoWayQuestion;
