import React from 'react';
import {useQuestionEditorV2Store} from 'client/EditPage/V2/QuestionEditorV2Store';
import {PRIMARY_CONTENT_LANGUAGE} from 'client/EditPage/V2/QuestionEditorV2Store.types';

import GenericExplanation from '../../common/V2/GenericExplanation';

import TextHighlightPrompt from './TextHighlightPrompt.react';

interface Props {
  questionStoreName: string;
}

const TextHighlightExplanation = (props: Props) => {
  const {questionStoreName} = props;

  const isPrimaryLanguage = useQuestionEditorV2Store(
    (state) => state.currentLanguage === PRIMARY_CONTENT_LANGUAGE
  );

  return (
    <GenericExplanation {...(props as any)}>
      <div className='text-highlight-explanation'>
        <h2>Correct Selections</h2>
        {isPrimaryLanguage && (
          <TextHighlightPrompt explanation storeName={questionStoreName} isGuessSubmitted />
        )}
      </div>
    </GenericExplanation>
  );
};

export default TextHighlightExplanation;
