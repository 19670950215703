/* eslint-disable */


// Programatically generated on Wed Apr 02 2025 19:07:43 GMT+0000
import {fromJS, List, Map, Record, Set} from 'immutable';
import {GenericModel} from 'resources/Generic.model';
import {validate, Empty} from '@albert-io/json-api-framework/models/helpers';
import moment from 'moment';

const ModelConfig = fromJS(
  {
    'endpoint': 'classrooms_v1',
    'attributes': {
      'begin_date': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'name': {
        'type': 'string',
        'isRequired': true,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'code': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'last_synced_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'skip_assignment_creation': {
        'type': 'boolean',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'end_date': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'status': {
        'type': 'string',
        'isRequired': true,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'clever_id': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'updated_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'google_course_id': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'inserted_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'id': {
        'type': 'binary_id',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'auto_archive_date': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'has_lti_course': {
        'type': 'boolean',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      }
    },
    'relationships': {
      'students_v1': {
        'type': 'one-to-many'
      },
      'grade_levels_v1': {
        'type': 'one-to-many'
      },
      'students_v2': {
        'type': 'one-to-many'
      },
      'exams_v1': {
        'type': 'one-to-many'
      },
      'school_v1': {
        'type': 'one-to-one'
      },
      'school_v2': {
        'type': 'one-to-one'
      },
      'school_v3': {
        'type': 'one-to-one'
      },
      'teachers_v1': {
        'type': 'one-to-many'
      },
      'school_v4': {
        'type': 'one-to-one'
      },
      'school_v5': {
        'type': 'one-to-one'
      },
      'authoring_subjects_v1': {
        'type': 'one-to-many'
      },
      'classroom_invitations_v1': {
        'type': 'one-to-many'
      },
      'authoring_exams_v1': {
        'type': 'one-to-many'
      },
      'draft_assignments_v1': {
        'type': 'one-to-many'
      },
      'subjects_v1': {
        'type': 'one-to-many'
      },
      'subjects_v2': {
        'type': 'one-to-many'
      },
      'assignments_v1': {
        'type': 'one-to-many'
      },
      'assignments_v2': {
        'type': 'one-to-many'
      },
      'assignments_v3': {
        'type': 'one-to-many'
      },
      'student_assignments_v1': {
        'type': 'one-to-many'
      }
    }
  }
);

const ModelRecord = Record(
  {
    'student_assignments': Empty,
    'begin_date': Empty,
    'assignments': Empty,
    'subjects': Empty,
    'draft_assignments': Empty,
    'name': Empty,
    'authoring_exams': Empty,
    'code': Empty,
    'classroom_invitations': Empty,
    'authoring_subjects': Empty,
    'last_synced_at': Empty,
    'teachers': Empty,
    'skip_assignment_creation': Empty,
    'end_date': Empty,
    'status': Empty,
    'clever_id': Empty,
    'exams': Empty,
    'school': Empty,
    'updated_at': Empty,
    'grade_levels': Empty,
    'students': Empty,
    'google_course_id': Empty,
    'inserted_at': Empty,
    'id': Empty,
    'auto_archive_date': Empty,
    'relationshipMeta': Empty,
    'has_lti_course': Empty,
    'meta': new Map({
    'classroom_insights_count_students': Empty,
    'classroom_insights_count_students_mastering': Empty,
    'classroom_insights_count_total_guesses': Empty,
    'school_subject_breakdown': Empty,
    'count_of_school_teachers': Empty,
    'populated_metadata_fields': Empty,
    'count_of_questions_answered': Empty,
    'classroom_insights_count_correct_guesses': Empty,
    'has_lti_deployment': Empty,
    'school_subject_names': Empty,
    'classroom_insights_count_total_questions': Empty,
    'count_of_school_students': Empty,
    'is_lti_synced': Empty,
    'count_of_pending_classroom_invitations': Empty,
    'count_of_assignments': Empty,
    'classroom_insights_count_students_excelling': Empty,
    'count_of_students': Empty,
    'classroom_insights_count_students_passing': Empty,
    'count_of_school_guesses': Empty,
    'count_of_school_assignments': Empty,
    'classroom_insights_count_students_proficient': Empty,
    'count_of_school_license_seats_remaining': Empty,
    'classroom_insights_count_students_unanswered': Empty,
    'max_attempt_number': Empty,
    'classroom_insights_average_time_spent': Empty,
    'count_of_school_incorrect_guesses': Empty,
    'school_licensed_subjects': Empty,
    'classroom_insights_count_students_struggling': Empty,
    'lti_platform_name': Empty,
    'count_of_school_correct_guesses': Empty,
    'weighted_accuracies': Empty,
    'school_teacher_emails': Empty
  })
  }
);

const DefaultModel = fromJS(
  {
    'name': '',
    'code': '',
    'status': '',
    'clever_id': '',
    'google_course_id': '',
    'id': '',
    'begin_date': new Date(),
    'last_synced_at': new Date(),
    'end_date': new Date(),
    'updated_at': new Date(),
    'inserted_at': new Date(),
    'auto_archive_date': new Date(),
    'student_assignments': new List(),
    'assignments': new List(),
    'subjects': new List(),
    'draft_assignments': new List(),
    'authoring_exams': new List(),
    'classroom_invitations': new List(),
    'authoring_subjects': new List(),
    'teachers': new List(),
    'exams': new List(),
    'meta': new Map(),
    'skip_assignment_creation': false,
    'grade_levels': new List(),
    'students': new List(),
    'school': new Map(),
    'relationshipMeta': new Map(),
    'has_lti_course': false
  }
);

export class ClassroomModelV1 extends GenericModel(ModelRecord, ModelConfig, 'ClassroomModelV1') {
  // -- Query Rules --
  /**
   * @returns {boolean}
   */
  static allowsInclude(include) {
    // AllowedIncludes is currently disabled.
    return true; // AllowedIncludes.has(include);
  }

  /**
   * @returns {boolean}
   */
  static allowsFilter(filterField, filterType) {
    return filterType ?
      FilterRules.get(filterField).has(filterType) :
      FilterRules.has(filterField);
  }

  /**
   * @returns {boolean}
   */
  static allowsSort(sort) {
    return sort.split(',').filter((s) => AllowedSorts.has(s)).length > 0;
  }

  /**
   * @returns {Array}
   */
  static getAllowedSorts() {
    return AllowedSorts.keySeq().toJS();
  }

  static getMetaContextQuery(key) {
    if (!MetadataContextRules.hasIn(['optional', key]) && !MetadataContextRules.hasIn(['required', key])) {
      console.error('Rule for meta context key ' + key + ' does not exist');
    }

    return MetadataContextRules.getIn(['optional', key]) || MetadataContextRules.getIn(['required', key]);
  }

  /**
   * @returns {Immutable.Set}
   */
  static getRequiredMetaContextRules() {
    return new Set([...MetadataContextRules.get('required').values()]);
  }

  /**
   * @returns {Immutable.Set}
   */
  static getAllMetaContextRules() {
    return new Set([...MetadataContextRules.get('required').values(), ...MetadataContextRules.get('optional').values()]);
  }

  static getDefaultModel() {
    _emptyClassroomModelV1Model = _emptyClassroomModelV1Model ? _emptyClassroomModelV1Model : new ClassroomModelV1(DefaultModel);
    return _emptyClassroomModelV1Model;
  }

  static supportsMetaContext() {
    return MetadataContextRules !== null;
  }

  /**
   * @returns {string}
   */
  static getModelName() {
    return 'ClassroomModelV1';
  }

  /**
   * @returns {boolean}
   */
  static _isFullSpec() {
    return true;
  }

  constructor() {
    super(...arguments);
    this.__meta = null;
  }

  /**
   * @returns {string}
   */
  getJsonApiType() {
    return 'classroom_v1';
  }

  /**
   * @params {string} - An attribute name
   * @params {*} [def] - Optional default value override for the attribute
   */
  getValueForAttribute(attribute, def) {
    let value = this.get(attribute);
    validate(value, attribute);
    if (value === Empty) {
      value = def !== undefined ? def : DefaultModel.get(attribute);
    }
    return value;
  }

  // -- Primary Attributes --
  
  /**
   * @type {datetime}
   */
  getAutoArchiveDate() {
    const value = this.getValueForAttribute('auto_archive_date');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  setAutoArchiveDate(value) {
    const momentValue = moment(value);
    return this.setField('auto_archive_date', momentValue.isValid() ? momentValue : value);
  }

  /**
   * @type {datetime}
   */
  getBeginDate() {
    const value = this.getValueForAttribute('begin_date');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  setBeginDate(value) {
    const momentValue = moment(value);
    return this.setField('begin_date', momentValue.isValid() ? momentValue : value);
  }

  /**
   * @type {string}
   */
  getCleverId() {
    const value = this.getValueForAttribute('clever_id');
    return value;
  }

  /**
   * @type {string}
   */
  getCode() {
    const value = this.getValueForAttribute('code');
    return value;
  }

  setCode(value) {
    return this.setField('code', value);
  }

  /**
   * @type {datetime}
   */
  getEndDate() {
    const value = this.getValueForAttribute('end_date');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  setEndDate(value) {
    const momentValue = moment(value);
    return this.setField('end_date', momentValue.isValid() ? momentValue : value);
  }

  /**
   * @type {string}
   */
  getGoogleCourseId() {
    const value = this.getValueForAttribute('google_course_id');
    return value;
  }

  setGoogleCourseId(value) {
    return this.setField('google_course_id', value);
  }

  /**
   * @type {boolean}
   */
  hasLtiCourse() {
    const value = this.getValueForAttribute('has_lti_course');
    return value;
  }

  /**
   * @type {binary_id}
   */
  getId() {
    const value = this.getValueForAttribute('id');
    return value;
  }

  setId(value) {
    return this.setField('id', value);
  }

  /**
   * @type {datetime}
   */
  getInsertedAt() {
    const value = this.getValueForAttribute('inserted_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  /**
   * @type {datetime}
   */
  getLastSyncedAt() {
    const value = this.getValueForAttribute('last_synced_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  /**
   * @type {string}
   */
  getName() {
    const value = this.getValueForAttribute('name');
    return value;
  }

  setName(value) {
    return this.setField('name', value);
  }

  /**
   * @type {boolean}
   */
  isSkipAssignmentCreation() {
    const value = this.getValueForAttribute('skip_assignment_creation');
    return value;
  }

  setSkipAssignmentCreation(value) {
    return this.setField('skip_assignment_creation', value);
  }

  /**
   * @type {string}
   */
  getStatus() {
    const value = this.getValueForAttribute('status');
    return value;
  }

  setStatus(value) {
    return this.setField('status', value);
  }

  /**
   * @type {datetime}
   */
  getUpdatedAt() {
    const value = this.getValueForAttribute('updated_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  // -- Meta Attributes --
  
    /**
     * LEGACY META ACCESSORS
     * @todo These should no longer be used, instead use this.getMeta()[...]
     */
    
      getClassroomInsightsAverageTimeSpent() {
        return this.getMeta().getClassroomInsightsAverageTimeSpent();
      }
    
      getClassroomInsightsCountCorrectGuesses() {
        return this.getMeta().getClassroomInsightsCountCorrectGuesses();
      }
    
      getClassroomInsightsCountStudents() {
        return this.getMeta().getClassroomInsightsCountStudents();
      }
    
      getClassroomInsightsCountStudentsExcelling() {
        return this.getMeta().getClassroomInsightsCountStudentsExcelling();
      }
    
      getClassroomInsightsCountStudentsMastering() {
        return this.getMeta().getClassroomInsightsCountStudentsMastering();
      }
    
      getClassroomInsightsCountStudentsPassing() {
        return this.getMeta().getClassroomInsightsCountStudentsPassing();
      }
    
      getClassroomInsightsCountStudentsProficient() {
        return this.getMeta().getClassroomInsightsCountStudentsProficient();
      }
    
      getClassroomInsightsCountStudentsStruggling() {
        return this.getMeta().getClassroomInsightsCountStudentsStruggling();
      }
    
      getClassroomInsightsCountStudentsUnanswered() {
        return this.getMeta().getClassroomInsightsCountStudentsUnanswered();
      }
    
      getClassroomInsightsCountTotalGuesses() {
        return this.getMeta().getClassroomInsightsCountTotalGuesses();
      }
    
      getClassroomInsightsCountTotalQuestions() {
        return this.getMeta().getClassroomInsightsCountTotalQuestions();
      }
    
      getCountOfAssignments() {
        return this.getMeta().getCountOfAssignments();
      }
    
      getCountOfPendingClassroomInvitations() {
        return this.getMeta().getCountOfPendingClassroomInvitations();
      }
    
      getCountOfQuestionsAnswered() {
        return this.getMeta().getCountOfQuestionsAnswered();
      }
    
      getCountOfSchoolAssignments() {
        return this.getMeta().getCountOfSchoolAssignments();
      }
    
      getCountOfSchoolCorrectGuesses() {
        return this.getMeta().getCountOfSchoolCorrectGuesses();
      }
    
      getCountOfSchoolGuesses() {
        return this.getMeta().getCountOfSchoolGuesses();
      }
    
      getCountOfSchoolIncorrectGuesses() {
        return this.getMeta().getCountOfSchoolIncorrectGuesses();
      }
    
      getCountOfSchoolLicenseSeatsRemaining() {
        return this.getMeta().getCountOfSchoolLicenseSeatsRemaining();
      }
    
      getCountOfSchoolStudents() {
        return this.getMeta().getCountOfSchoolStudents();
      }
    
      getCountOfSchoolTeachers() {
        return this.getMeta().getCountOfSchoolTeachers();
      }
    
      getCountOfStudents() {
        return this.getMeta().getCountOfStudents();
      }
    
      hasLtiDeployment() {
        return this.getMeta().hasLtiDeployment();
      }
    
      isLtiSynced() {
        return this.getMeta().isLtiSynced();
      }
    
      getLtiPlatformName() {
        return this.getMeta().getLtiPlatformName();
      }
    
      getMaxAttemptNumber() {
        return this.getMeta().getMaxAttemptNumber();
      }
    
      getPopulatedMetadataFields() {
        return this.getMeta().getPopulatedMetadataFields();
      }
    
      getSchoolLicensedSubjects() {
        return this.getMeta().getSchoolLicensedSubjects();
      }
    
      getSchoolSubjectBreakdown() {
        return this.getMeta().getSchoolSubjectBreakdown();
      }
    
      getSchoolSubjectNames() {
        return this.getMeta().getSchoolSubjectNames();
      }
    
      getSchoolTeacherEmails() {
        return this.getMeta().getSchoolTeacherEmails();
      }
    
      getWeightedAccuracies() {
        return this.getMeta().getWeightedAccuracies();
      }
    
    getMeta() {
      if (this.__meta) {
        return this.__meta;
      }
      const meta = this.get('meta', Map());
      this.__meta = {
        
          /**
           * @type {integer}
           */
          getClassroomInsightsAverageTimeSpent() {
            const value = meta.get('classroom_insights_average_time_spent');
            validate(value, 'classroom_insights_average_time_spent');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getClassroomInsightsCountCorrectGuesses() {
            const value = meta.get('classroom_insights_count_correct_guesses');
            validate(value, 'classroom_insights_count_correct_guesses');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getClassroomInsightsCountStudents() {
            const value = meta.get('classroom_insights_count_students');
            validate(value, 'classroom_insights_count_students');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getClassroomInsightsCountStudentsExcelling() {
            const value = meta.get('classroom_insights_count_students_excelling');
            validate(value, 'classroom_insights_count_students_excelling');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getClassroomInsightsCountStudentsMastering() {
            const value = meta.get('classroom_insights_count_students_mastering');
            validate(value, 'classroom_insights_count_students_mastering');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getClassroomInsightsCountStudentsPassing() {
            const value = meta.get('classroom_insights_count_students_passing');
            validate(value, 'classroom_insights_count_students_passing');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getClassroomInsightsCountStudentsProficient() {
            const value = meta.get('classroom_insights_count_students_proficient');
            validate(value, 'classroom_insights_count_students_proficient');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getClassroomInsightsCountStudentsStruggling() {
            const value = meta.get('classroom_insights_count_students_struggling');
            validate(value, 'classroom_insights_count_students_struggling');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getClassroomInsightsCountStudentsUnanswered() {
            const value = meta.get('classroom_insights_count_students_unanswered');
            validate(value, 'classroom_insights_count_students_unanswered');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getClassroomInsightsCountTotalGuesses() {
            const value = meta.get('classroom_insights_count_total_guesses');
            validate(value, 'classroom_insights_count_total_guesses');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getClassroomInsightsCountTotalQuestions() {
            const value = meta.get('classroom_insights_count_total_questions');
            validate(value, 'classroom_insights_count_total_questions');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfAssignments() {
            const value = meta.get('count_of_assignments');
            validate(value, 'count_of_assignments');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfPendingClassroomInvitations() {
            const value = meta.get('count_of_pending_classroom_invitations');
            validate(value, 'count_of_pending_classroom_invitations');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfQuestionsAnswered() {
            const value = meta.get('count_of_questions_answered');
            validate(value, 'count_of_questions_answered');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfSchoolAssignments() {
            const value = meta.get('count_of_school_assignments');
            validate(value, 'count_of_school_assignments');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfSchoolCorrectGuesses() {
            const value = meta.get('count_of_school_correct_guesses');
            validate(value, 'count_of_school_correct_guesses');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfSchoolGuesses() {
            const value = meta.get('count_of_school_guesses');
            validate(value, 'count_of_school_guesses');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfSchoolIncorrectGuesses() {
            const value = meta.get('count_of_school_incorrect_guesses');
            validate(value, 'count_of_school_incorrect_guesses');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfSchoolLicenseSeatsRemaining() {
            const value = meta.get('count_of_school_license_seats_remaining');
            validate(value, 'count_of_school_license_seats_remaining');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfSchoolStudents() {
            const value = meta.get('count_of_school_students');
            validate(value, 'count_of_school_students');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfSchoolTeachers() {
            const value = meta.get('count_of_school_teachers');
            validate(value, 'count_of_school_teachers');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfStudents() {
            const value = meta.get('count_of_students');
            validate(value, 'count_of_students');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {boolean}
           */
          hasLtiDeployment() {
            const value = meta.get('has_lti_deployment');
            validate(value, 'has_lti_deployment');
            return value;
          },
  
          /**
           * @type {boolean}
           */
          isLtiSynced() {
            const value = meta.get('is_lti_synced');
            validate(value, 'is_lti_synced');
            return value;
          },
  
          /**
           * @type {string}
           */
          getLtiPlatformName() {
            const value = meta.get('lti_platform_name');
            validate(value, 'lti_platform_name');
            return value;
          },
  
          /**
           * @type {integer}
           */
          getMaxAttemptNumber() {
            const value = meta.get('max_attempt_number');
            validate(value, 'max_attempt_number');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {string[]}
           */
          getPopulatedMetadataFields() {
            const value = meta.get('populated_metadata_fields');
            validate(value, 'populated_metadata_fields');
            return value;
          },
  
          /**
           * @type {map}
           */
          getSchoolLicensedSubjects() {
            const value = meta.get('school_licensed_subjects');
            validate(value, 'school_licensed_subjects');
            return value;
          },
  
          /**
           * @type {map}
           */
          getSchoolSubjectBreakdown() {
            const value = meta.get('school_subject_breakdown');
            validate(value, 'school_subject_breakdown');
            return value;
          },
  
          /**
           * @type {string}
           */
          getSchoolSubjectNames() {
            const value = meta.get('school_subject_names');
            validate(value, 'school_subject_names');
            return value;
          },
  
          /**
           * @type {string}
           */
          getSchoolTeacherEmails() {
            const value = meta.get('school_teacher_emails');
            validate(value, 'school_teacher_emails');
            return value;
          },
  
          /**
           * @type {map[]}
           */
          getWeightedAccuracies() {
            const value = meta.get('weighted_accuracies');
            validate(value, 'weighted_accuracies');
            return value;
          },
  
      };
      return this.__meta;
    }
  
  // -- Relationships --
  
  /**
   * @type {student_assignment_v1 (many)}
   */
  getStudentAssignments() {
    const value = this.getValueForAttribute('student_assignments');
    return value;
  }

  // -- Relationship Fields: student_assignments --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withStudentAssignmentRelationshipGetters(data) {
    return {
      getSettings() {
        return data.get('settings', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getStudentAssignmentRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'student_assignment'], new Map());
    if (!id) {
      return data.map((rel) => this.__withStudentAssignmentRelationshipGetters(rel));
    } else {
      return this.__withStudentAssignmentRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {assignment_v3 (many)}
   */
  getAssignments() {
    const value = this.getValueForAttribute('assignments');
    return value;
  }

  // -- Relationship Fields: assignments --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withAssignmentRelationshipGetters(data) {
    return {
      getSettings() {
        return data.get('settings', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getAssignmentRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'assignment'], new Map());
    if (!id) {
      return data.map((rel) => this.__withAssignmentRelationshipGetters(rel));
    } else {
      return this.__withAssignmentRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {subject_v2 (many)}
   */
  getSubjects() {
    const value = this.getValueForAttribute('subjects');
    return value;
  }

  // -- Relationship Fields: subjects --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withSubjectRelationshipGetters(data) {
    return {
      getRestrictFreePractice() {
        return data.get('restrict_free_practice', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getSubjectRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'subject'], new Map());
    if (!id) {
      return data.map((rel) => this.__withSubjectRelationshipGetters(rel));
    } else {
      return this.__withSubjectRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {draft_assignment_v1 (many)}
   */
  getDraftAssignments() {
    const value = this.getValueForAttribute('draft_assignments');
    return value;
  }

  // -- Relationship Fields: draft_assignments --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withDraftAssignmentRelationshipGetters(data) {
    return {
      getSettings() {
        return data.get('settings', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getDraftAssignmentRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'draft_assignment'], new Map());
    if (!id) {
      return data.map((rel) => this.__withDraftAssignmentRelationshipGetters(rel));
    } else {
      return this.__withDraftAssignmentRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {authoring_exam_v1 (many)}
   */
  getAuthoringExams() {
    const value = this.getValueForAttribute('authoring_exams');
    return value;
  }

  // -- Relationship Fields: authoring_exams --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withAuthoringExamRelationshipGetters(data) {
    return {
      getSettings() {
        return data.get('settings', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getAuthoringExamRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'authoring_exam'], new Map());
    if (!id) {
      return data.map((rel) => this.__withAuthoringExamRelationshipGetters(rel));
    } else {
      return this.__withAuthoringExamRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {classroom_invitation_v1 (many)}
   */
  getClassroomInvitations() {
    const value = this.getValueForAttribute('classroom_invitations');
    return value;
  }

  // -- Relationship Fields: classroom_invitations --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {authoring_subject_v1 (many)}
   */
  getAuthoringSubjects() {
    const value = this.getValueForAttribute('authoring_subjects');
    return value;
  }

  // -- Relationship Fields: authoring_subjects --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withAuthoringSubjectRelationshipGetters(data) {
    return {
      getRestrictFreePractice() {
        return data.get('restrict_free_practice', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getAuthoringSubjectRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'authoring_subject'], new Map());
    if (!id) {
      return data.map((rel) => this.__withAuthoringSubjectRelationshipGetters(rel));
    } else {
      return this.__withAuthoringSubjectRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {teacher_v1 (many)}
   */
  getTeachers() {
    const value = this.getValueForAttribute('teachers');
    return value;
  }

  // -- Relationship Fields: teachers --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {exam_v1 (many)}
   */
  getExams() {
    const value = this.getValueForAttribute('exams');
    return value;
  }

  // -- Relationship Fields: exams --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withExamRelationshipGetters(data) {
    return {
      getSettings() {
        return data.get('settings', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getExamRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'exam'], new Map());
    if (!id) {
      return data.map((rel) => this.__withExamRelationshipGetters(rel));
    } else {
      return this.__withExamRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {school_v5 (one)}
   */
  getSchool() {
    const value = this.getValueForAttribute('school');
    return value;
  }

  // -- Relationship Fields: school --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {grade_level_v1 (many)}
   */
  getGradeLevels() {
    const value = this.getValueForAttribute('grade_levels');
    return value;
  }

  // -- Relationship Fields: grade_levels --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {student_v2 (many)}
   */
  getStudents() {
    const value = this.getValueForAttribute('students');
    return value;
  }

  // -- Relationship Fields: students --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withStudentRelationshipGetters(data) {
    return {
      getDeletedAt() {
        return data.get('deleted_at', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getStudentRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'student'], new Map());
    if (!id) {
      return data.map((rel) => this.__withStudentRelationshipGetters(rel));
    } else {
      return this.__withStudentRelationshipGetters(data.get(id, new Map()));
    }
  }

}

// -- Empty Model Instance --
let _emptyClassroomModelV1Model = null;

// -- Sort Rules --

const AllowedSorts = fromJS({
  'auto_archive_date': null,
  'begin_date': null,
  'clever_id': null,
  'code': null,
  'end_date': null,
  'google_course_id': null,
  'has_lti_course': null,
  'id': null,
  'inserted_at': null,
  'last_synced_at': null,
  'meta.classroom_insights_average_time_spent': null,
  'meta.classroom_insights_count_correct_guesses': null,
  'meta.classroom_insights_count_students': null,
  'meta.classroom_insights_count_students_excelling': null,
  'meta.classroom_insights_count_students_mastering': null,
  'meta.classroom_insights_count_students_passing': null,
  'meta.classroom_insights_count_students_proficient': null,
  'meta.classroom_insights_count_students_struggling': null,
  'meta.classroom_insights_count_students_unanswered': null,
  'meta.classroom_insights_count_total_guesses': null,
  'meta.classroom_insights_count_total_questions': null,
  'meta.count_of_assignments': null,
  'meta.count_of_pending_classroom_invitations': null,
  'meta.count_of_questions_answered': null,
  'meta.count_of_school_assignments': null,
  'meta.count_of_school_correct_guesses': null,
  'meta.count_of_school_guesses': null,
  'meta.count_of_school_incorrect_guesses': null,
  'meta.count_of_school_license_seats_remaining': null,
  'meta.count_of_school_students': null,
  'meta.count_of_school_teachers': null,
  'meta.count_of_students': null,
  'meta.has_lti_deployment': null,
  'meta.is_lti_synced': null,
  'meta.lti_platform_name': null,
  'meta.max_attempt_number': null,
  'meta.school_subject_names': null,
  'meta.school_teacher_emails': null,
  'name': null,
  'skip_assignment_creation': null,
  'status': null,
  'updated_at': null,
});

// -- Include Rules --
// List []

// -- Filter Rules --
const FilterRules = new Map({
  'assignments_v1': new Set(['associated']),
  'assignments_v2': new Set(['associated']),
  'assignments_v3': new Set(['associated']),
  'authoring_exams_v1': new Set(['associated']),
  'authoring_subjects_v1': new Set(['associated']),
  'auto_archive_date': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'begin_date': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'classroom_invitations_v1': new Set(['associated']),
  'clever_id': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'code': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'draft_assignments_v1': new Set(['associated']),
  'end_date': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'exams_v1': new Set(['associated']),
  'google_course_id': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'grade_levels_v1': new Set(['associated']),
  'has_lti_course': new Set(['exact','not','null']),
  'id': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'inserted_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'last_synced_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.classroom_insights_average_time_spent': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.classroom_insights_count_correct_guesses': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.classroom_insights_count_students': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.classroom_insights_count_students_excelling': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.classroom_insights_count_students_mastering': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.classroom_insights_count_students_passing': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.classroom_insights_count_students_proficient': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.classroom_insights_count_students_struggling': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.classroom_insights_count_students_unanswered': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.classroom_insights_count_total_guesses': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.classroom_insights_count_total_questions': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_assignments': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_pending_classroom_invitations': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_questions_answered': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_school_assignments': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_school_correct_guesses': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_school_guesses': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_school_incorrect_guesses': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_school_license_seats_remaining': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_school_students': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_school_teachers': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_students': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.has_lti_deployment': new Set(['exact','not','null']),
  'meta.is_lti_synced': new Set(['exact','not','null']),
  'meta.lti_platform_name': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'meta.max_attempt_number': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.populated_metadata_fields': new Set(['null']),
  'meta.school_subject_names': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'meta.school_teacher_emails': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'name': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'school_v1': new Set(['associated']),
  'school_v2': new Set(['associated']),
  'school_v3': new Set(['associated']),
  'school_v4': new Set(['associated']),
  'school_v5': new Set(['associated']),
  'skip_assignment_creation': new Set(['exact','not','null']),
  'status': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'student_assignments_v1': new Set(['associated']),
  'students_v1': new Set(['associated']),
  'students_v2': new Set(['associated']),
  'subjects_v1': new Set(['associated']),
  'subjects_v2': new Set(['associated']),
  'teachers_v1': new Set(['associated']),
  'updated_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
});
    

// -- Metadata Context Rules --
const MetadataContextRules = fromJS({
  optional: {
    'assignment_id': 'meta.context.assignment.id',
    'classroom_counts': 'meta.context.classroom.counts',
    'classroom_insights': 'meta.context.classroom.insights',
    'licensed_subjects': 'meta.context.classroom.licensed_subjects',
    'classroom_overview': 'meta.context.classroom.overview',
    'classroom_statuses': 'meta.context.classroom.statuses',
    'csv_headers': 'meta.context.csv_headers',
    'end_date': 'meta.context.end_date',
    'attempt_number': 'meta.context.guess.attempt_number',
    'max_attempt': 'meta.context.guess.max_attempt',
    'most_recent': 'meta.context.guess.most_recent',
    'guide_level_id': 'meta.context.guide_level.id',
    'lti': 'meta.context.lti',
    'lti_course_id': 'meta.context.lti_course.id',
    'lti_deployment': 'meta.context.lti_deployment',
    'lti_platform_name': 'meta.context.lti_platform_name',
    'school_id': 'meta.context.school.id',
    'school_license_seats_remaining': 'meta.context.school.school_license_seats_remaining',
    'school_administrator_dashboard': 'meta.context.school_administrator.dashboard',
    'start_date': 'meta.context.start_date',
    'subject_breakdown': 'meta.context.subject.breakdown',
    'subject_id': 'meta.context.subject.id',
    'teacher_id': 'meta.context.teacher.id',
  },
  required: {
  }
});

// -- Legacy Name Export --
export const Classroom = ClassroomModelV1;
