import React, {useMemo} from 'react';
import FeatureFlag from 'client/components/FeatureFlag/FeatureFlag.react';
import {QuestionModelV3} from 'resources/augmented/Question/QuestionModel.v3';
import {useShallow} from 'zustand/react/shallow';

import {useQuestionTranslationStore} from '../../shared/TranslationSection';
import MarkdownBlock from '../../shared/MarkdownBlock/MarkdownBlock';
import LegacyTextHighlightPrompt from '../legacy/TextHighlightPrompt.react';

import TextHighlightPrompt from './TextHighlightPrompt.react';

interface TextHighlightExplanationProps {
  storeName?: string;
  question?: QuestionModelV3;
}

const TextHighlightExplanation = ({storeName, question}: TextHighlightExplanationProps) => {
  const {translatedQuestionInfo, showAnswerTranslation} = useQuestionTranslationStore(
    useShallow((state) => ({
      translatedQuestionInfo: state.translatedQuestionInfo,
      showAnswerTranslation: state.toggleStatus.answer
    }))
  );
  const sample: string = useMemo(() => {
    if (showAnswerTranslation) {
      return translatedQuestionInfo?.sample;
    }
    return question.getMeta().getSample();
  }, [question, showAnswerTranslation, translatedQuestionInfo]);

  return (
    <FeatureFlag name='assignment_question_translations'>
      <div>
        <div className='text-highlight-explanation'>
          <h2>Correct Selections</h2>
          <TextHighlightPrompt
            explanation
            storeName={storeName}
            question={question}
            isGuessSubmitted
          />
        </div>
        {sample && <MarkdownBlock heading='Sample Response' text={sample} />}
      </div>
      <FeatureFlag.Fallback>
        <LegacyTextHighlightPrompt storeName={storeName} question={question} />
      </FeatureFlag.Fallback>
    </FeatureFlag>
  );
};

export default TextHighlightExplanation;
