import React, {useState, useCallback} from 'react';
import {isNumber} from 'lodash';

import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import Button from 'sg/Button/Button.react';

import PassageCorrectionPopover from '../shared/PassageCorrectionPopover.react';
import GenericExplanation from '../../common/V2/GenericExplanation';

interface Props {
  question: any;
}

const PassageCorrectionExplanation = (props: Props) => {
  const {question} = props;
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [showCorrectPassage, setShowCorrectPassage] = useState(false);

  const handleClick = useCallback((e) => {
    const {regionIndex} = e.target.dataset;
    if (regionIndex === undefined) {
      return;
    }
    setActiveIndex(parseInt(e.target.dataset.idx, 10));
  }, []);

  const handleActiveIndexReset = useCallback(() => {
    setActiveIndex(null);
  }, []);

  const toggleShowHide = useCallback(() => {
    setShowCorrectPassage((prevValue) => {
      const updatedValue = !prevValue;
      if (updatedValue === false) {
        setActiveIndex(null);
      }
      return updatedValue;
    });
  }, []);

  const wordPropsFunc = useCallback(
    (text, optionIndex) => {
      const matchedRegion = question.getRegionForOptionIndex(optionIndex);
      /**
       * Only non distractors and distractors with solutions should be interactive.
       */
      if (!matchedRegion || !matchedRegion.get('annotation')) {
        return {text};
      }
      const matchedRegionIndex = question.getRegionKeyForOptionIndex(optionIndex);
      const isAnnotatedDistractor = matchedRegion.get('solutions') === null;
      const splitSolution = isAnnotatedDistractor
        ? null
        : matchedRegion.get('solutions').first().split(/\s+/);
      const matchedStartIndex = matchedRegion.get('start_index');
      const matchedEndIndex = matchedRegion.get('end_index');
      const isActive =
        isNumber(activeIndex) && question.doesRegionContainOptionIndex(matchedRegion, activeIndex);
      const isRangeStart = matchedStartIndex === optionIndex;
      const isRangeEnd = matchedEndIndex === optionIndex;
      const regionIndexDifference = matchedEndIndex - matchedStartIndex;
      const currentIndex = Math.abs(matchedEndIndex - optionIndex - regionIndexDifference);
      /**
       * A solution can have as many or more words than the incorrect region (i.e. 'alot' can be corrected to 'a lot',
       * but not the other way around). Because of this, if we're rendering the last word in an incorrect region, we
       * render the rest of the words in the solution, joined by a space.
       */
      let correctedText;
      if (isAnnotatedDistractor) {
        correctedText = text;
      } else if (currentIndex === regionIndexDifference) {
        correctedText = splitSolution.slice(regionIndexDifference).join(' ');
      } else {
        correctedText = splitSolution[currentIndex];
      }
      return {
        text,
        correctedText,
        isActive,
        isRangeStart,
        isRangeEnd,
        matchedRegionIndex,
        isCorrect: true
      };
    },
    [question, activeIndex]
  );

  const annotation =
    activeIndex !== null ? question.getRegionForOptionIndex(activeIndex).get('annotation') : '';

  return (
    <GenericExplanation {...(props as any)}>
      <div className='passage-correction-explanation'>
        <div className='passage-correction-explanation__show-hide-toggle-wrapper'>
          <Button
            linkButton
            className='passage-correction-explanation__show-hide-toggle'
            onClick={toggleShowHide}
            text={`${showCorrectPassage ? 'Hide' : 'Show'} correct passage`}
          />
        </div>
        {showCorrectPassage ? (
          <MarkdownRendererV2
            passageCorrection
            className='passage-correction-question__correct-passage'
            text={question.getUncorrectedText()}
            onClick={handleClick}
            wordPropsFunc={wordPropsFunc}
            popover={
              <Popover
                activeIndex={activeIndex}
                key={activeIndex}
                annotation={annotation}
                onReset={handleActiveIndexReset}
              />
            }
          />
        ) : null}
      </div>
    </GenericExplanation>
  );
};

interface PopoverProps {
  activeIndex: number | null;
  annotation: string;
  onReset: any;
}

const Popover = ({annotation, ...props}: PopoverProps) => {
  return (
    <PassageCorrectionPopover {...props}>
      <div className='passage-correction-popover__annotation-wrapper'>
        <div className='a-form-input__label'>Annotation:</div>
        <MarkdownRendererV2 text={annotation} />
      </div>
    </PassageCorrectionPopover>
  );
};

export default PassageCorrectionExplanation;
