import React from 'react';
import SimpleMarkdown from 'simple-markdown';
import SupplementRenderer from 'client/Supplement/V2/SupplementRenderer.react';

/**
 * RegExp _string_ shared for the creation of various Supplment RegExps.
 * @example [s:<uuid>(:<additional_attributes)]
 * @example [s:edc918e5-a42e-41e4-b803-bb52edabe3a4:x=10:y=20:downloadable]
 * @type {string}
 */
const supplementRegexBaseString = `\\[s:([a-zA-Z0-9-]+)(?::([^\n\\]]+))?\\]`;

const regex = new RegExp(`^${supplementRegexBaseString}`);
/**
 * @type {RegExp}
 */
export const supplementRegexGlobal = new RegExp(supplementRegexBaseString, 'g');

/**
 * Parse a supplement RegExp match converting it into our standard object.
 * @param {array} match
 * @returns {{
 *  id: string,
 *  name: string,
 *  type: string,
 *  token: string,
 *  attributes: {
 *    imageSize: ?string,
 *    x: ?string,
 *    y: ?string,
 *    width: ?string,
 *    height: ?string,
 *    downloadable: Boolean,
 *    viewable: Boolean
 *  }
 * }}
 */
function parseMatch([token, id, rawAttributes]) {
  const attributes =
    rawAttributes &&
    rawAttributes
      .split(':')
      .filter(Boolean)
      .reduce((acc, attributeStr) => {
        const {key, value} = attributeStr.match(/(?<key>[^=]+)=?(?<value>[^\n]+)?/).groups;
        acc[key] = value ?? true;
        return acc;
      }, {});

  return {
    id,
    token,
    attributes: {
      // Default values to pass to the renderer
      imageSize: null,
      x: null,
      y: null,
      width: null,
      height: null,
      downloadable: null,
      viewable: null,
      // Default values end
      ...attributes
    }
  };
}

export const supplementRule = {
  order: SimpleMarkdown.defaultRules.paragraph.order - 0.5,
  match: (source) => regex.exec(source),
  parse: (capture) => {
    return {
      /**
       * SimpleMarkdown will _technically_ specify the `type` here, but since it is *REQUIRED* and *RESERVED*
       * we include it here to avoid conflicts. If we _did_ just spread the result of `parseMatch`, SimpleMarkdown
       * will attempt to render the parsed Node using _that_ "type", attempting to map it to a rule (ie. "image").
       */
      type: 'supplementRule',
      content: parseMatch(capture)
    };
  },
  react: (node) => {
    return (
      <SupplementRenderer
        id={node.content.id}
        key={node.content.token}
        attributes={node.content.attributes}
      />
    );
  }
};
/**
 * Parse a string for supplement matches, then parse the match.
 * @param {string} str The string to parse.
 */
export function parse(str) {
  return parseMatch(regex.exec(str));
}
