import React, {useEffect, useMemo, useState} from 'react';
import {Map} from 'immutable';
import {getSupplementTypeDefinition} from 'client/SupplementTypes/SupplementTypeDefinitions';
import supplementStore from 'client/Supplement/SupplementStore';
import supplementActions from 'client/Supplement/SupplementActions';
import {LoadingSpinner, Text} from '@albert-io/atomic';
import FeatureFlag from 'client/components/FeatureFlag/FeatureFlag.react';
import {useShallow} from 'zustand/react/shallow';
import {useQuestionTranslationStore} from 'client/components/QuestionTypes/shared/TranslationSection';

import {callAction} from '../../framework';
import LegacyRenderer from '../SupplementRenderer.react';

interface SupplementRendererProps {
  id: string;
  attributes: unknown;
}

const SupplementRenderer = ({id, attributes}: SupplementRendererProps) => {
  const [isReady, setIsReady] = useState(supplementStore.hasSupplement(id));
  const [hasError, setHasError] = useState(false);
  const [supplement, setSupplement] = useState<any | null>(null);

  useEffect(() => {
    const fetchOrSetSupplement = async () => {
      if (!isReady) {
        callAction(supplementActions.FETCH_SUPPLEMENT, id);
        const supplementPromise = supplementStore.getFetchSupplementPromise();
        try {
          await supplementPromise;
        } catch (err) {
          setHasError(true);
        } finally {
          setIsReady(true);
        }
      } else {
        setSupplement(supplementStore.getSupplement(id));
      }
    };
    fetchOrSetSupplement();
  }, [id, isReady]);

  // translation info

  const {translatedSupplements, showPromptTranslation} = useQuestionTranslationStore(
    useShallow((state) => ({
      translatedSupplements: state.translatedSupplements,
      showPromptTranslation: state.toggleStatus.prompt
    }))
  );

  const {content, name} = useMemo(() => {
    if (!supplement) return {content: Map(), name: ''};
    if (showPromptTranslation) {
      const translatedSup = translatedSupplements?.find((ts) => ts.id === id);
      if (translatedSup) {
        const contentMap = Map({...translatedSup.content});
        return {content: contentMap, name: translatedSup.name};
      }
    }
    return {
      content: supplement.get('content', Map()),
      name: supplement.get('name')
    };
  }, [supplement, showPromptTranslation, id, translatedSupplements]);

  const Renderer = useMemo(() => {
    if (!supplement) return null;
    try {
      const renderer = getSupplementTypeDefinition(supplement.get('type')).get('renderer');
      return renderer;
    } catch (err) {
      return null;
    }
  }, [supplement]);

  if (!isReady || hasError || !supplement) {
    return (
      <div className='u-display_flex u-justify-content_center'>
        {hasError ? (
          <Text color='negative'>There was a problem loading the attached supplement.</Text>
        ) : (
          <LoadingSpinner assistiveText='Loading question supplement...' size={2} />
        )}
      </div>
    );
  }

  return (
    <Renderer
      id={supplement.getId()}
      name={name}
      content={content}
      supplement={supplement}
      attributes={attributes}
    />
  );
};

const SupplementRendererWrapper = (props: SupplementRendererProps) => {
  return (
    <FeatureFlag name='assignment_question_translations'>
      <SupplementRenderer {...props} />
      <FeatureFlag.Fallback>
        <LegacyRenderer {...props} />
      </FeatureFlag.Fallback>
    </FeatureFlag>
  );
};

export default SupplementRendererWrapper;
