import React, {useCallback} from 'react';
import {Link} from 'react-router';

import MarkdownRendererV2 from 'client/generic/MarkdownRendererV2/MarkdownRendererV2.react';
import {Chip} from '@albert-io/atomic';
import {TagOrStandardGroup} from 'client/components/TagOrStandardGroup/TagOrStandardGroup.react';
import {resource} from '@albert-io/json-api-framework/request/builder';

import appStore from 'client/AppStore';

import LabelTable from '../LabelTable/LabelTable.react';

import {StandardChip} from './StandardChip.react';

/**
 * @todo Implement Mandark version of `QuestionHelpfulnessVote`
 * @see https://github.com/albert-io/project-management/issues/687
 */
import ResultIndicatorBar from './ResultIndicatorBar/ResultIndicatorBar.react';
/**
 * @todo This stylesheet probably needs to be cleaned up.
 */
import '../question-footer.scss';

interface Props {
  isCorrect: boolean;
  questionId: string;
  questionSetId: string;
  responseStats: any;
  showLabelTable: boolean;
  solutionText: string;
  subjectSlug: string;
  videoExplanationId: string;
  hideResultIndicatorBar: boolean;
  isUnsubmittedAssignmentQuestion: boolean;
  isInUnpublishedSubject: boolean;
  sample?: string;
  children: any;
}

const GenericExplanation = ({
  isCorrect,
  sample,
  questionId,
  questionSetId,
  responseStats,
  showLabelTable,
  solutionText,
  subjectSlug,
  videoExplanationId,
  hideResultIndicatorBar,
  isUnsubmittedAssignmentQuestion,
  isInUnpublishedSubject = false,
  children
}: Props) => {
  const generateSampleResponse = useCallback(() => {
    if (!sample) {
      return null;
    }
    return [
      <h2 key='sampleHeading' className='question-footer__explanation-heading'>
        Sample Response
      </h2>,
      <MarkdownRendererV2
        text={sample}
        key='sampleText'
        className='question-footer__sample-response'
      />
    ];
  }, [sample]);

  const generateExplanationContent = useCallback(() => {
    const els = [
      <h2 key='heading' className='question-footer__explanation-heading'>
        Explanation
      </h2>
    ];

    if (isUnsubmittedAssignmentQuestion) {
      return els.concat([
        <div key='solution' className='question-footer__explanation-content'>
          <p className='question-footer__explanation-content-unsubmitted-message'>
            You&apos;ll need to place a guess in order to view this question&apos;s explanation.
          </p>
          <div className='explanation-content-placeholder'>
            <div className='explanation-content-placeholder__line explanation-content-placeholder__line--medium' />
            <div className='explanation-content-placeholder__line' />
            <div className='explanation-content-placeholder__line explanation-content-placeholder__line--small' />
            <div className='explanation-content-placeholder__line' />
            <div className='explanation-content-placeholder__line explanation-content-placeholder__line--medium' />
            <div className='explanation-content-placeholder__line explanation-content-placeholder__line--medium' />
          </div>
        </div>
      ]);
    }

    els.push(
      <div key='solution' className='question-footer__explanation-content'>
        <MarkdownRendererV2 text={solutionText} />
      </div>
    );

    if (videoExplanationId) {
      els.push(
        <div key='video'>
          <h3 className='question-footer__explanation-heading'>Video Explanation</h3>
          <MarkdownRendererV2 text={`[s:${videoExplanationId}:videoExplanation:video]`} />
        </div>
      );
    }

    return els;
  }, [isUnsubmittedAssignmentQuestion, solutionText, videoExplanationId]);

  // Determines whether or not we should the <LabelTable> component...
  // also used to toggle Label tooltips based on the presence of the LabelTable
  const isDennis = appStore.isZoneDennis();
  const tagsQuery = resource('tags_v1').mandarkEndpoint([
    isDennis ? 'authoring_question_sets_v1' : 'question_sets_v1',
    questionSetId,
    'tags_v1'
  ]);
  const standardsQuery = resource('standards_v1')
    .mandarkEndpoint([
      isDennis ? 'authoring_questions_v1' : 'questions_v3',
      questionId,
      'standards_v1'
    ])
    .include('standard_set_v1');

  return (
    <div className='question-footer__main-content-wrapper'>
      {hideResultIndicatorBar ? null : <ResultIndicatorBar isCorrect={isCorrect} />}
      {children}
      <div className='question-footer__explanation-wrapper'>
        {generateSampleResponse()}
        {generateExplanationContent()}
      </div>
      {showLabelTable ? <LabelTable query={standardsQuery} /> : null}
      {standardsQuery.isResourcePopulated() && !standardsQuery.getResource().isEmpty() && (
        <div>
          <h2 className='question-footer__explanation-heading'>Standards</h2>
          <TagOrStandardGroup
            query={standardsQuery}
            renderFunc={(standard, key) => (
              <StandardChip
                as={Link}
                key={key}
                enableTooltip={!showLabelTable}
                standard={standard}
                title={`See all questions listed under ${standard.getTitle()}`}
                to={`/${subjectSlug}?standards=${standard.getId()}`}
              />
            )}
          />
        </div>
      )}
      {tagsQuery.isResourcePopulated() && !tagsQuery.getResource().isEmpty() && (
        <div>
          <h2 className='question-footer__explanation-heading'>Tags</h2>
          <TagOrStandardGroup
            query={tagsQuery}
            renderFunc={(tag, key) => (
              <Chip
                as={Link}
                key={key}
                title={`See all questions tagged ${tag.getName()}`}
                to={isInUnpublishedSubject ? null : `/${subjectSlug}?tags=${tag.getId()}`}
              >
                <MarkdownRendererV2 text={tag.getName()} />
              </Chip>
            )}
          />
        </div>
      )}
      {isUnsubmittedAssignmentQuestion ? null : responseStats}
    </div>
  );
};

export default GenericExplanation;
