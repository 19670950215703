import React, {useCallback} from 'react';
import {v4 as uuid} from 'uuid';
import {List, Map} from 'immutable';
import TextArea from 'generic/Forms/TextArea/TextArea.react';
import Button from 'sg/Button/Button.react';

import {AuthoringQuestionModelV1} from 'resources/augmented/AuthoringQuestion/AuthoringQuestionModel.v1';

import 'assets/stylesheets/modules/_text-highlight.scss';
import 'assets/stylesheets/modules/_text-highlight-editor.scss';
import {useQuestionEditorV2Store} from 'client/EditPage/V2/QuestionEditorV2Store';
import {useShallow} from 'zustand/react/shallow';

import {PRIMARY_CONTENT_LANGUAGE} from 'client/EditPage/V2/QuestionEditorV2Store.types';

import AnswerPermutation from './AnswerPermutation.react';

interface Props {
  question: AuthoringQuestionModelV1;
  onModelChange: (question: AuthoringQuestionModelV1) => void;
  validationErrors: Map<string, string>;
}

const TextHighlightEditor = ({question, onModelChange, validationErrors}: Props) => {
  const {currentLanguage, translatedHighlightPrompt, updateTranslatedQuestionField} =
    useQuestionEditorV2Store(
      useShallow((state) => ({
        currentLanguage: state.currentLanguage,
        updateTranslatedQuestionField: state.updateTranslatedQuestionField,
        translatedHighlightPrompt:
          state
            .currentTranslatedQuestion()
            ?.translated_fields?.find((field) => field.field === 'highlight_prompt')?.text || ''
      }))
    );

  const addPermutation = useCallback(() => {
    const permutationId = uuid();
    const validResponse = question.getValidResponse().set(permutationId, List());

    let updatedQuestion = question.setValidResponse(validResponse);

    if (validResponse.size === 1) {
      updatedQuestion = updatedQuestion.setPreferredPermutation(permutationId);
    }

    onModelChange(updatedQuestion);
  }, [question, onModelChange]);

  const onChange = useCallback(
    (e) => {
      if (currentLanguage === PRIMARY_CONTENT_LANGUAGE) {
        const updatedQuestion = question
          .setHighlightPrompt(e.target.value)
          .setPreferredPermutation('')
          .setValidResponse(Map());

        onModelChange(updatedQuestion);
      } else {
        updateTranslatedQuestionField(currentLanguage, 'highlight_prompt', 'text', e.target.value);
      }
    },
    [question, onModelChange, currentLanguage, updateTranslatedQuestionField]
  );

  const makeError = useCallback((error) => {
    return error ? <div className='a-form-input__error'>{error}</div> : null;
  }, []);

  const generatePermutations = useCallback(() => {
    const highlightPrompts = question.getValidResponse();
    return highlightPrompts
      .map((answerPermutation, permutationId) => {
        const error = answerPermutation.isEmpty()
          ? makeError(validationErrors.get('validResponse'))
          : null;
        return (
          <AnswerPermutation
            // this isn't even an array index, eslint thinks this is an array but it's an immutable.js Map
            // eslint-disable-next-line react/no-array-index-key
            key={permutationId}
            highlightPrompt={question.getHighlightPrompt()}
            permutationId={permutationId}
            highlights={answerPermutation}
            preferredPermutationId={question.getPreferredPermutation()}
            question={question}
            onModelChange={onModelChange}
            error={error}
          />
        );
      })
      .toList();
  }, [question, onModelChange, validationErrors, makeError]);

  const preferredPermutationError = makeError(validationErrors.get('preferredPermutation'));

  const value =
    currentLanguage === PRIMARY_CONTENT_LANGUAGE
      ? question.getHighlightPrompt()
      : translatedHighlightPrompt;

  return (
    <div className='question-editor--text-highlight'>
      <TextArea
        className='question-editor__question-detail--fullwidth question-editor__textarea--text-highlight-answer-content'
        label='Answer Content'
        name='highlightPrompt'
        placeholder=''
        onChange={onChange}
        value={value}
        data-testid='question-editor-text-highlight-input'
      />
      {currentLanguage === PRIMARY_CONTENT_LANGUAGE && (
        <div className='text-highlight-editor__permutations-wrapper'>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className='a-form-input__label'>Highlight Correct Answers</label>
          <Button onClick={addPermutation}>Add answer permutation</Button>
          {preferredPermutationError}
          {generatePermutations()}
        </div>
      )}
    </div>
  );
};

export default TextHighlightEditor;
