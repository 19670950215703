import React, {useCallback, useEffect} from 'react';
import {callTargetedAction} from 'client/framework';
import {QuestionModelV3} from '@albert-io/models';
import FeatureFlag from 'client/components/FeatureFlag/FeatureFlag.react';

import LegacyTextHighlightInputArea from '../legacy/TextHighlightInputArea.react';
import textHighlightQuestionActions from '../TextHighlightQuestion.actions';

import TextHighlightPrompt from './TextHighlightPrompt.react';

import '../text-highlight-question.scss';

interface TextHighlightInputAreaProps {
  isGuessCorrect?: boolean;
  isGuessSubmitted?: boolean;
  question?: QuestionModelV3;
  // questionId?: string // This was in the old component file, seems to be unused though
  storeName?: string;
}

/*

old component propTypes
  static propTypes = {
    isGuessCorrect: PropTypes.bool,
    isGuessSubmitted: PropTypes.bool,
    question: PropTypes.instanceOf(QuestionModelV3),
    questionId: PropTypes.string,
    storeName: PropTypes.string
  };
*/

const TextHighlightInputArea = ({
  storeName = 'textHighlightQuestionStore',
  question,
  isGuessSubmitted,
  isGuessCorrect
}: TextHighlightInputAreaProps) => {
  const setUpQuestion = useCallback(
    (questionToSetup: QuestionModelV3) => {
      callTargetedAction({
        name: textHighlightQuestionActions.SET_UP_QUESTION,
        payload: {
          highlightPrompt: questionToSetup.getHighlightPrompt(),
          preferredPermutation: questionToSetup.getPreferredPermutation()
        },
        targetStore: storeName
      });
    },
    [storeName]
  );

  const clearSelections = useCallback(() => {
    callTargetedAction({
      name: textHighlightQuestionActions.CLEAR_SELECTIONS,
      targetStore: storeName
    });
  }, [storeName]);

  const shouldClearSelection = useCallback(() => {
    if (question.getStore().isGuessSubmitted === true && isGuessSubmitted === false) {
      clearSelections();
    }
  }, [question, isGuessSubmitted, clearSelections]);

  useEffect(() => {
    setUpQuestion(question);
    return () => {
      shouldClearSelection();
    };
  }, [storeName, question, shouldClearSelection, setUpQuestion]);

  return (
    <div className='text-highlight-question'>
      {/* TODO add warning about highlighting translated version?
      use
      */}
      <h2>Highlight Answer(s) Below</h2>
      <TextHighlightPrompt
        storeName={storeName}
        question={question}
        isGuessCorrect={isGuessCorrect}
        isGuessSubmitted={isGuessSubmitted}
      />
    </div>
  );
};

const TextHighlightInputAreaWrapper = (props: TextHighlightInputAreaProps) => {
  return (
    <FeatureFlag name='assignment_question_translations'>
      <TextHighlightInputArea {...props} />
      <FeatureFlag.Fallback>
        <LegacyTextHighlightInputArea {...props} />
      </FeatureFlag.Fallback>
    </FeatureFlag>
  );
};

export default TextHighlightInputAreaWrapper;
