import React, {useRef, useEffect, useCallback, useMemo} from 'react';
import {Map} from 'immutable';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import {useQuestionEditorV2Store} from 'client/EditPage/V2/QuestionEditorV2Store';
import {setUpStore, callTargetedAction} from 'client/framework';

import usePrevious from 'lib/hooks/usePrevious';

import {Text} from '@albert-io/atomic';

import passageCorrectionQuestionActions from './PassageCorrectionQuestion.actions';
import PassageCorrectionQuestionStore from './PassageCorrectionQuestion.store';
import PassageCorrectionPreSubmissionRenderer from './PassageCorrectionPreSubmissionRenderer';

import './passage-correction-question.scss';

interface Props {
  storeName: string;
  questionId: string;
  question: any;
  isGuessSubmitted: boolean;
}

const PassageCorrectionQuestion = (props: Props) => {
  const {storeName, questionId, question, isGuessSubmitted} = props;
  const initialCorrectionsRef = useRef(Map());
  const prevStoreName = usePrevious(storeName);

  const currentTranslatedQuestion = useQuestionEditorV2Store((state) =>
    state.currentTranslatedQuestion()
  );

  const store = useMemo(
    (storeNameArg = storeName, questionIdArg = questionId) => {
      return setUpStore(PassageCorrectionQuestionStore, storeNameArg, questionIdArg);
    },
    [storeName, questionId]
  );

  const setInitialCorrections = useCallback(() => {
    if (store.hasInitialCorrections()) {
      return;
    }
    callTargetedAction({
      name: passageCorrectionQuestionActions.SET_INITIAL_CORRECTIONS,
      payload: initialCorrectionsRef.current,
      targetStore: store.getName()
    });
    initialCorrectionsRef.current = Map();
  }, [store]);

  const addWordToInitialCorrection = useCallback(
    ({text, matchedRegionIndex, optionIndex}) => {
      if (matchedRegionIndex === undefined || store.hasInitialCorrections()) {
        return;
      }
      initialCorrectionsRef.current = initialCorrectionsRef.current.setIn(
        [matchedRegionIndex, optionIndex],
        text
      );
    },
    [store]
  );

  useEffect(() => {
    if (prevStoreName === undefined) {
      // Initial mount
      setInitialCorrections();
    }
  }, [storeName, store, setInitialCorrections, prevStoreName]);

  return (
    <div className='passage-correction-question'>
      <MarkdownRendererV2 text={currentTranslatedQuestion?.prompt || question.getPrompt()} />
      <h2 className='question__prompt-heading'>
        {!isGuessSubmitted
          ? 'Click the word(s) you want to edit in the sentence below and type your changes.'
          : 'Click highlighted areas for more information.'}
      </h2>
      {currentTranslatedQuestion?.uncorrected_text && (
        <div>
          <Text color='warning' size='l'>
            Passage correction translations are only shown for reference. Passage correction
            questions must be answered in their original language.
          </Text>
          <MarkdownRendererV2
            text={currentTranslatedQuestion.uncorrected_text}
            className='u-mar-t_1'
          />
        </div>
      )}
      {/* In practice, this component currently ALWAYS has isGuessSubmitted = false, since it's used only in question preview. */}
      {!isGuessSubmitted && !currentTranslatedQuestion?.uncorrected_text && (
        <PassageCorrectionPreSubmissionRenderer
          {...props}
          onRenderWordFunc={addWordToInitialCorrection}
        />
      )}
    </div>
  );
};

export default PassageCorrectionQuestion;
