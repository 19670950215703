// @flow
import {kebabCase} from 'lodash';
import {List, Map, OrderedMap, Record, Range} from 'immutable';
import PropTypes from 'prop-types';
import {GuessModel} from 'resources/Guess/Guess.model';
import {stats} from 'lib/StatsUtil';
import {memoize} from 'lib/memoizer';
import type {QuestionSetModelV1} from 'resources/GeneratedModels/QuestionSet/QuestionSetModel.v1';
import type {AuthoringQuestionSetModelV1} from 'resources/augmented/QuestionSet/AuthoringQuestionSetModel.v1';

/**
 * @todo This needs to be moved to a centralized place.
 */
const REGEX_SPECIAL_CHARS = new RegExp('\u00AE', 'g');
export function getTitleSlug(title: string) {
  title = title.toLowerCase().replace(REGEX_SPECIAL_CHARS, '');
  return kebabCase(title);
}

/*
 * Base Helpers
 */

const InputAnswerRecord = Record({
  correct: false,
  count_of_answers: 0,
  input_value: ''
});

class InputAnswerStat extends InputAnswerRecord {
  getAnswerCount(): number {
    return this.get('count_of_answers');
  }

  getInputValue(): string {
    return this.get('input_value');
  }

  getPercentageResponseRate(totalAnswerCount: number): number {
    return stats.percentage(this.getAnswerCount(), totalAnswerCount);
  }

  isCorrect(): boolean {
    return this.get('correct');
  }
}

const EmptyBaseQuestion = {
  /* eslint-disable camelcase */
  authoring_question_set: Map(),
  difficulty: 1,
  id: '',
  labels: List(),
  meta: Map(),
  points: 0,
  position: 0,
  prompt: '',
  published: false,
  question_set: Map(),
  question_type: '',
  question_sub_type: null,
  rubric: Map(),
  slug_id: '',
  solution_text: '',
  supplements: List(),
  title: '',
  video_explanation_id: ''
  /* eslint-enable camelcase */
};

function addSharedQuestionMethods(Class) {
  return class extends Class {
    __legacy = true;

    cache = memoize();

    getId(): string {
      return this.get('id');
    }

    getDifficulty(): number {
      return this.get('difficulty');
    }

    getGuessHistory(): List<GuessModel> {
      return this.getIn(['meta', 'guess_history'], List()).map((guess) => new GuessModel(guess));
    }

    getLabels(): List<*> {
      return this.get('labels');
    }

    getPoints(): number {
      return this.hasIn(['meta', 'points']) ? this.getIn(['meta', 'points']) : this.get('points');
    }

    getPosition(): number {
      return this.get('position');
    }

    getPrompt(): string {
      return this.get('prompt');
    }

    /**
     * Returns 20% of the `prompt`
     * This is used for `free-response` question "paywalls".
     */
    getPromptPreview(): string {
      return this.get('prompt').substr(0, Math.round(this.get('prompt').length * 0.2));
    }

    getTitle(): string {
      return this.get('title');
    }

    getType(): string {
      return this.get('question_type');
    }

    getQuestionSubType(): string {
      return this.get('question_sub_type');
    }

    getSample(): string {
      return this.getIn(['meta', 'sample'], '');
    }

    getMeta(): Map<*, *> {
      return this.get('meta');
    }

    getRubric(): Map<*, *> {
      return this.getIn(['meta', 'rubric'], Map());
    }

    getValidResponse(): Map<*, *> {
      return this.getIn(['meta', 'rubric', 'validResponse'], Map());
    }

    getQuestionSet(): QuestionSetModelV1 {
      return this.get('question_set');
    }

    getAuthoringQuestionSet(): AuthoringQuestionSetModelV1 {
      return this.get('authoring_question_set');
    }

    getSlugId(): string {
      return this.get('slug_id');
    }

    getSolutionText(): string {
      // This model is shared between normal questions and authoring questions. Authoring questions have
      // their rubric as an attribute, while normal questions include the rubric as meta
      if (this.hasIn(['meta', 'solution_text'])) {
        return this.getIn(['meta', 'solution_text'], '');
      }
      return this.get('solution_text');
    }

    getSupplements(): List<*> {
      return this.get('supplements');
    }

    getVideoExplanationId(): string {
      return this.get('video_explanation_id');
    }

    getTitleSlug(): string {
      return getTitleSlug(this.getTitle());
    }

    hasGuess(): boolean {
      return !this.getIn(['meta', 'guess_history'], List()).isEmpty();
    }

    hasGuessForAssignment(assignmentId: string): boolean {
      return !this.getGuessHistory()
        .filter((guess) => guess.getAssignmentId() === assignmentId)
        .isEmpty();
    }

    getGuessFeedbackForAssignment(assignmentId: string): boolean {
      const guessForAssignment = this.getGuessHistory().find(
        (guess) => guess.getAssignmentId() === assignmentId
      );
      return guessForAssignment ? guessForAssignment.isCorrect() : false;
    }

    isMostRecentGuessCorrect(): boolean {
      return this.getGuessHistory() && this.getGuessHistory().first().isCorrect();
    }

    isQuestionInActiveAssignment(): boolean {
      return this.getIn(['meta', 'active_assignment_question'], false);
    }

    getCorrectAnswerCountForInputValue(inputId: string, valueId: string): number {
      return this.getIn(
        ['meta', 'how_others_answered', inputId, 'inputs', valueId, 'count_of_correct_answers'],
        0
      );
    }

    getTotalAnswerCountForInputValue(inputId: string, valueId: string): number {
      return this.getIn(
        ['meta', 'how_others_answered', inputId, 'inputs', valueId, 'count_of_answers'],
        0
      );
    }

    getPercentageCorrectForInputValue(inputId: string, valueId: string): number {
      return stats.percentage(
        this.getCorrectAnswerCountForInputValue(inputId, valueId),
        this.getTotalAnswerCountForInputValue(inputId, valueId)
      );
    }

    getPercentageSelectedInputValue(inputId: string, valueId: string): number {
      return stats.percentage(
        this.getTotalAnswerCountForInputValue(inputId, valueId),
        this.getTotalAnswerCountForInput(inputId)
      );
    }

    getCorrectAnswerCountForInput(inputId: string): number {
      return this.getIn(['meta', 'how_others_answered', inputId, 'count_of_correct_answers'], 0);
    }

    getTotalAnswerCountForInput(inputId: string): number {
      return this.getIn(['meta', 'how_others_answered', inputId, 'count_of_answers'], 0);
    }

    getPercentageCorrectForInput(inputId: string): number {
      return stats.percentage(
        this.getCorrectAnswerCountForInput(inputId),
        this.getTotalAnswerCountForInput(inputId)
      );
    }

    getResponsePercentageForInput(inputId: string): number {
      return stats.percentage(
        this.getTotalAnswerCountForInput(inputId),
        this.getTotalAnswerCount()
      );
    }

    getTopAnswersForInputSorted(inputId: string, count: number = 5): OrderedMap<*, *> {
      return this.cache(`top-answers-${inputId}-${count}`, () =>
        this.getIn(['meta', 'how_others_answered', inputId, 'inputs'], Map())
          .sortBy((input) => -input.get('count_of_answers'))
          .take(count)
      ).map((stat, name) => {
        return new InputAnswerStat({
          correct: stat.get('correct'),
          count_of_answers: stat.get('count_of_answers'),
          input_value: name
        });
      });
    }

    getTotalAnswerCount(): number {
      return (this.getIn(['meta', 'how_others_answered']) || Map()).reduce(
        (result, value) => result + value.get('count_of_answers'),
        0
      );
    }

    // Permission Meta

    authorCanEdit(): boolean {
      return this.getMeta().get('author_can_edit');
    }

    // Assignment meta accessors

    getAssignmentAverageTimeElapsed(): number {
      return this.getMeta().get('assignment_average_time_elapsed');
    }

    getAssignmentCountOfCorrectGuesses(): number {
      return this.getMeta().get('assignment_count_of_correct_guesses');
    }

    getAssignmentCountOfIncorrectGuesses(): number {
      return this.getMeta().get('assignment_count_of_incorrect_guesses');
    }

    getAssignmentSumOfTimeElapsed(): number {
      return this.getMeta().get('assignment_sum_of_time_elapsed');
    }

    getClassroomInsightsAverageTimeSpent(): number {
      return this.getMeta().get('classroom_insights_average_time_spent');
    }

    getClassroomInsightsCountCorrectStudents(): number {
      return this.getMeta().get('classroom_insights_count_correct_students');
    }

    getClassroomInsightsCountIncorrectStudents(): number {
      return this.getMeta().get('classroom_insights_count_incorrect_students');
    }

    getClassroomInsightsCountUnansweredStudents(): number {
      return this.getMeta().get('classroom_insights_count_unanswered_students');
    }

    getGuessPlacedByStudent(): boolean {
      return this.getMeta().get('guess_placed_by_student');
    }

    getGuessPlacedByStudentCorrect(): boolean {
      return this.getMeta().get('guess_placed_by_student_correct');
    }

    getGuessPlacedByStudentInAssignment(): boolean {
      return this.getMeta().get('guess_placed_by_student_in_assignment');
    }

    getGuessPlacedByStudentInAssignmentCorrect(): boolean {
      return this.getMeta().get('guess_placed_by_student_in_assignment_correct');
    }

    getGuessPlacedByStudentInAssignmentTimeElapsed(): number {
      return parseInt(
        this.getMeta().get('guess_placed_by_student_in_assignment_time_elapsed', 0),
        10
      );
    }

    // Section meta accessors

    getSectionAverageTimeElapsed(): number {
      return this.getMeta().get('section_average_time_elapsed');
    }

    getSectionCountOfCorrectGuesses(): number {
      return this.getMeta().get('section_count_of_correct_guesses');
    }

    getSectionCountOfIncorrectGuesses(): number {
      return this.getMeta().get('section_count_of_incorrect_guesses');
    }

    getSectionSumOfTimeElapsed(): number {
      return this.getMeta().get('section_sum_of_time_elapsed');
    }
  };
}

@addSharedQuestionMethods
export class EmptyQuestionModel extends Record(EmptyBaseQuestion) {
  // This is an empty class to be used in stores are a question's initialData
  // Don't import this class directly in stores, import getEmptyModel from mandark.resource
  // and call getEmptyModel('empty')
}

// /*
//  * Multiple Choice
//  */

// Multiple Choice Option
class MultipleChoiceOptionModel extends Record({
  id: '',
  label: '',
  value: ''
}) {
  getId(): string {
    return this.get('id');
  }

  getLabel(): string {
    return this.get('label');
  }

  getValue(): string {
    return this.get('value');
  }
}

// Multiple Choice Question
const MultipleChoiceQuestionRecord = Record({
  ...EmptyBaseQuestion,
  is_select_multiple: false,
  options: List()
});

@addSharedQuestionMethods
export class MultipleChoiceQuestionModel extends MultipleChoiceQuestionRecord {
  static setupOptions(questionData) {
    const options = questionData.get('options');
    return questionData.set(
      'options',
      options.map((option) => new MultipleChoiceOptionModel(option))
    );
  }

  constructor(questionData: Map<*, *> = new MultipleChoiceQuestionRecord().toMap()) {
    super(MultipleChoiceQuestionModel.setupOptions(questionData));
  }

  isSelectMultiple(): boolean {
    return this.get('is_select_multiple');
  }

  getOptions(): List<MultipleChoiceOptionModel> {
    return this.get('options');
  }

  // *** Response Statistics ***
  // The below are only available if the student has answered the question before or if the user is superuser or licensed teacher
  getOptionResponseCount(optionId: string): number {
    return this.getIn(['meta', 'how_others_answered', optionId, 'count_of_answers'], 0);
  }

  getOptionResponsePercentage(optionId: string): number {
    return stats.percentage(this.getOptionResponseCount(optionId), this.getTotalAnswerCount());
  }
}

/**
 * Snippet Selection
 */

// Snippet Selection Option
class SnippetSelectionOptionModel extends Record({
  id: '',
  label: '',
  value: ''
}) {
  getId(): string {
    return this.get('id');
  }

  getLabel(): string {
    return this.get('label');
  }

  getValue(): string {
    return this.get('value');
  }
}

// Snippet Selection Question
const SnippetSelectionQuestionRecord = Record({
  ...EmptyBaseQuestion,
  options: List(),
  snippet_prompt: ''
});

@addSharedQuestionMethods
export class SnippetSelectionQuestionModel extends SnippetSelectionQuestionRecord {
  static setupOptions(questionData) {
    const options = questionData.get('options');
    return questionData.set(
      'options',
      options.map((option) => new SnippetSelectionOptionModel(option))
    );
  }

  constructor(questionData: Map<*, *>) {
    super(SnippetSelectionQuestionModel.setupOptions(questionData));
  }

  getOptions(): List<MultipleChoiceOptionModel> {
    return this.get('options');
  }

  getSnippetPrompt(): string {
    return this.get('snippet_prompt');
  }
}

/*
 * Free Entry
 */

// Free Entry Option
export class FreeEntryInputModel extends Record({
  id: '',
  text: ''
}) {
  getId(): string {
    return this.get('id');
  }

  getText(): string {
    return this.get('text');
  }
}

// Free Entry Question
const FreeEntryQuestionRecord = Record({...EmptyBaseQuestion, inputs: List()});

@addSharedQuestionMethods
export class FreeEntryQuestionModel extends FreeEntryQuestionRecord {
  static setupInputs(questionData) {
    const inputs = questionData.get('inputs');
    return questionData.set(
      'inputs',
      inputs.map((input) => new FreeEntryInputModel(input))
    );
  }

  constructor(questionData: Map<*, *>) {
    super(FreeEntryQuestionModel.setupInputs(questionData));
  }

  getInputs(): List<FreeEntryInputModel> {
    return this.get('inputs');
  }
}

/*
 * Fill In The Blank
 */

// Fill In The Blank Dropdown Choice
class DropdownChoiceModel extends Record({
  id: '',
  value: ''
}) {
  getId(): string {
    return this.get('id');
  }

  getValue() {
    return this.get('value');
  }
}

// Fill In The Blank Dropdown
export class DropdownModel extends Record({
  choices: List(),
  id: '',
  position: 0
}) {
  static buildDropdownChoices(dropdown) {
    const choices = dropdown.get('choices');
    return dropdown.set(
      'choices',
      choices.map((choice) => new DropdownChoiceModel(choice))
    );
  }

  constructor(dropdownData) {
    super(DropdownModel.buildDropdownChoices(dropdownData));
  }

  getId(): string {
    return this.get('id');
  }

  getChoices(): List<*> {
    return this.get('choices');
  }

  getPosition(): number {
    return this.get('position');
  }
}

// Fill In The Blank Question
const FillInTheBlankQuestionRecord = Record({
  ...EmptyBaseQuestion,
  dropdown_text: '',
  dropdowns: List()
});

@addSharedQuestionMethods
export class FillInTheBlankQuestionModel extends FillInTheBlankQuestionRecord {
  static setupDropdowns(questionData) {
    const dropdowns = questionData.get('dropdowns');
    return questionData.set(
      'dropdowns',
      dropdowns.map((dropdown) => new DropdownModel(dropdown))
    );
  }

  constructor(questionData: Map<*, *>) {
    super(FillInTheBlankQuestionModel.setupDropdowns(questionData));
  }

  getDropdowns(): List<DropdownModel> {
    return this.get('dropdowns');
  }

  getDropdownText(): string {
    return this.get('dropdown_text');
  }
}

/*
 * Text Highlight Question
 */

const TextHighlightQuestionRecord = Record({
  ...EmptyBaseQuestion,
  highlight_prompt: '',
  preferred_permutation: ''
});

@addSharedQuestionMethods
export class TextHighlightQuestionModel extends TextHighlightQuestionRecord {
  getHighlightPrompt(): string {
    return this.get('highlight_prompt');
  }

  getPreferredPermutation(): string {
    return this.get('preferred_permutation');
  }
}

/*
 * Two-way Question
 */

// Two-way Question Column
class TwoWayColumn extends Record({
  id: '',
  text: ''
}) {
  getId(): string {
    return this.get('id');
  }

  getText(): string {
    return this.get('text');
  }
}

// Two-way Question Rows
export class TwoWayRow extends Record({
  columns: new Map({
    left: Map(),
    right: Map()
  }),
  id: '',
  index: 0,
  statement: ''
}) {
  static setUpColumns(rowData) {
    const leftColumn = new TwoWayColumn(rowData.getIn(['columns', 'left']));
    const rightColumn = new TwoWayColumn(rowData.getIn(['columns', 'right']));
    return rowData.setIn(['columns', 'left'], leftColumn).setIn(['columns', 'right'], rightColumn);
  }

  constructor(rowData) {
    super(TwoWayRow.setUpColumns(rowData));
  }

  getLeftColumn(): Map<*, *> {
    return this.getIn(['columns', 'left']);
  }

  getRightColumn(): Map<*, *> {
    return this.getIn(['columns', 'right']);
  }

  getId(): string {
    return this.get('id');
  }

  getIndex(): number {
    return this.get('index');
  }

  getStatement(): string {
    return this.get('statement');
  }
}

// Two-way Question
const TwoWayQuestionRecord = Record({
  ...EmptyBaseQuestion,
  header_left: '',
  header_right: '',
  rows: List()
});

@addSharedQuestionMethods
export class TwoWayQuestionModel extends TwoWayQuestionRecord {
  static setUpRows(questionData) {
    const rows = questionData.get('rows').map((row) => new TwoWayRow(row));
    return questionData.set('rows', rows);
  }

  constructor(questionData: Map<string, *>) {
    super(TwoWayQuestionModel.setUpRows(questionData));
  }

  getLeftHeader(): string {
    return this.get('header_left');
  }

  getRightHeader(): string {
    return this.get('header_right');
  }

  getRows(): List<*> {
    return this.get('rows');
  }

  containsLatex(): boolean {
    const func = () => {
      const regex = /\$.+\$/;
      if (regex.test(this.getLeftHeader()) || regex.test(this.getRightHeader())) {
        return true;
      }
      return this.getRows().some(
        (row) =>
          regex.test(row.getLeftColumn().getText()) ||
          regex.test(row.getRightColumn().getText()) ||
          regex.test(row.getStatement())
      );
    };
    return this.cache('containsLatex', func);
  }
}

// Passage Correction Question
@addSharedQuestionMethods
export class PassageCorrectionQuestionModel extends Record({
  ...EmptyBaseQuestion,
  region_indices: Map(),
  uncorrected_text: ''
}) {
  constructor(questionData: Map<string, *>) {
    super(questionData);
  }

  getUncorrectedText(): string {
    return this.get('uncorrected_text');
  }

  getRegionIndices(): Map<string, number> {
    return this.get('region_indices');
  }

  getEnumeratedRegionIndices(): List<number> {
    const func = () =>
      this.getRegionIndices().reduce((acc, region) => {
        const indicesInRegion = Range(
          region.get('start_index'),
          region.get('end_index') + 1
        ).reduce((acc, val) => {
          return acc.set(val, true);
        }, Map());
        return acc.merge(indicesInRegion);
      }, Map());

    return this.cache('getEnumeratedRegionIndices', func);
  }

  isOptionIndexInteractive(optionIndex: number): boolean {
    return this.getEnumeratedRegionIndices().get(parseInt(optionIndex, 10)) === true;
  }

  getRegionForOptionIndex(optionIndex: number): Map<string, any> {
    const matchedRegion = this.getRegionKeyForOptionIndex(optionIndex);
    return this.getValidResponse().get(matchedRegion);
  }

  getRegionKeyForOptionIndex(optionIndex: number): Map<string, any> {
    const func = () =>
      this.getRegionIndices().findKey((region) => {
        return (
          [region.get('start_index'), optionIndex, region.get('end_index')].sort(
            (a, b) => a - b
          )[1] === optionIndex
        );
      });
    return this.cache(`getRegionKeyForOptionIndex/${optionIndex}`, func);
  }

  getSolutionsForRegionKey(regionKey: string): List<string> {
    return this.getValidResponse().getIn([regionKey, 'solutions']);
  }

  isRegionDistractor(regionKey: string): boolean {
    return this.getSolutionsForRegionKey(regionKey) === null;
  }

  doesRegionContainOptionIndex(region: Map<string, any>, optionIndex: number): boolean {
    const matchedStartIndex = region.get('start_index');
    const matchedEndIndex = region.get('end_index');
    return matchedStartIndex <= optionIndex && optionIndex <= matchedEndIndex;
  }
}

// Graphing Question
const GraphingQuestionRecord = Record({
  ...EmptyBaseQuestion
});

// We don't want to enhance support to old question resources but an empty class is needed for question_v1 and v2 to return with a questionModel
@addSharedQuestionMethods
export class GraphingQuestionModel extends GraphingQuestionRecord {}

export type QuestionType =
  | FillInTheBlankQuestionModel
  | FreeEntryQuestionModel
  | MultipleChoiceQuestionModel
  | SnippetSelectionQuestionModel
  | TextHighlightQuestionModel
  | TwoWayQuestionModel
  | EmptyQuestionModel
  | PassageCorrectionQuestionModel
  | GraphingQuestionModel;

export const questionPropType = PropTypes.oneOfType([
  PropTypes.instanceOf(FillInTheBlankQuestionModel),
  PropTypes.instanceOf(FreeEntryQuestionModel),
  PropTypes.instanceOf(MultipleChoiceQuestionModel),
  PropTypes.instanceOf(SnippetSelectionQuestionModel),
  PropTypes.instanceOf(TextHighlightQuestionModel),
  PropTypes.instanceOf(TwoWayQuestionModel),
  PropTypes.instanceOf(EmptyQuestionModel),
  PropTypes.instanceOf(PassageCorrectionQuestionModel),
  PropTypes.instanceOf(GraphingQuestionModel)
]);
