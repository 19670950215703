import React, {lazy} from 'react';
import {Route, IndexRoute} from 'react-router';

const Reports = lazy(() => import(/* webpackChunkName: "Reports" */ './Reports.react'));

export default (
  <Route path='/reports'>
    <IndexRoute component={Reports} />
    <Route path='*' component={Reports} />
  </Route>
);
